import { call, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { errorActions } from "../actions";
import { settlementAccountActions } from '../actions/settlement-account.actions';
import { RequestState } from "../constants/request-state";
import { settlementService } from '../services/settlement.service';
import { SettlementAccount } from "../types/settlement/SettlementAccount";

function* watchRequest() {
    try {
        const accounts: SettlementAccount[] = yield call(settlementService.getSettlementAccounts);
        yield put(settlementAccountActions.requestResult(RequestState.success, accounts));
    } catch (e) {
        yield put(errorActions.criticalError(e));
        yield put(settlementAccountActions.requestResult(RequestState.failure));
    }
}

export function* watchSettlementAccount() {
    yield takeEvery(getType(settlementAccountActions.request), watchRequest);
}
