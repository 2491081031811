import { fetchSafe } from './fetch.safe';
import { BrokerDealerCompanySlim } from '../types/company/BrokerDealerCompanySlim';
import { responseType } from '../constants';
import { SellerAdminEditFormModel } from '../types/seller-admin/SellerAdminEditFormModel';
import { UserCreationRequest } from '../types/management/UserCreationRequest';
import { CompanyUser } from '../types/management/CompanyUser';
import { TradingLimitType } from '../types/seller-admin/TradingLimitType';
import { Currency } from '../types/enums/Currency';
import { UserStatus } from '../types/account/UserStatus';

function getExcludedDealers(): Promise<{ excludeNewCompany: boolean, excludedDealers: BrokerDealerCompanySlim[] }> {
    return fetchSafe('/api/manage/excluded-dealers')
}

function updateExcludedDealers(excludeNewCompany: boolean, companyIds: number[]): Promise<void> {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ excludeNewCompany, companyIds }),
        responseType: responseType.none
    };
    return fetchSafe('/api/manage/excluded-dealers', requestOptions)
}

function getTradingLimits(): Promise<{currency: Currency; size: number | null; type: TradingLimitType}[]> {
    return fetchSafe('/api/manage/trading-limits')
}

function updateTradingLimits(params: { tradingLimits: { currency: string; type: number; size: number | null }[] }): Promise<void> {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        responseType: responseType.none
    };
    return fetchSafe('/api/manage/trading-limits', requestOptions)
}

interface RequestCreationEditUserParams extends SellerAdminEditFormModel {
    id?: number;
    concurrencyStamp?: Date;
}

function saveUserRequest(params: RequestCreationEditUserParams): Promise<UserCreationRequest> {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        responseType: responseType.json
    };
    return fetchSafe('/api/manage/users', requestOptions)
}

function saveUser(params: RequestCreationEditUserParams): Promise<void> {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(params),
        responseType: responseType.json
    };
    return fetchSafe(`/api/manage/users/${params.id}/edit`, requestOptions)
}

function enableRequestUser(id: number, concurrencyStamp: Date): Promise<UserCreationRequest> {
    const requestOptions = {
        method: 'PATCH',
        body: JSON.stringify({ id, concurrencyStamp }),
        responseType: responseType.json
    };
    return fetchSafe('/api/manage/users/user-creation-request/enable', requestOptions)
}

function disableRequestUser(id: number, concurrencyStamp: Date, blockReason: string): Promise<UserCreationRequest> {
    const requestOptions = {
        method: 'PATCH',
        body: JSON.stringify({ id, concurrencyStamp, blockReason }),
        responseType: responseType.json
    };
    return fetchSafe('/api/manage/users/user-creation-request/disable', requestOptions)
}

function enableUser(id: number): Promise<{ id: number, status: UserStatus }> {
    const requestOptions = {
        method: 'PATCH',
        responseType: responseType.json
    };
    return fetchSafe(`/api/manage/users/${id}/enable`, requestOptions)
}

function disableUser(id: number, blockReason: string): Promise<{ id: number }> {
    const requestOptions = {
        method: 'PATCH',
        body: '"' + blockReason + '"',
        responseType: responseType.json
    };
    return fetchSafe(`/api/manage/users/${id}/disable`, requestOptions)
}

function getCompanyUsers(): Promise<{ users: CompanyUser[], requests: UserCreationRequest[] }> {
    return fetchSafe('/api/company-account/users');
}

function exportUsers(): Promise<{ name: string, blob: Blob }> {
    const requestOptions = {
        method: 'get',
        responseType: responseType.file
    };
    return fetchSafe('/api/manage/users/export', requestOptions);
}

export const sellerAdminService = {
    getExcludedDealers,
    updateExcludedDealers,
    updateTradingLimits,
    getTradingLimits,
    saveUserRequest,
    saveUser,
    enableRequestUser,
    disableRequestUser,
    enableUser,
    disableUser,
    exportUsers,
    getCompanyUsers,
};
