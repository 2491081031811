import { call, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { errorActions } from "../actions";
import { tradeAllocationTemplateActions } from '../actions/trade-allocation-template.actions';
import { RequestState } from "../constants/request-state";
import { tradeAllocationTemplateService } from '../services/trade-allocation-template.service';
import { TradeAllocationTemplate } from "../types/trade-allocation/template/TradeAllocationTemplate";

function* watchRequest() {
    try {
        const templates: TradeAllocationTemplate[] = yield call(tradeAllocationTemplateService.getTemplates);
        yield put(tradeAllocationTemplateActions.requestResult(RequestState.success, templates));
    } catch (e) {
        yield put(errorActions.criticalError(e));
        yield put(tradeAllocationTemplateActions.requestResult(RequestState.failure));
    }
}

export function* watchTradeAllocationTemplate() {
    yield takeEvery(getType(tradeAllocationTemplateActions.request), watchRequest);
}
