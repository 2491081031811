import { fetchSafe } from "./fetch.safe";
import { responseType } from '../constants';
import { SaveInventoryPosition } from '../types/inventory/SaveInventoryPosition';
import { InventoryPosition } from '../types/inventory/InventoryPosition';
import { ParsedInventoryPosition } from '../types/inventory/ParsedInventoryPosition';
import { fileUpload } from "./file.upload";
import { InventorySearchResult } from "../types/inventory/InventorySearchResult";
import { appConfig } from "../app-config";

export const inventoryService = {
    getInventoryExistFlag,
    getAllInventory,
    getBdInventory,
    getAdminEditInventory,
    setTraded,
    save,
    exportInventoryList,
    sendEmail,
    uploadSecurities,
    getSecuritiesXlsxTemplate,
    parseSecuritiesStirng,
    getActiveSecurityIdList,
};

function getInventoryExistFlag(): Promise<boolean> {
    return fetchSafe('/api/dealers-inventory/has-inventory');
}

function getBdInventory(): Promise<{ inventorySecurities: InventoryPosition[], lockModifiedDate: Date | null }> {
    return fetchSafe('/api/dealers-inventory');
}

function getAdminEditInventory(companyId: number): Promise<{ inventorySecurities: InventoryPosition[], lockModifiedDate: Date | null }> {
    return fetchSafe(`/api/dealers-inventory/${companyId}`);
}

export interface InventorySearchParams {
    identifiers: string[];
    statuses: number[];
    ratings: string[];
    dealers: number[] | null;
    orderByColumn?: number;
    sortOrder?: number;
    page?: number;
    pageSize?: number;
    from?: Date;
    to?: Date;
    currentItems?: boolean;
    loadSummary?: boolean;
}

function getAllInventory(params: InventorySearchParams): Promise<InventorySearchResult> {
    return fetchSafe('/api/dealers-inventory/asset-manager', {
        method: 'POST', body: JSON.stringify({
            ...params, 
            loadSummary: params.loadSummary && !appConfig.isSummaryRowHidden
        })
    });
}

function setTraded(inventoryPositionId: number, lockModifiedDate: Date, tradeDate: Date): Promise<void> {
    const requestOptions = {
        method: 'put',
        body: JSON.stringify({ lockModifiedDate, tradeDate }),
        responseType: responseType.none
    };

    return fetchSafe(`/api/dealers-inventory/set-traded/${inventoryPositionId}`, requestOptions);
}

function save(inventorySecurities: SaveInventoryPosition[], lockModifiedDate?: Date, companyId?: number): Promise<void> {
    const requestOptions = {
        method: 'post',
        body: JSON.stringify({
            inventorySecurities,
            lockModifiedDate,
            companyId
        }),
        responseType: responseType.none
    };
    return fetchSafe(`/api/dealers-inventory`, requestOptions);
}

function exportInventoryList(params: InventorySearchParams): Promise<{ blob: Blob, name: string }> {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        responseType: responseType.file
    };
    return fetchSafe('/api/dealers-inventory/export', requestOptions)
}

function sendEmail(params: { selectedDealerEmail: boolean, selectedBloombergEmail: boolean }): Promise<void> {
    const requestOptions = {
        method: 'put',
        responseType: responseType.none,
        body: JSON.stringify({
            toDealerEmail: params.selectedDealerEmail,
            toBloombergEmail: params.selectedBloombergEmail
        }),
    };
    return fetchSafe('/api/dealers-inventory/send-list', requestOptions)
}

function uploadSecurities(
    file: File,
    progressCallback: ((this: XMLHttpRequest, ev: ProgressEvent) => any) | null): Promise<ParsedInventoryPosition[]> {
    return fileUpload(
        '/api/dealers-inventory/ParseFile',
        null,
        file,
        progressCallback
    );
}

function getSecuritiesXlsxTemplate(): Promise<{ name: string, blob: Blob }> {
    const requestOptions = {
        responseType: responseType.file
    };
    return fetchSafe('/api/dealers-inventory/template', requestOptions);
}

function parseSecuritiesStirng(text: string): Promise<ParsedInventoryPosition[]> {
    const url = '/api/dealers-inventory/clipboard';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ text })
    };
    return fetchSafe(url, requestOptions);
}

function getActiveSecurityIdList(): Promise<number[]> {
    return fetchSafe('/api/dealers-inventory/active-securities-ids');
}
