import { fork } from 'redux-saga/effects';
import { watchNotifications } from './notifications.saga';
import { watchBlotter } from './blotter.saga';
import { watchOnBoardingTooltip } from './onBoardinTooltip.saga';
import { watchDashboard } from './dashboard.saga';
import { watchSecurityDetails } from './security-details.saga';
import { watchClientCompanies } from './clients-companies.saga';
import { watchSellerBuyside } from './seller-buyside.saga';
import { watchSubscriptions } from './subscriptions.saga';
//import { watchBillingInfo } from './billing-info.saga';
//import { watchPaymentMethod } from './payment-method.saga';
//import { watchUpgradeSubscription } from './upgrade-subscription.saga';
import { watchContactSales } from './contact-sales.saga';
import { watchSalesRepresentative } from './sales-representative.saga';
import { watchBidOnBehalf } from './bid-on-behalf.saga';
import { watchBillingHistory } from './subscription-biling-history.saga';
import { watchApiOperation } from './api-operation.saga';
import { watchFavoriteBrokerDealers } from './favorite-broker-dealers.saga';
import { watchBdInventory } from './bd-inventory.saga';
import { watchInventory } from './inventory-saga';
import { watchClearingBank } from './clearing-bank.saga';
import { watchBlastMessage } from './blast-message.saga';
import { watchRelease } from './release.saga';
import { watchCompanyDetails } from './company-details.saga';
import { watchDealerListPanel } from './dealer-list-panel.saga';
import { watchAddToPortfolio } from "./add-to-portfolio.saga";
import { watchBrokerDealerContactList } from './broker-dealer-contact-list.saga';
import { watchBrokerDealerUserList } from './broker-dealer-user-list.saga';
import { watchDealerList } from './dealer-list.saga';
import { watchNewBwicDealers } from './new-bwic-dealers.saga';
import { watchBrokerDealerList } from './broker-dealer-list.saga';
import { watchDirectBiddingDisclaimer } from './direct-bidding-agreement-disclaimer.saga';
import { watchSellerAdminDealers } from './seller-admin-dealers.saga';
import { watchPortfolioList } from "./portfolio-list.saga";
import { watchManageEmailPreferences } from './manage-email-preferences.saga';
import { watchSellerAdminLimit } from './seller-admin-limit.saga';
import { watchSellerAdminUsers } from './seller-admin-users.saga';
import { watchBrokerDealerBidding } from './broker-dealer-bidding.saga';
import { watchUserRequestAdmin } from './user-request-admin.saga';
import { watchImUserConfig } from './im-user-config.saga';
import { watchAdminInventoryEdit } from './admin-inventory-edit.saga';
import { watchInventoryEdit } from './inventory-edit.saga';
import { watchSignup } from './signup.saga';
import { watchPriceTalks } from './price-talks.saga';
import { watchEmailPreferences } from './email-preferences.saga';
import { watchSettlementAccount } from './settlement-account.saga';
import { watchAssetManager } from './asset-manager.saga';
import { watchTradeAllocation } from './trade-allocation.saga';
import { watchTradeAllocationTemplate } from './trade-allocation-template.saga';
import { watchAwaitingTrades } from './entities/awaiting-trades.saga';
import { watchRejectedTrades } from './entities/rejected-trades.saga';
import { watchTransactionHistory } from './amr-transaction-history.saga';
import { watchAmrPipelineCommon } from './amr-pipeline-common.saga';
import { watchHelpResources } from './help-resources.saga';
import { watchAmrTransactionAnalytics } from './amr-transaction-analytics.saga'
import { watchArrangerPipelineSaga } from './amr-arranger-pipeline.saga';
import { watchManageTokens } from './manage-tokens.saga'
import { watchCloManagers } from './clo-managers.saga';
import { watchAddress } from './address.saga';
import { watchChangeSubscription } from './change-subscription.saga';
import { watchPortfolioAgreementDisclaimer } from './portfolio-agreement-disclaimer.saga';
import { watchAllBwics } from './allbwics.saga';
import { watchFilter } from './filter.saga';
import { watchNews } from './news.saga';
import { watchEditNews } from './edit-news.saga';
import { watchBwicLog } from './bwic-log.saga';
import { watchBanks } from './banks.saga';
import { watchCompanyMembers } from './company-members.saga';
import { watchDeals } from './deals.saga';

export function* watchMain() {
    yield fork(watchNotifications);
    yield fork(watchBlotter);
    yield fork(watchOnBoardingTooltip);
    yield fork(watchDashboard);
    yield fork(watchSecurityDetails);
    yield fork(watchBwicLog);
    yield fork(watchClientCompanies);
    yield fork(watchSellerBuyside);
    yield fork(watchSubscriptions);
    //yield fork(watchBillingInfo);
    //yield fork(watchPaymentMethod);
    yield fork(watchEmailPreferences);
    yield fork(watchChangeSubscription);
    //yield fork(watchUpgradeSubscription);
    yield fork(watchContactSales);
    yield fork(watchSalesRepresentative);
    yield fork(watchBidOnBehalf);
    yield fork(watchApiOperation);
    yield fork(watchBillingHistory);
    yield fork(watchFavoriteBrokerDealers);
    yield fork(watchBdInventory);
    yield fork(watchInventory);
    yield fork(watchClearingBank);
    yield fork(watchBlastMessage);
    yield fork(watchCompanyDetails);
    yield fork(watchDealerListPanel);
    yield fork(watchAddToPortfolio);
    yield fork(watchBrokerDealerUserList);
    yield fork(watchBrokerDealerContactList);
    yield fork(watchDealerList);
    yield fork(watchRelease);
    yield fork(watchNewBwicDealers);
    yield fork(watchBrokerDealerList);
    yield fork(watchDirectBiddingDisclaimer);
    yield fork(watchSellerAdminDealers);
    yield fork(watchPortfolioList);
    yield fork(watchSellerAdminLimit);
    yield fork(watchSellerAdminUsers);
    yield fork(watchBrokerDealerBidding);
    yield fork(watchUserRequestAdmin);
    yield fork(watchImUserConfig);
    yield fork(watchAdminInventoryEdit);
    yield fork(watchInventoryEdit);
    yield fork(watchSignup);
    yield fork(watchPriceTalks);
    yield fork(watchManageEmailPreferences);
    yield fork(watchSettlementAccount);
    yield fork(watchAssetManager);
    yield fork(watchTradeAllocation);
    yield fork(watchTradeAllocationTemplate);
    yield fork(watchAwaitingTrades);
    yield fork(watchTransactionHistory);
    yield fork(watchAmrPipelineCommon);
    yield fork(watchRejectedTrades);
    yield fork(watchHelpResources);
    yield fork(watchAmrTransactionAnalytics);
    yield fork(watchArrangerPipelineSaga);
    yield fork(watchManageTokens);
    yield fork(watchCloManagers);
    yield fork(watchAddress);
    yield fork(watchPortfolioAgreementDisclaimer);
    yield fork(watchAllBwics);
    yield fork(watchFilter);
    yield fork(watchNews);
    yield fork(watchEditNews);
    yield fork(watchBanks);
    yield fork(watchCompanyMembers);
    yield fork(watchDeals);
}
