import { fetchSafe } from './fetch.safe';
import { fileUpload } from './file.upload';
import { responseType } from '../constants';
import { DocumentStoreType } from '../types/document/Document';

export const documentsService = {
    uploadDocument,
    uploadAndAssignDocument,
    downloadDocument,
    downloadAllDocuments,
    deleteDocument,
    uploadSecurities,
    downloadSecurities,
    getSecurityDocumentsExistFlag,
    downloadStatic
}

function getDocumentUrlByType(documentStoreType) {
    if (documentStoreType === DocumentStoreType.Settlement) {
        return '/api/settlement-documents'
    }
    if (documentStoreType === DocumentStoreType.Disclosure) {
        return `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/documents`
    }
    return '/api/documents'
}

function getDownloadAllUrlByType(documentStoreType) {
    if(documentStoreType === DocumentStoreType.Settlement) {
        return '/api/settlement-documents/download-all'
    }
    throw new Error('Download all is not supported');
}

function uploadDocument(file, progressCallback, documentStoreType) {
    return fileUpload(
        getDocumentUrlByType(documentStoreType),
        null,
        file,
        progressCallback
    );
}

function uploadAndAssignDocument(file, dealReferenceName, documentType, progressCallback) {
    return fileUpload(
        `/api/documents/deal-document/${dealReferenceName}/${documentType}`,
        null,
        file,
        progressCallback
    );
}

function downloadDocument(documentId, documentStoreType = DocumentStoreType.Default) {
    return fetchSafe(`${getDocumentUrlByType(documentStoreType)}/${documentId}`, {
        responseType: responseType.file
    });
}

function downloadAllDocuments(documentGroupKey, documentStoreType) {
    return fetchSafe(`${getDownloadAllUrlByType(documentStoreType)}/${documentGroupKey}`, {
        responseType: responseType.file
    })
}

function deleteDocument(documentId, dealTicker) {
    const requestOptions = {
        method: 'DELETE',
        responseType: responseType.none
    };

    return fetchSafe(`/api/documents/${documentId}?ticker=${dealTicker}`, requestOptions);
}

function uploadSecurities(file, progressCallback) {
    const url = '/api/manage-securities';
    return fileUpload(
        url,
        null,
        file,
        progressCallback
    );
}

function downloadSecurities() {
    return fetchSafe('/api/manage-securities/template', { responseType: responseType.file });
}

function getSecurityDocumentsExistFlag(isinCusip) {
    return fetchSafe(`/api/securities/hasDocuments/${isinCusip} `);
}

function downloadStatic(filename) {
    return fetchSafe(`/api/documents/static/${filename}`, { responseType: responseType.file });
}
