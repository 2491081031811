import moment from 'moment';
import qs from 'query-string';
import { constants, responseType } from '../constants';
import { CloManagerAccessType } from '../types/amr-pipeline/enums/CloManagerAccessType';
import { CloManagerDetailed } from '../types/clo-managers/CloManagerDetailed';
import { CloManagerSave } from '../types/clo-managers/CloManagerSave';
import { fetchSafe } from './fetch.safe';
import { apiUtils } from '../utils';

function getCloManager(companyReferenceName: string): Promise<CloManagerDetailed> {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${companyReferenceName}/clo-manager`);
}

function createOrUpdateCloManager(companyReferenceName: string, cloManager: CloManagerSave): Promise<any> {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${companyReferenceName}/clo-manager`, {
        method: 'PUT',
        body: JSON.stringify(cloManager),
        responseType: responseType.none,
    });
}

function downloadManagerPresentationFile(companyReferenceName: string, referenceName: string) {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${companyReferenceName}/clo-manager/manager-presentation-file/download/${referenceName}`, requestOptions);
}

function logUserActivity(companyReferenceName: string, accessType: CloManagerAccessType) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${companyReferenceName}/clo-manager/access/${accessType}`, {
        method: 'POST',
        responseType: responseType.none,
    });
}

function switchFavorite(companyReferenceName: string, favorite: boolean) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${companyReferenceName}/favorite/${favorite}`, {
        method: 'PUT',
        responseType: responseType.none,
    });
};

async function getAnalytics(companyReferenceName: string) {
    const queryString = qs.stringify({ currentTime: moment().format() });

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${companyReferenceName}/clo-manager/analytics?${queryString}`);
}

function getInvitedAndActiveManagers(companyReferenceName: string) {
    return fetchSafe(`/api/companies/${companyReferenceName}/invited-and-active-collateral-managers`);
}

function getProfileViewHistory(companyReferenceName: string, startDate?: Date, endDate?: Date) {
    let queryString = '';

    if (startDate && endDate) {
        const queryParams = {
            'period.From': moment(startDate).format(constants.dateTimeFormatUtc),
            'period.To': moment(endDate).format(constants.dateTimeFormatUtc),
        };

        queryString = `?${qs.stringify(queryParams)}`;
    }

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${companyReferenceName}/clo-manager/profile-views/${queryString}`);
}

function getLatestTransactionsInDeal(
    managerReferenceName: string,
    sortingParams: { field: string; ascending: boolean }[]
) {
    const queryString = `${qs.stringify(
        apiUtils.parseSortingParams(sortingParams)
    )}`;

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/transactions/collateral-manager/${managerReferenceName}/latest-transaction-in-deal?${queryString}`
    );
}

function scheduleMeeting(companyReferenceName: string, recipientsEmails: string[], message: string) {
     return fetchSafe(
         `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${companyReferenceName}/clo-manager/request-meeting`,
         {
             method: 'PUT',
             body: JSON.stringify({
                recipientsEmails,
                message
             }),
             responseType: responseType.none,
         },
     );
}

function getCloSystemInfrastructures() {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/CloSystemInfrastructure`);
}

export const cloManagersService = {
    getCloManager,
    createOrUpdateCloManager,
    downloadManagerPresentationFile,
    logUserActivity,
    switchFavorite,
    getAnalytics,
    getInvitedAndActiveManagers,
    getProfileViewHistory,
    getLatestTransactionsInDeal,
    scheduleMeeting,
    getCloSystemInfrastructures
};
