import { fetchSafe } from './fetch.safe';
import { BwicByRatingPreferences, EmailPreferences } from '../types/email-preferences/EmailPreferences';
import { responseType } from '../constants';

export const emailPreferencesService = {
    getEmailPreferences,
    saveEmailPreferences,
    saveBwicByRating
}

function getEmailPreferences(): Promise<EmailPreferences> {
    return fetchSafe('/api/email-preferences');
}

function saveEmailPreferences(preferences: EmailPreferences): Promise<void> {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(preferences),
        responseType: responseType.none
    };

    return fetchSafe('/api/email-preferences', requestOptions);
}

function saveBwicByRating(bwicByRatingPreferences: BwicByRatingPreferences): Promise<void> {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(bwicByRatingPreferences),
        responseType: responseType.none
    };

    return fetchSafe('/api/email-preferences/bwic-by-rating-alert', requestOptions);
}