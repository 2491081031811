import { fetchSafe } from "./fetch.safe";
import { responseType } from "../constants";

export const tradeReportingService = {
    search,
    exportSearchResult,
    setReportedFalg,
    getResolveTrades,
    setResolveTrades,
};

function search({ isReported, excludeOnBehalf, dateFrom, dateTo, tradeStatuses, settlementStatuses, sortingField, sortDirection, page, pageSize }) {
    const url = '/api/trades/search';
    const requestBody = JSON.stringify({
        dateFrom,
        dateTo,
        isReported: isReported == null ? undefined : isReported,
        excludeOnBehalf,
        tradeStatuses,
        settlementStatuses,
        page,
        pageSize,
        orderByColumn: sortingField,
        sortOrder: sortDirection,
    });
    const requestOptions = {
        method: 'POST',
        body: requestBody,
        responseType: responseType.json
    };
    return fetchSafe(url, requestOptions);
}

function exportSearchResult(isReported, dateFrom, dateTo, excludeOnBehalf, tradeStatuses, settlementStatuses, sortingField, sortDirection) {
    const url = '/api/trades/export';
    const requestBody = JSON.stringify({
        dateFrom,
        dateTo,
        isReported: isReported == null ? undefined : isReported.toString(),
        excludeOnBehalf,
        tradeStatuses,
        settlementStatuses,
        orderByColumn: sortingField,
        sortOrder: sortDirection,
    });
    const requestOptions = {
        method: 'POST',
        body: requestBody,
        responseType: responseType.file
    };

    return fetchSafe(url, requestOptions);
}


function setReportedFalg(referenceId, isReported) {
    const url = '/api/trades';
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ referenceId, isReported }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function getResolveTrades() {
    return fetchSafe('/api/trading/trades/resolve')
}

function setResolveTrades(references) {
    return fetchSafe('/api/trading/trades/resolve', {method: 'POST', body: JSON.stringify(references)})
}
