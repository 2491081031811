import qs from "query-string";
import { responseType } from "../constants";
import { fetchSafe } from "./fetch.safe";
import { News } from "../types/news/News";
import { NewsQueryParams } from '../types/news/NewsQueryParams';
import { NewsAccessType } from "../types/amr-pipeline/enums/NewsAccessType";

interface NewsListQueryParams extends NewsQueryParams {
    count?: number;
    offset?: number;
}

function getNews(params: NewsListQueryParams): Promise<News[]> {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/news`, {
        method: "POST",
        body: JSON.stringify(params),
    });
}

function getNewsTotalCount(params?: NewsListQueryParams): Promise<number> {
    const queryParams = qs.stringify(params || {});
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/news/count?${queryParams}`
    );
}

function getNewsDetails(referenceName: string, params: NewsQueryParams = {}): Promise<News> {
    const queryString = qs.stringify(params);

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/news/${referenceName}?${queryString}`
    );
}

function createOrUpdateNews(newsData: News) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/news`, {
        method: "PUT",
        body: JSON.stringify(newsData),
    });
}

function deleteNews(referenceName: string) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/news/${referenceName}`,
        {
            method: "DELETE",
            responseType: responseType.none,
        }
    );
}

function logUserActivity(accessType: NewsAccessType) {
    const url = `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/Accounts/log-user-activity/${accessType}`;

    const requestOptions = {
        method: 'POST',
        responseType: responseType.none,
    };

    return fetchSafe(url, requestOptions);
}

function downloadDocument(newsReferenceName: string, documentReferenceName: string) {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/news/${newsReferenceName}/documents/${documentReferenceName}/download`,
        requestOptions,
    );
}

function downloadDocuments(newsReferenceName: string) {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/news/${newsReferenceName}/documents/download`,
        requestOptions,
    );
}

export const newsService = {
    getNews,
    getNewsTotalCount,
    getNewsDetails,
    createOrUpdateNews,
    deleteNews,
    logUserActivity,
    downloadDocument,
    downloadDocuments,
};
