import { fetchSafe } from './fetch.safe';
import moment from 'moment';
import { apiUtils } from "../utils/api.utils";
import { responseType } from '../constants';
import qs from 'query-string';
import { TransactionType, AllTopIssuersTransactionTypes } from '../types/amr-pipeline/enums/TransactionType'
import { TransactionStatus } from '../types/amr-pipeline/enums/TransactionStatus'
import { Currency } from '../types/enums/Currency'
import { urlBuilder } from '.';
import { constants } from '../constants/constants';
import { CollateralType } from '../types/amr-pipeline/enums/CollateralType';
import { isNil } from 'lodash';

function getCurrentTimeStamp(){
    return encodeURIComponent(moment().format('YYYY-MM-DDThh:mm:ss'));
}

function fetchDailyTradingStats() {
    return fetchSafe('/api/dashboard/trading-stats');
}

function fetchDeals(additionalCriteria, yearFrom, yearTo) {
    const filterCriteria = {
        pricingDate: {
            from: moment([yearFrom]).startOf("year").format(constants.dateFormatISO8601),
            to: moment([yearTo]).endOf("year").format(constants.dateFormatISO8601)
        },
        statuses: [TransactionStatus.Closed, TransactionStatus.Priced],
        ...additionalCriteria
    }

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/transactions/statistics-deals`, {
        method: 'POST',
        body: JSON.stringify(filterCriteria),
    });
}

function fetchExportDeals(additionalCriteria, yearFrom, yearTo) {
    const filterCriteria = {
        pricingDate: {
            from: moment([yearFrom]).startOf("year").format(constants.dateFormatISO8601),
            to: moment([yearTo]).endOf("year").format(constants.dateFormatISO8601)
        },
        statuses: [TransactionStatus.Closed, TransactionStatus.Priced],
        ...additionalCriteria
    }

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/transactions/statistics-deals/export`, {
        method: 'POST',
        body: JSON.stringify(filterCriteria),
        responseType: responseType.file,
    });
}

function fetchTopChart(chartType, selectedType, currencyCode, yearFrom, yearTo, collateralTypes) {
    const queryParams = {
        statuses: [TransactionStatus.Closed, TransactionStatus.Priced],
        types: selectedType || AllTopIssuersTransactionTypes,
        currencyCode,
        collateralTypes: [collateralTypes],
        "pricingDate.From": moment([yearFrom]).startOf("year").format(constants.dateTimeFormatUtc),
        "pricingDate.To": moment([yearTo]).endOf("year").format(constants.dateTimeFormatUtc),
    };

    const queryString = `?${qs.stringify(queryParams)}`;
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/dashboard/${chartType}/${queryString}`
    );
}

function exportTopChart(
    chartType,
    selectedType,
    currencyCode,
    yearFrom,
    yearTo,
    collateralTypes,
    sortingParams,
) {
    const requestOptions = {
        responseType: responseType.file
    };
    const querySortingParams = apiUtils.parseSortingParams(sortingParams);

    const queryParams = {
        statuses: [TransactionStatus.Closed, TransactionStatus.Priced],
        types: selectedType || AllTopIssuersTransactionTypes,
        currencyCode,
        "pricingDate.From": moment([yearFrom]).startOf("year").format(constants.dateTimeFormatUtc),
        "pricingDate.To": moment([yearTo]).endOf("year").format(constants.dateTimeFormatUtc),
        collateralTypes,
        ...querySortingParams,
    };

    const queryString = `?${qs.stringify(queryParams)}`;
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/dashboard/${chartType}/export/${queryString}`,
        requestOptions
    );
}

function fetchExportTradingStats(currencyFilter, ratings) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ currencyFilter, ratings}),
        responseType: responseType.file
    };
    return fetchSafe('/api/dashboard/export-trading-stats', requestOptions);
}

function fetchPortfolioSecurities() {
    return fetchSafe(`/api/dashboard/portfolio-securities/${getCurrentTimeStamp()}`);
}

function fetchScheduledBwics() {
    return fetchSafe(`/api/dashboard/${getCurrentTimeStamp()}`, {}, true)
}

function fetchColorRates() {
    return fetchSafe(`/api/dashboard/price-in-color/${getCurrentTimeStamp()}`)
}

function fetchDntRates() {
    return fetchSafe(`/api/dashboard/dnt-rates/${getCurrentTimeStamp()}`)
}

function exportBwicStatistic(securityId) {
    const url = `/api/securities/export-bwic-statistic/${securityId}`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({}),
        responseType: responseType.file
    };
    return fetchSafe(url, requestOptions);
}

function fetchSameDayPeriods() {
    return fetchSafe(`/api/dashboard/same-day-bwics/${getCurrentTimeStamp()}`);
}

function fetchDealersStatsExists() {
    return fetchSafe('/api/dashboard/dealers-stats-exists');
}

function fetchDealersStats() {
    return fetchSafe('/api/dashboard/dealers-stats');
}

function exportDealersStats() {
    return fetchSafe(`/api/dashboard/dealers-stats-export`, { responseType: responseType.file });
}

function fetchSellersStatsExists() {
    return fetchSafe('/api/dashboard/sellers-stats-exists');
}

function fetchClientsStats() {
    return fetchSafe('/api/dashboard/sellers-stats');
}

function exportSellerStats() {
    return fetchSafe(`/api/dashboard/sellers-stats-export`, { responseType: responseType.file });
}

function fetchPriceLevel() {
    return fetchSafe('/api/dashboard/price-level')
}

function fetchDtcSellingBuying() {
    return fetchSafe('/api/dashboard/dtc-selling-buying')
}

function fetchDtcInvestmentGrade() {
    return fetchSafe(`/api/dashboard/dtc-ig-hy/${getCurrentTimeStamp()}`)
}

function fetchMedianAAA() {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ dateFrom: moment().format() }),
    };
    return fetchSafe('/api/dashboard/median-aaa', requestOptions)
}

function fetchAAAPriceLevel() {
    return fetchSafe(`/api/dashboard/priceLevel-aaa/${getCurrentTimeStamp()}`)
}

function fetchMostTradedTickers() {
    return fetchSafe(`/api/dashboard/most-traded-tickers/${getCurrentTimeStamp()}`)
}

function fetchBiggestMovers() {
    return fetchSafe(`/api/dashboard/biggest-movers/${getCurrentTimeStamp()}`)
}

function fetchPxTalkDeviation() {
    return fetchSafe(`/api/dashboard/pxtalk-deviation/${getCurrentTimeStamp()}`)
}

function fetchBwicPercentOfTrace() {
    return fetchSafe(`/api/dashboard/bwic-percent-of-trace/${getCurrentTimeStamp()}`)
}

function fetchIssueTransactionVolumesCount({
    from,
    to,
    transactionType = [TransactionType.newIssue, TransactionType.refi, TransactionType.reset],
    currencyCode = Currency.USD,
    statuses = TransactionStatus.Closed,
    collateralTypes = [],
    esg = null,
}) {
    const filterCriteria = {
        'pricingDate.From':  moment.utc(from || [constants.minChartYear]).format(constants.dateTimeFormatUtc),
        'pricingDate.To': moment.utc(to).format(constants.dateTimeFormatUtc),
        types: transactionType,
        currencyCode,
        statuses,
        collateralTypes,
        esg,
    };

    const url = urlBuilder(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/dashboard/volumes-count`,
        filterCriteria
    );

    return fetchSafe(url);
}

function fetchVolumeCountByTransactionTypes({
    from,
    to,
    transactionType = TransactionType.newIssue,
    currencyCode = Currency.USD,
    statuses = TransactionStatus.Closed,
    collateralTypes = [],
    euCompliance = null,
}) {
    const filterCriteria = {
        'pricingDate.From': moment.utc(from || [constants.minChartYear]).format(constants.dateTimeFormatUtc),
        'pricingDate.To': moment.utc(to).format(constants.dateTimeFormatUtc),
        types: transactionType,
        currencyCode,
        statuses,
        collateralTypes,
        euCompliance,
    };

    const url = urlBuilder(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/dashboard/volumes-count-transaction-types`,
        filterCriteria
    );

    return fetchSafe(url);
}

function fetchVolumesCountShortLongDated({
    managerReferenceName,
    from,
    to,
    transactionTypes = [TransactionType.newIssue],
    currencyCode,
    statuses = TransactionStatus.Closed,
    nonCallPeriodEndYearsShort,
    reinvestmentPeriodEndYearsShort,
    nonCallPeriodEndYearsLong,
    reinvestmentPeriodEndYearsLong,
}) {
    const [ncEndShortFrom, ncEndShortTo] = nonCallPeriodEndYearsShort;
    const [riEndShortFrom, reEndShortTo] = reinvestmentPeriodEndYearsShort;
    const [ncEndLongFrom, ncEndLongTo] = nonCallPeriodEndYearsLong;
    const [riEndLongFrom, riEndLongTo] = reinvestmentPeriodEndYearsLong;

    const filterCriteria = {
        managerReferenceName,
        'pricingDate.From': moment.utc(from || [constants.minChartYear]).format(constants.dateTimeFormatUtc),
        'pricingDate.To': moment.utc(to).format(constants.dateTimeFormatUtc),
        types: transactionTypes,
        currencyCode,
        statuses,
        'nonCallPeriodEndDaysShort.From': moment.duration(ncEndShortFrom, 'years').asDays(),
        'nonCallPeriodEndDaysShort.To': moment.duration(ncEndShortTo, 'years').asDays(),

        'reinvestmentPeriodEndDaysShort.From': moment.duration(riEndShortFrom, 'years').asDays(),
        'reinvestmentPeriodEndDaysShort.To': moment.duration(reEndShortTo, 'years').asDays(),

        'nonCallPeriodEndDaysLong.From': moment.duration(ncEndLongFrom, 'years').asDays(),
        'nonCallPeriodEndDaysLong.To': moment.duration(ncEndLongTo, 'years').asDays(),

        'reinvestmentPeriodEndDaysLong.From': moment.duration(riEndLongFrom, 'years').asDays(),
        'reinvestmentPeriodEndDaysLong.To': moment.duration(riEndLongTo, 'years').asDays(),
    };

    const url = urlBuilder(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/dashboard/volumes-count-short-long-dated`,
        filterCriteria
    );

    return fetchSafe(url);
}

function fetchIssuanceSpreads({
    managerReferenceName,
    from,
    to,
    transactionTypes = [TransactionType.newIssue],
    statuses = TransactionStatus.Closed,
    collateralTypes = [CollateralType.broadlySyndicated, CollateralType.middleMarket],
    floaterIndexes = [],
    classRatings = [],
    currencyCodes = [Currency.USD],
    nonCallPeriodEndYears = [],
    reinvestmentPeriodEndYears = [],
}) {
    const [ncEndFrom, ncEndTo] = nonCallPeriodEndYears;
    const [riEndFrom, riEndTo] = reinvestmentPeriodEndYears;

    const ncEndPeriod = !isNil(ncEndFrom) && !isNil(ncEndTo) ? {
        'nonCallPeriodEndDays.From': moment.duration(ncEndFrom, 'years').asDays(),
        'nonCallPeriodEndDays.To': moment.duration(ncEndTo, 'years').asDays(),
    } : {};

    const riEndPeriod = !isNil(riEndFrom) && !isNil(riEndTo) ? {
        'reinvestmentPeriodEndDays.From': moment.duration(riEndFrom, 'years').asDays(),
        'reinvestmentPeriodEndDays.To': moment.duration(riEndTo, 'years').asDays(),
    } : {};

    const filterCriteria = {
        managerReferenceName,
        'pricingDate.From': moment.utc(from || [constants.minChartYear]).format(constants.dateTimeFormatUtc),
        'pricingDate.To': moment.utc(to).format(constants.dateTimeFormatUtc),
        types: transactionTypes,
        currencyCodes,
        statuses,
        collateralTypes,
        floaterIndexes,
        classRatings,
        ...ncEndPeriod,
        ...riEndPeriod,
    };

    const url = urlBuilder(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/dashboard/issuance-spreads`,
        filterCriteria
    );

    return fetchSafe(url);
}

function fetchKIndicator() {
    return fetchSafe(`/api/dashboard/market-stress/${getCurrentTimeStamp()}`)
}

function fetchPortfolioInventorySecurities(pageSize, page, orderByColumn, sortOrder) {
    const url = urlBuilder(
        '/api/dealers-inventory/included-to-portfolio',
        {
            pageSize,
            page,
            sortOrder,
            orderByColumn
        }
    )
    return fetchSafe(url)
}

function fetchNewIssueWeeklySpreads() {
    const url = urlBuilder(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/dashboard/waswarfds`,
        {
            types: [TransactionType.newIssue],
            statuses: [TransactionStatus.Active],
            collateralTypes: [CollateralType.broadlySyndicated],
            currencyCode: Currency.USD
        }
    );

    return fetchSafe(url);
}

export const dashboardService = {
    fetchDailyTradingStats,
    fetchExportTradingStats,
    fetchScheduledBwics,
    fetchPortfolioSecurities,
    exportBwicStatistic,
    fetchColorRates,
    fetchDntRates,
    fetchSameDayPeriods,
    fetchDealersStatsExists,
    fetchDealersStats,
    exportDealersStats,
    fetchSellersStatsExists,
    fetchClientsStats,
    exportSellerStats,
    fetchPriceLevel,
    fetchDtcSellingBuying,
    fetchMedianAAA,
    fetchAAAPriceLevel,
    fetchDtcInvestmentGrade,
    fetchMostTradedTickers,
    fetchBiggestMovers,
    fetchPxTalkDeviation,
    fetchBwicPercentOfTrace,
    fetchTopChart,
    exportTopChart,
    fetchDeals,
    fetchExportDeals,
    fetchPortfolioInventorySecurities,
    fetchIssueTransactionVolumesCount,
    fetchVolumeCountByTransactionTypes,
    fetchVolumesCountShortLongDated,
    fetchIssuanceSpreads,
    fetchKIndicator,
    fetchNewIssueWeeklySpreads
};
