import { takeEvery, call, put, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { releaseActions } from '../actions/release.actions';
import { releaseService } from '../services/release.service';
import { errorActions } from '../actions';
import { RequestState } from '../constants/request-state';
import { Releases } from '../types/marketing/Release';
import { AppState } from '../types/state/AppState';
import { isRequesting, isRequestSuccess } from '../utils';
import { onBoardingTooltipService } from '../services';
import { user } from '../user';
import { onBoardingTooltipConst } from '../constants';
import { OnBoardingTooltipType } from '../types/onboarding-tooltips/OnBoardingTooltipType';

function* watchGetReleaseRequest() {
    const requestState: RequestState = yield select((s: AppState) => s.release.requestState);
    if (isRequestSuccess(requestState) || isRequesting(requestState)) {
        return
    }
    try {
        yield put(releaseActions.getReleaseRequest());
        const responseReleases: Releases = yield call(releaseService.fetchReleases);
        yield put(releaseActions.getReleaseResponse(RequestState.success, responseReleases))
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(releaseActions.getReleaseResponse(RequestState.failure));
    }
}

function* watchMarkAsReadRequest() {
    try {
        yield call(onBoardingTooltipService.setCount, [{page: OnBoardingTooltipType.whatsNewPopup, count: onBoardingTooltipConst.maxCountLimit}]);
        user.setOnBoardingTooltipCounter(OnBoardingTooltipType.whatsNewPopup, onBoardingTooltipConst.maxCountLimit)
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(releaseActions.markAsReadResponse())
    }
}

export function* watchRelease() {
    yield takeEvery(getType(releaseActions.fetchReleases), watchGetReleaseRequest);
    yield takeEvery(getType(releaseActions.markAsReadRequest), watchMarkAsReadRequest);
}
