import { ActionType } from '../types/ActionType';
import { BrokerDealerCompanySlim } from '../types/company/BrokerDealerCompanySlim';
import { put, takeEvery, all, select } from 'redux-saga/effects';
import { newBwicDealersActions } from '../actions/new-bwic-dealers.actions';
import { accountActions } from '../constants';
import { getType } from 'typesafe-actions';
import { apiUtils } from '../utils/api.utils';
import { brokerDealerContactListActions } from '../actions/broker-dealer-contact-list.actions';
import { Contact } from '../types/company/Contact';
import { AppState } from '../types/state/AppState';
import { UserSlim } from '../types/account/UserSlim';
import { BwicProcessType } from '../types/models/Process';
import { CompanyStatus } from '../types/company/CompanyStatus';

function* watchSelectAllDealersByDefault(action: ActionType<{ dealers: BrokerDealerCompanySlim[] }>) {
    const { dealers } = action.payload;
    const liveBidding: boolean = yield select((s: AppState) => s.process.templates.current?.type === BwicProcessType.Live);

    // Leave off-platform companies unchecked if live bidding
    const dealersToSelect = liveBidding
        ? dealers.filter(d => d.status === CompanyStatus.active)
        : dealers;

    const list = apiUtils.normalize(dealersToSelect, d => d.id, () => true);
    yield put(newBwicDealersActions.selectDealers(list));
}

function* watchPushUpdateDealerContacts(action: ActionType<{ contacts: Contact[] }>) {
    const selectedByDefault: boolean = yield select((state: AppState) => state.newBwicDealers.selectedByDefault);
    const liveBidding: boolean = yield select((s: AppState) => s.process.templates.current?.type === BwicProcessType.Live);

    if (!selectedByDefault) {
        return false;
    }
    const { contacts } = action.payload;
    const selectedDealers: {[dealerId: number]: boolean} = yield select((s:AppState) => s.newBwicDealers.selectedDealers);
    const brokerDealers: BrokerDealerCompanySlim[] = yield select((state: AppState) => state.entities.brokerDealerList.items);
    const brokerDealerContacts: Contact[] = yield select((state: AppState) => state.entities.brokerDealerContactList.items);
    const brokerDealerUsers: UserSlim[] = yield select((state: AppState) => state.entities.brokerDealerUserList.items);
    yield all(contacts.map(c => {
        const brokerDealer = brokerDealers.find(d => d.id === c.companyId);
        if (!brokerDealer) return null;

        const isLiveBiddingOffPlatform = liveBidding && brokerDealer.status !== CompanyStatus.active;

        if (!c.deleted && !selectedDealers[c.companyId] && !isLiveBiddingOffPlatform) {
            return put(newBwicDealersActions.selectDealer(c.companyId))
        }
        
        if (
            c.deleted &&
            selectedDealers[c.companyId] &&
            brokerDealer &&
            !brokerDealer.isClearingBank &&
            !brokerDealer.headOfTrading &&
            !brokerDealer.distributionList &&
            !(liveBidding && brokerDealer.status !== CompanyStatus.active) &&
            !brokerDealerContacts.some(contact => contact.companyId === c.companyId) &&
            !brokerDealerUsers.some(user => user.companyId === c.companyId)
        ) {
            return put(newBwicDealersActions.selectDealer(c.companyId))
        }

        return null;
    }))
}

function* watchLogout() {
    yield put(newBwicDealersActions.reset())
}

export function* watchNewBwicDealers() {
    yield takeEvery(getType(newBwicDealersActions.selectDealersByDefault), watchSelectAllDealersByDefault);
    yield takeEvery(getType(brokerDealerContactListActions.pushUpdateList), watchPushUpdateDealerContacts);
    yield takeEvery(accountActions.LOGOUT, watchLogout);
}
