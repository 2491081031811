import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { securityDetailsActions } from '../actions/security.details.actions';
import { ratingService } from '../services';

function* watchSecurityDetailsInit(action: ActionType<typeof securityDetailsActions.securityDetailsInitRequest>): Generator<any, void, any> {
    try {
        const { security, fetchAgencyRatings } = action.payload;
        const [ratings, mappings] = yield all([
            fetchAgencyRatings && call(ratingService.getSecurityRatings, security.securityId),
            call(ratingService.getRatingMappings),
        ]);

        yield put(securityDetailsActions.securityDetailsInitSuccess(ratings, mappings));
    } catch (e) {
        yield put(securityDetailsActions.securityDetailsInitFailure(e));
    }
}

export function* watchSecurityDetails() {
    yield takeLatest(getType(securityDetailsActions.securityDetailsInitRequest), watchSecurityDetailsInit);
}
