import { fetchSafe } from './fetch.safe';
import { responseType } from '../constants';
import { urlBuilder } from './url.builder';

export const parsedBwicsService = {
    search,
    publish,
    unpublish,
    save,
    deleteBwic,
    cancel,
    changeAmrFlag
};

function search(searchCriteria, page, pageSize) {
    const url = urlBuilder('/api/parsed-bwics/search', { page, pageSize });
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(searchCriteria)
    };

    return fetchSafe(url, requestOptions);
}

function publish(referenceName) {
    const url = '/api/parsed-bwics/publish';
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ referenceName }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function unpublish(referenceName) {
    const url = '/api/parsed-bwics/unpublish';
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ referenceName }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function save(payload) {
    const url = '/api/parsed-bwics';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(payload),
    };

    return fetchSafe(url, requestOptions);
}

function deleteBwic(referenceName) {
    const url = `/api/parsed-bwics/${referenceName}`;
    const requestOptions = {
        method: 'DELETE',
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function cancel(referenceName) {
    const url = `/api/parsed-bwics/cancel/${referenceName}`;
    const requestOptions = {
        method: 'PUT',
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function changeAmrFlag(bwicReferenceName, isAMR) {
    return fetchSafe(
        `/api/parsed-bwics/change-amr-flag/${bwicReferenceName}`,
        {
            method: 'PUT',
            body: JSON.stringify({ isAMR })
        });
}
