import { fetchSafe } from './fetch.safe';
import { responseType } from '../constants';
import { urlBuilder } from './url.builder';
import { getFromCache } from './cache';


export const companiesService = {
    getCompanies,
    getBrokerDealers,
    getBrokerDealer,
    getActiveAssetManagers,
    getCompany,
    getCompanyDomains,
    getCompanyMembers,
    getCurrentUserCompany,
    postCompanyApprove,
    postCompanyReject,
    changeCompanyStatus,
    save,
    setMemberOfacStatus,
    setCompanyOfacStatus,
    getBwicParticipants,
    sendDistributionListInvite,
    sendHeadOfTrdingInvite,
    getFavorites,
    addToFavorites,
    deleteFromFavorites,
    exportCompanies,
    getClearingBankBwicParticipants
};

function getCompanies() {
    return fetchSafe('/api/companies');
}

function getBrokerDealers() {
    return fetchSafe('/api/companies/broker-dealers');
}

function getBrokerDealer(referenceName) {
    return fetchSafe(`/api/companies/broker-dealers/${referenceName}`);
}

function getActiveAssetManagers() {
    return fetchSafe('/api/companies/asset-managers');
}

function getCompany(referenceName, companyId) {
    const url = urlBuilder('/api/companies/single', { referenceName, companyId });
    return fetchSafe(url);
}

function getCompanyDomains() {
    return fetchSafe('/api/companies/domains');
}

function getCurrentUserCompany(disableRequestCancelation = false) {
    return getFromCache('/api/companies/current-user', undefined, undefined, disableRequestCancelation);
}

function postCompanyApprove(companyId) {
    const url = `/api/companies/${companyId}/approve`;
    const requestOptions = {
        method: 'POST',
        body: '',
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function postCompanyReject(companyId, rejectReason) {
    const url = `/api/companies/${companyId}/reject`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(rejectReason),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function changeCompanyStatus(companyId, status, reason) {
    const url = `/api/companies/${companyId}/status`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ status, reason }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function save(companySaveModel) {
    const url = '/api/companies';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(companySaveModel),
    };

    return fetchSafe(url, requestOptions);
}

function getCompanyMembers(companyId) {
    return fetchSafe(`/api/companies/${companyId}/members`);
}

function setMemberOfacStatus(memberId, status) {
    const url = `/api/users/${memberId}/ofac`;
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(status)
    };
    return fetchSafe(url, requestOptions);
}

function setCompanyOfacStatus(companyId, status) {
    const url = `/api/companies/${companyId}/ofac`;
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(status)
    };
    return fetchSafe(url, requestOptions);
}


function getBwicParticipants(bwicReferenceName) {
    return fetchSafe(`/api/bwics/participants/${bwicReferenceName}`);
}

function sendDistributionListInvite(companyId) {
    const url = '/api/invitation/invite-distribution-list';
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ companyId }),
        responseType: responseType.none
    };
    return fetchSafe(url, requestOptions);
}

function sendHeadOfTrdingInvite(companyId) {
    const url = '/api/invitation/invite-head-of-trading';
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ companyId }),
        responseType: responseType.none
    };
    return fetchSafe(url, requestOptions);
}

function getFavorites() {
    return fetchSafe(`/api/companies/favourites`);
}

function addToFavorites(companyId) {
    const requestOptions = {
        method: 'PATCH',
        body: companyId,
    };
    return fetchSafe(`/api/companies/favourites`, requestOptions);
}

function deleteFromFavorites(companyId) {
    const requestOptions = {
        method: 'DELETE',
    };
    return fetchSafe(`/api/companies/favourites/${companyId}`, requestOptions);
}

function getClearingBankBwicParticipants(bwicReferenceName) {
    return fetchSafe(`/api/companies/clearing-banks/${bwicReferenceName}`);
}

function exportCompanies(searchTerms, statuses, roles, regByFrom, regByTo, salesRepresentativeIds, regByIds, orderByColumn, sortOrder) {
    const url = urlBuilder('/api/companies/export', {
        searchTerms,
        statuses,
        roles,
        regByFrom,
        regByTo,
        salesRepresentativeIds,
        regByIds,
        orderByColumn,
        sortOrder
    })
    return fetchSafe(url, { responseType: responseType.file })
}
