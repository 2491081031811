import { ActionType } from '../types/ActionType';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { userRequestAdminActions } from '../actions/user-request-admin.actions';
import { errorActions } from '../actions/error.actions';
import { RequestState } from '../constants/request-state';
import { User } from '../types/management/User';
import { usersService } from '../services/users.service';
import { notificationActions } from '../actions';
import { UserCreationRequest } from '../types/management/UserCreationRequest';
import { logger } from '../logging/logger';

function* watchApprove(action: ActionType<{ userRequestId: number, concurrencyStamp: Date }>) {
    yield put(userRequestAdminActions.setOperationState(action.payload.userRequestId, RequestState.request));

    try {
        const user: User = yield call(
            usersService.approveUserRequest,
            action.payload.userRequestId,
            action.payload.concurrencyStamp
        );

        yield put(userRequestAdminActions.userRequestApproved(action.payload.userRequestId, user));
        yield put(userRequestAdminActions.setOperationState(action.payload.userRequestId, RequestState.success));
    } catch (e) {
        yield handleRequestError(action.payload.userRequestId, e);
    }
}

function* watchBlock(action: ActionType<{ userRequestId: number, concurrencyStamp: Date, blockReason: string }>) {
    yield put(userRequestAdminActions.setOperationState(action.payload.userRequestId, RequestState.request));

    try {
        const blockedUserRequest: UserCreationRequest = yield call(
            usersService.blockUserRequest,
            action.payload.userRequestId,
            action.payload.concurrencyStamp,
            action.payload.blockReason
        );
        yield put(userRequestAdminActions.userRequestBlocked(blockedUserRequest));
        yield put(userRequestAdminActions.setOperationState(action.payload.userRequestId, RequestState.success));
    } catch (e) {
        yield handleRequestError(action.payload.userRequestId, e);
    }
}

function* watchUnblock(action: ActionType<{ userRequestId: number, concurrencyStamp: Date }>) {
    yield put(userRequestAdminActions.setOperationState(action.payload.userRequestId, RequestState.request));

    try {
        const unblockedUserRequest: UserCreationRequest = yield call(
            usersService.unblockUserRequest,
            action.payload.userRequestId,
            action.payload.concurrencyStamp,
        );
        yield put(userRequestAdminActions.userRequestUnblocked(unblockedUserRequest));
        yield put(userRequestAdminActions.setOperationState(action.payload.userRequestId, RequestState.success));
    } catch (e) {
        yield handleRequestError(action.payload.userRequestId, e);
    }
}

function* handleRequestError(userRequestId: number, e: { status: number }) {
    yield put(userRequestAdminActions.setOperationState(userRequestId, RequestState.failure));
    if (e.status === 409) {
        yield put(notificationActions.notificationAddErrorMessageModal(
            "It is likely that the сreate user request has already been approved/blocked. Please refresh the page'. ",
            "",
            true)
        );
    } else {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchDeleteUserRequest(action: ActionType<{ userRequestId: number }>) {
    try {
        yield call(usersService.deleteUserRequest, action.payload.userRequestId);
        yield put(userRequestAdminActions.userRequestDeleted(action.payload.userRequestId));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchGetPendingUserRequestCount() {
    try {
        const count: number = yield call(usersService.gePendingUserRequestCount);
        yield put(userRequestAdminActions.setPendingUserRequestCount(count, RequestState.success));
    } catch (e) {
        yield put(userRequestAdminActions.setPendingUserRequestCount(0, RequestState.failure));
        logger.exception(e, 'could not get pending user request count')
    }
}

export function* watchUserRequestAdmin() {
    yield takeEvery(getType(userRequestAdminActions.approve), watchApprove);
    yield takeEvery(getType(userRequestAdminActions.block), watchBlock);
    yield takeEvery(getType(userRequestAdminActions.unblock), watchUnblock);
    yield takeEvery(getType(userRequestAdminActions.deleteUserRequest), watchDeleteUserRequest);
    yield takeEvery(getType(userRequestAdminActions.getPendingUserRequestCount), watchGetPendingUserRequestCount)
}
