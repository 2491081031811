import { createSelector } from 'reselect';
import { SettlementAgreementStatus } from '../types/bid-as-dealer/SettlementAgreementStatus';
import { SettlementAgentAgreement } from '../types/bid-as-dealer/SettlementAgentAgreement';
import { BwicPosition } from '../types/bwic/BwicPosition';
import { CompanySlim } from '../types/company/CompanySlim';
import { CompanyStatus } from '../types/company/CompanyStatus';
import { BwicProcessType } from '../types/models/Process';
import { CompanyHiddenState } from '../types/state/DealerListPanelState';
import { FavoriteCompanyState } from '../types/state/FavoriteBrokerDealersState';
import { arrayUtils } from '../utils/array.utils';
import { apiUtils } from '../utils/api.utils';
import { biddingUtils } from '../utils/bidding.utils';
import { compareBoolean } from '../utils/compare.utils';

const getParticipants = (state: { participants: CompanySlim[] }) => { return state.participants };
const getOffPlatformCompanies = (state: { offPlatformCompanies: CompanySlim[] }) => state.offPlatformCompanies;
const getHiddenState = (state: { hiddenState: CompanyHiddenState }) => state.hiddenState;
const getFavorites = (state: { favorites: FavoriteCompanyState }) => state.favorites;
const getPositions = (state: { securities: BwicPosition[] }) => state.securities;
const getProcessType = (state: { processType: BwicProcessType }) => state.processType
const getAgreements = (state: { agreements: SettlementAgentAgreement[] }) => state.agreements;
const getAllToAllFlag = (state: { isAllToAll?: boolean}) => state.isAllToAll;

export const getBuyers = (securities: BwicPosition[]) => {
    const allBuyersBids = securities.map(s => s?.bids ?? []).flat().filter(b => b.buyerPseudoOrderNumber);
    return Array.from(arrayUtils
        .groupBy(allBuyersBids, b => b.buyerPseudoOrderNumber)
        .entries())
        .map(([, [{ buyerPseudoOrderNumber, company }]]) => ({
            ...company,
            buyerPseudoOrderNumber,
            identifier: biddingUtils.getBidCompanyIdentifier({ company, buyerPseudoOrderNumber })
        }));
}


export const getSellerBiddingCompanies = createSelector(
    getParticipants,
    getOffPlatformCompanies,
    getHiddenState,
    getFavorites,
    getProcessType,
    getAgreements,
    getPositions,
    getAllToAllFlag,
    (participans: CompanySlim[],
        offPlatformCompanies: CompanySlim[],
        hiddenState: CompanyHiddenState,
        favorites: FavoriteCompanyState,
        processType,
        agreements: SettlementAgentAgreement[],
        securities: BwicPosition[],
        isAllToAll?: boolean
    ) => {
        const brokerDealers = participans
            .filter(p => p.status === CompanyStatus.active && (!p.isClearingBank || !isAllToAll))
            .concat(processType === BwicProcessType.Live ? [] : offPlatformCompanies)
            .map(company => ({ ...company, identifier: biddingUtils.getBidCompanyIdentifier({ company }) }))
            .filter(c => !hiddenState[c.identifier]);

        const buyers = getBuyers(securities).filter(c => !hiddenState[c.identifier]);

        return [...sortBrokerDealerCompanies(brokerDealers, favorites, agreements), ...buyers];
    }
);

export const getSellerFinishedCompanies = createSelector(
    getParticipants,
    getOffPlatformCompanies,
    getFavorites,
    getPositions,
    getAgreements,
    getAllToAllFlag,
    (participans: CompanySlim[],
        offPlatformCompanies: CompanySlim[],
        favorites: FavoriteCompanyState,
        securities: BwicPosition[],
        agreements: SettlementAgentAgreement[],
        isAllToAll?: boolean
    ) => {
        const companies =
            participans
                .filter(c => c.status === CompanyStatus.active && (!c.isClearingBank || !isAllToAll))
                .concat(offPlatformCompanies)

        const brokerDealers = companies
            .map(company => ({ ...company, identifier: biddingUtils.getBidCompanyIdentifier({ company }) }))
            .filter(c => securities.some(s => s.bids?.some(b => biddingUtils.getBidCompanyIdentifier(b) === c.identifier)))
            
        const buyers = getBuyers(securities);

        return [...sortBrokerDealerCompanies(brokerDealers, favorites, agreements), ...buyers];
    }
);

export function sortBrokerDealerCompanies(companies: CompanySlim[], favorites: FavoriteCompanyState, agreements: SettlementAgentAgreement[]) {
    const confirmedAgreementsByDealer = apiUtils.normalize(
        agreements.filter(a =>
            a.settlementAgent.isClearingBank &&
            a.agreementStatus === SettlementAgreementStatus.confirmed),
        (a: SettlementAgentAgreement) => a.settlementAgent.id,
        () => true
    );

    return [...companies].sort((a, b) =>
        compareBoolean(favorites[a.id], favorites[b.id]) ||
        compareBoolean(confirmedAgreementsByDealer[a.id], confirmedAgreementsByDealer[b.id]) ||
        compareBoolean(a.status === CompanyStatus.active, b.status === CompanyStatus.active) ||
        a.name.localeCompare(b.name)
    );
}
