import { getFromCache } from './cache';
import { fetchSafe } from './fetch.safe';

export const ratingService = {
    getSecurityRatings,
    getRatingMappings
}

function getSecurityRatings(securityId) {
    return fetchSafe(`/api/ratings/${securityId}`);
}

function getRatingMappings() {
    return getFromCache('/api/ratings/mappings');
}
