import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { portfolioAgreementDisclaimerActions } from '../actions/portfolio-agreement-disclaimer.actions';
import { accountActions } from '../constants';
import { accountService } from '../services';
import { errorActions } from '../actions';
import { user } from '../user';
import { saveAs } from 'file-saver';

function* watchConfirmRequest() {
    try {
        yield call(accountService.setPortfolioAgreementSigned);
        user.setPortfolioAgreementSigned(true);
        yield put(portfolioAgreementDisclaimerActions.hide(true));
        yield put(portfolioAgreementDisclaimerActions.confirmSuccess());
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(portfolioAgreementDisclaimerActions.confirmFailure());
    }
}

function* watchDownloadFileRequest() {
    try {
       const file: { name: string, blob: Blob } = yield call(accountService.downloadPortfolioAgreementFile);
        saveAs(file.blob, file.name);
        yield put(portfolioAgreementDisclaimerActions.downloadFileSuccess());
    } catch (e) {
        yield put(portfolioAgreementDisclaimerActions.downloadFileFailure());
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchLogout() {
    yield put(portfolioAgreementDisclaimerActions.reset());
}

export function* watchPortfolioAgreementDisclaimer() {
    yield takeLatest(getType(portfolioAgreementDisclaimerActions.confirmRequest), watchConfirmRequest);
    yield takeLatest(getType(portfolioAgreementDisclaimerActions.downloadFileRequest), watchDownloadFileRequest);
    yield takeEvery(accountActions.LOGOUT, watchLogout);
}
