import qs from 'query-string';
import { City } from '../types/location/City';
import { Country } from '../types/location/Country';
import { State } from '../types/location/State';
import { fetchSafe } from './fetch.safe';

function getCountries(): Promise<Country> {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/address/all-countries`);
}

function getStates(countryId: number): Promise<State> {
    const queryParams = qs.stringify({ countryId });

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/address/states?${queryParams}`);
}

function getCities(countryId: number, stateId?: number): Promise<City> {
    const queryParams = qs.stringify({ countryId, stateId });

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/address/cities?${queryParams}`);
}

export const addressService = {
    getCountries,
    getStates,
    getCities,
};
