import moment from 'moment';
import qs from 'query-string';
import { fetchSafe } from './fetch.safe';
import { BankSave } from '../types/banks/BankSave';
import { constants, responseType } from '../constants';
import { BankAccessType } from '../types/amr-pipeline/enums/BankAccessType';
import { Currency } from '../types/enums/Currency';
import { CollateralType } from '../types/amr-pipeline/enums/CollateralType';

export interface ExportManagersTransQueryParams {
    year?: number,
    currencyCodes?: Currency[],
    collateralTypes?: CollateralType[],
    orderBy?: {
        field: string,
        ascending: boolean
    }
}

function getBwicBanksList(dateFrom: Date = new Date()) {
    const queryParams = {
        dateFrom: moment(dateFrom).format(),
    };

    const queryString = `?${qs.stringify(queryParams)}`;
    return fetchSafe(`/api/Companies/banks/${queryString}`);
}

function getBankDetails(companyReferenceName: string) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/Companies/${companyReferenceName}/bank`
    );
}

function getEvents(companyReferenceName: string) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/Companies/${companyReferenceName}/bank/events`
    );
}

function getSyndicateContacts(companyReferenceName: string) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/Companies/${companyReferenceName}/bank/syndicate-contacts`
    );
}

function updateBank(companyReferenceName: string, bank: BankSave): Promise<any> {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${companyReferenceName}/bank`, {
        method: 'PUT',
        body: JSON.stringify(bank),
        responseType: responseType.none,
    });
}

function logUserActivity(companyReferenceName: string, accessType: BankAccessType) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${companyReferenceName}/bank/access/${accessType}`, {
        method: 'POST',
        responseType: responseType.none,
    });
}

function getBwicsPriceTalks(companyReferenceName: string, dateFrom: Date = new Date()) {
    const queryParams = {
        dateFrom: moment(dateFrom).format(),
    };

    const queryString = `?${qs.stringify(queryParams)}`;

    return fetchSafe(`/api/bwics/banks/${companyReferenceName}/bwics-price-talks/${queryString}`)
}

function getAnalytics(companyReferenceName: string) {
    const queryString = qs.stringify({ currentTime: moment().format() });

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${companyReferenceName}/bank/analytics?${queryString}`);
}

function getProfileViewHistory(companyReferenceName: string, startDate?: Date, endDate?: Date) {
    let queryString = '';

    if (startDate && endDate) {
        const queryParams = {
            'period.From': moment(startDate).format(constants.dateTimeFormatUtc),
            'period.To': moment(endDate).format(constants.dateTimeFormatUtc),
        };

        queryString = `?${qs.stringify(queryParams)}`;
    }

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${companyReferenceName}/bank/profile-views/${queryString}`);
}

function exportBankManagersTrans(companyReferenceName: string, params?: ExportManagersTransQueryParams) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/Companies/${companyReferenceName}/bank/managers-transactions/export`, {
        method: 'POST',
        body: JSON.stringify(params),
        responseType: responseType.file
    });
}

export const banksService = {
    getBwicBanksList,
    getBankDetails,
    getEvents,
    getSyndicateContacts,
    updateBank,
    logUserActivity,
    getBwicsPriceTalks,
    getAnalytics,
    getProfileViewHistory,
    exportBankManagersTrans,
};
