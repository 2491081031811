import { pushNotificationService } from '../services';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { errorActions, notificationActions } from '../actions';
import { accountActions } from '../constants';
import { user } from '../user';
import { AppState } from '../types/state/AppState';
import { Notification } from '../types/notifications/Notification';
import { NotificationType } from '../types/notifications/NotificationType';
import { ActionType } from '../types/ActionType';
import { apiUtils } from '../utils/api.utils';
import { NotificationAttributes } from '../types/notifications/NotificationAttributes';

function* watchNotificationServiceConnect() {
    yield call(pushNotificationService.start);
}

function* watchAuthLoginSuccess() {
    yield put(notificationActions.notificationServiceConnect());
}

function* watchAuthLogout() {
    yield call(pushNotificationService.stop);
    yield put(notificationActions.notificationReset())
}

function* watchNotificationGetList() {
    const notificationAttributes: NotificationAttributes = yield select((state: AppState) => state.notification.notificationAttributes);
    const notifications: Notification[] = yield select(
        (state: AppState) =>
            state.notification.notificationList
                .filter(n => notificationAttributes[n.referenceName]?.displayInNotificationList)
    );

    let lastNotificationReferenceName = null;

    if (notifications.length) {
        lastNotificationReferenceName = notifications[notifications.length - 1].referenceName
    }

    try {
        const newNotificationList: Notification[] = yield pushNotificationService.send('GetNotificationsBefore', lastNotificationReferenceName);
        const notificationAttributes = apiUtils.normalize(newNotificationList, n => n.referenceName, () => ({
            type: NotificationType.PushNotification,
            toastVisible: false,
            displayInNotificationList: true
        }));
        yield put(notificationActions.appendNotifications(newNotificationList, notificationAttributes))
    } catch (err) {
        yield put(notificationActions.notificationGetListFailure());
        yield put(errorActions.unexpectedError(err));
    }
}

function* watchNotificationsGetCountUnread() {
    try {
        const countUnread: number = yield pushNotificationService.send('CountUnreadNotifications');
        yield put(notificationActions.notificationsSetCountUnread(countUnread))
    } catch (err) {
        yield put(notificationActions.notificationsGetCountUnreadFailure());
    }
}

function* watchNotificationSetReadRequest(action: ActionType<{ referenceName: string }>) {
    const { referenceName } = action.payload;

    try {
        yield pushNotificationService.send('SetNotificationRead', referenceName);
        yield put(notificationActions.notificationSetReadSuccess(referenceName));
        yield watchNotificationsGetCountUnread();
    } catch (err) {
        yield put(notificationActions.notificationSetReadFailure(referenceName));
        yield put(errorActions.unexpectedError(err));
    }
}

function* watchNotificationsReadAllRequest() {
    try {
        yield pushNotificationService.send('SetAllNotificationsRead');
        yield put(notificationActions.notificationsReadAllSuccess());
    } catch (err) {
        yield put(notificationActions.notificationsReadAllFailure());
        yield put(errorActions.unexpectedError(err));
    }
}

export function* watchNotifications() {
    yield call(pushNotificationService.build);
    yield takeEvery(getType(notificationActions.notificationServiceConnect), watchNotificationServiceConnect);
    yield takeEvery(accountActions.LOGIN_SUCCESS, watchAuthLoginSuccess);
    yield takeEvery(accountActions.LOGOUT, watchAuthLogout);
    if (user.isAuthenticated()) {
        yield put(notificationActions.notificationServiceConnect());
    }
    yield takeLatest(notificationActions.notificationGetList, watchNotificationGetList);
    yield takeLatest(notificationActions.notificationsGetCountUnread, watchNotificationsGetCountUnread);
    yield takeLatest(notificationActions.notificationSetReadRequest, watchNotificationSetReadRequest);
    yield takeLatest(notificationActions.notificationsReadAllRequest, watchNotificationsReadAllRequest)
}
