import { fetchSafe } from './fetch.safe';
import { responseType } from '../constants';
import { logger } from '../logging/logger';

export const trackingService = {
    trackAction
}

function trackAction(eventName: string, values?: { [key: string | number]: string | number }) {
    const url = `/api/tracking`;
    const requestOptions = {
        method: 'put',
        body: JSON.stringify({ eventName, values }),
        responseType: responseType.none
    };

    try {
        fetchSafe(url, requestOptions);
    } catch (e) {
        logger.exception(e, 'Failed to track some event');
    }
}