import { fetchSafe } from './fetch.safe';
import { responseType, constants } from '../constants';
import moment from 'moment';

export const tradingService = {
    search: ({ isinCusipsAndTickers, statuses, settlementStatuses, currency, side, type, dateFrom, dateTo, settlFrom, settlTo,
        orderByColumn, sortOrder = 'ASC', page = 1, pageSize = 50 }) => {
        const url = '/api/trading/search';
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                isinCusipsAndTickers,
                statuses,
                settlementStatuses,
                currency,
                side,
                type,
                from: dateFrom && moment(dateFrom).format(constants.dateTimeFormatUtc),
                to: dateTo && moment(dateTo).format(constants.dateTimeFormatUtc),
                settlementFrom: settlFrom && moment(settlFrom).format(constants.dateTimeFormatUtc),
                settlementTo: settlTo && moment(settlTo).format(constants.dateTimeFormatUtc),
                orderByColumn,
                sortOrder,
                page,
                pageSize
            })
        };
        return fetchSafe(url, requestOptions);
    },

    doesExist: () => {
        return fetchSafe('/api/trading/exist');
    },

    blotterExport: (params) => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                clientDateTime: moment().format(),
                ...params,
                from: params.dateFrom && moment(params.dateFrom).format(constants.dateTimeFormatUtc),
                to: params.dateTo && moment(params.dateTo).format(constants.dateTimeFormatUtc),
                settlFrom: params.settlFrom && moment(params.settlFrom).format(constants.dateTimeFormatUtc),
                settlTo: params.settlTo && moment(params.settlTo).format(constants.dateTimeFormatUtc),
                orderByColumn: params.orderByColumn,
                sortOrder: params.sortOrder
            }),
            responseType: responseType.file
        };
        return fetchSafe('/api/trading/export', requestOptions)
    },

    setVcon: ({ tradeId, isBuyTrade, value }) => {
        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify({ referenceId: tradeId, buyTrade: isBuyTrade, value }),
        };
        return fetchSafe('api/trading/set-vcon', requestOptions)
    },

    setBooked: ({ tradeId, isBuyTrade, value }) => {
        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify({ referenceId: tradeId, buyTrade: isBuyTrade, value }),
        };
        return fetchSafe('api/trading/set-booked', requestOptions)
    },

    getAwaitingActionTrades: () => fetchSafe('/api/trading/trades-pending-action', {}, true),
}
