import { fetchSafe } from './fetch.safe';
import { PageConfig } from '../types/page-config/PageConfig';
import { PageConfigType } from '../types/page-config/PageConfigType';
import { UserConfigFilter } from '../types/user-config/UserConfigFilter';

export const pageConfigService = {
    getConfigs,
    saveConfig,
    getDefaultConfigs,
    saveFilters,
}

function getConfigs(): Promise<PageConfig[]> {
    return fetchSafe(`/api/page-config`, undefined, true);
}

function getDefaultConfigs(pageConfigType: PageConfigType): Promise<PageConfig> {
    return fetchSafe(`/api/page-config/${pageConfigType}/default`);
}

function saveConfig(pageConfigType: PageConfigType, pageConfig: PageConfig): Promise<PageConfig> {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(pageConfig)
    };
    return fetchSafe(`/api/page-config/${pageConfigType}`, requestOptions, true);
}

function saveFilters(page: PageConfigType, value: UserConfigFilter[]): Promise<any> {
    const requestParams = { method: 'POST', body: JSON.stringify({ page, value }) }
    return fetchSafe('/api/page-config', requestParams)
}
