import { responseType } from '../constants';
import { fetchSafe } from './fetch.safe';
import { fileUpload } from './file.upload';

export const bidOnBehalfService = {
    getCompanies,
    submitBidsBulk,
    uploadFile,
    parseClipboard,
    downloadTemplate
}

function getCompanies(bwicReferenceName) {
    return fetchSafe(`/api/bidding/on-behalf/${bwicReferenceName}/companies`);
}

function submitBidsBulk(bwicReferenceName, bids) {
    const url = `/api/bidding/on-behalf/${bwicReferenceName}`;
    const requestOptions = {
        method: 'post',
        body: JSON.stringify(bids)
    };

    return fetchSafe(url, requestOptions);
}

function uploadFile(file, progressCallback) {
    return fileUpload(
        '/api/bidding/on-behalf/parse/file',
        null,
        file,
        progressCallback
    );
}

function parseClipboard(text) {
    const url = '/api/parse/clipboard';

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ text })
    };

    return fetchSafe(url, requestOptions);
}

function downloadTemplate(bwicReferenceName) {
    return fetchSafe(
        `/api/bidding/on-behalf/template/${bwicReferenceName}`,
        { method: 'GET', responseType: responseType.file }
    );
}
