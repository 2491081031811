import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionType, getType } from "typesafe-actions";
import { signupActions } from "../actions/signup.actions";
import { notificationActions } from '../actions/notification.actions';
import { routes } from '../constants/routes';
import { accountService } from '../services/account.service';
import { history } from '../history';

function* watchSubmit(action: ActionType<typeof signupActions.submit>) {
    try {
        yield call(accountService.saveSignupForm, action.payload.request);
        yield put(signupActions.submitSuccess());
        yield call(history.replace, routes.signUpConfirm);
    } catch (e) {
        yield put(notificationActions.notificationAddErrorMessage('Sign up form submission failed'));
        yield put(signupActions.submitError());
    }
}

export function* watchSignup() {
    yield takeEvery(getType(signupActions.submit), watchSubmit);
}
