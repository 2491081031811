import { getFromCache } from './cache';
import { fetchSafe } from './fetch.safe';
import { responseType } from '../constants';
import { urlBuilder } from './url.builder';
import { CurrencyRate } from '../types/currency/CurrencyRate';

export const currencyService = {
    getCurrencies,
    getCurrencyRate,
    setCurrencyRate
};

function getCurrencies() {
    return getFromCache('/api/currencies')
}

function getCurrencyRate():Promise<CurrencyRate> {
    return fetchSafe('/api/currencies/get')
}

function setCurrencyRate(usdRate: number): Promise<void> {
    const url = urlBuilder(
        '/api/currencies/set-usdrate',
        {
            usdRate
        }
    );
    const requestOptions = {
        method: 'PUT',
        responseType: responseType.none
    };
    return fetchSafe(url, requestOptions)
}
