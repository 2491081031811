import moment from 'moment';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import saveAs from 'file-saver';
import { errorActions } from '../actions';
import { newsActions } from '../actions/news.actions';
import { releaseService } from '../services/release.service';
import { MarketingEventType, ReleaseItem, ReleaseItemType, Releases } from '../types/marketing/Release';
import { compact, find, flatMap } from 'lodash';
import { MarketingEvent } from '../types/marketing/MarketingEvent';
import { Conference } from '../types/marketing/Conference';
import { Webinar } from '../types/marketing/Webinar';
import { NewsNavigation } from '../types/news/News';
import { newsService } from '../services/news.service';
import { News } from '../types/news/News';
import { NewsCategory, NewsTabToCategory } from '../types/news/enums/NewsCategory';
import { AppState } from '../types/state/AppState';
import { constants, routes } from '../constants';
import { NewsTabCategory } from '../types/news/enums/NewsTabCategory';
import { history } from '../history';

// Here order matters — 1. New Feature, 2. Improvement
const ReleaseItemTypeGroups = [ReleaseItemType.NewFeature, ReleaseItemType.Improvement];

const convertReleaseItem = (releaseItem: ReleaseItem): MarketingEvent => ({
    title: releaseItem.releaseItemType === ReleaseItemType.NewFeature ? 'New Features' : 'Improvements',
    description: releaseItem.title,
    type: MarketingEventType.Release,
    link: process.env.REACT_APP_WHATS_NEW_POPUP_READ_MORE,
    imageReferenceName: releaseItem.imageReferenceName,
    releaseItemType: releaseItem.releaseItemType,
});

const convertWebinar = (webinar: Webinar): MarketingEvent => ({
    title: 'Webinar',
    description: webinar.title,
    type: MarketingEventType.Webinar,
    link: webinar.link,
    linkName: webinar.linkName,
    imageReferenceName: webinar.imageReferenceName,
    speakerTitle: webinar.speakerTitle,
    speakerName: webinar.speakerName,
});

const convertConference = (conference: Conference): MarketingEvent => ({
    title: 'Conference',
    description: conference.title,
    type: MarketingEventType.Conference,
    link: conference.link,
    linkName: conference.linkName,
    imageReferenceName: conference.imageReferenceName,
});

function composeEventsList(releases: Releases, conferences: Conference[], webinar?: Webinar) {
    const releaseItems = compact(flatMap(releases.releases, x =>
        x.releaseItems?.map(convertReleaseItem),
    ));

    // Find latest Improvements and New Features
    let orderedReleaseItems = ReleaseItemTypeGroups.map(itemType =>
        find(releaseItems, item => item.releaseItemType === itemType),
    );

    const [firstReleaseItem, secondReleaseItem] = orderedReleaseItems;

    if (conferences.length > 1 && firstReleaseItem && secondReleaseItem) {
        orderedReleaseItems = [{
            ...firstReleaseItem,
            description: `${firstReleaseItem?.description}. ${secondReleaseItem?.description}`,

            // Prioritize this type for tracking
            releaseItemType: ReleaseItemType.NewFeature,
            title: 'New Features and Improvements'
        } as MarketingEvent];
    }

    // Here order matters — 1. Webinars, 2. Conferences, 3. New Feature, 4. Improvements
    return compact([
        webinar
            ? convertWebinar(webinar)
            : undefined,
        ...conferences.map(convertConference),
        ...orderedReleaseItems,
    ]);
}

function* fetchMarketingEvents() {
    let conferences: Conference[];
    let webinar: Webinar;
    let releases: Releases;

    [conferences, webinar, releases] = yield all([
        call(releaseService.fetchConferences),
        call(releaseService.fetchWebinar),
        call(releaseService.fetchReleases),
    ]);

    return composeEventsList(releases, conferences, webinar);
}

function* watchFetchTotalNewsCount(action: ActionType<typeof newsActions.fetchTotalNewsCountRequest>) {
    try {
        const [
            totalNewsAll,
            totalNewsPrimary,
            totalNewsSecondary,
            totalNewsResearch,
        ]: number[] = yield all([
            call(newsService.getNewsTotalCount, {}),
            call(newsService.getNewsTotalCount, { categories: [NewsCategory.Primary] }),
            call(newsService.getNewsTotalCount, { categories: [NewsCategory.Secondary] }),
            call(newsService.getNewsTotalCount, { categories: [NewsCategory.Research] }),
        ]);

        yield put(
            newsActions.fetchTotalNewsCountResponse({
                [NewsTabCategory.All]: totalNewsAll,
                [NewsTabCategory.Primary]: totalNewsPrimary,
                [NewsTabCategory.Secondary]: totalNewsSecondary,
                [NewsTabCategory.Research]: totalNewsResearch,
            }),
        );
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchFetchMarketingEventRequest() {
    try {
        const events: MarketingEvent[] = yield fetchMarketingEvents();

        yield put(newsActions.fetchMarketingEventsResponse(events));
    } catch (e) {
        yield put(newsActions.fetchMarketingEventsResponse([]));
    }
}

function* watchFetchNewsRequest(action: ActionType<typeof newsActions.fetchNewsRequest>) {
    const isNewsCached: boolean = yield select(
        (s: AppState) => !!s.news.news[action.payload.tab].get(action.payload.page)?.length,
    );

    if (isNewsCached) {
        return;
    }

    const category = NewsTabToCategory[action.payload.tab];

    try {
        const news: News[] = yield call(newsService.getNews, {
            count: constants.newsOnPage,
            offset: action.payload.page * constants.newsOnPage - constants.newsOnPage,
            categories: category ? [category] : undefined,
        });

        yield put(newsActions.fetchNewsResponse(news, action.payload.page, action.payload.tab));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchDeleteNews(action: ActionType<typeof newsActions.deleteNews>) {
    try {
        yield call(newsService.deleteNews, action.payload.referenceName);

        yield put(newsActions.resetNewsList());

        yield put(newsActions.fetchNewsRequest(action.payload.page, action.payload.tab));
        yield put(newsActions.fetchTotalNewsCountRequest());
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchDeleteSingleNews(action: ActionType<typeof newsActions.deleteSingleNews>) {
    try {
        yield call(newsService.deleteNews, action.payload.referenceName);
        yield call(history.push, routes.newsUrl());
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchFetchNews(action: ActionType<typeof newsActions.fetchNewsDetails>) {
    try {
        const { newsReferenceName, queryParams } = action.payload;

        const news: NewsNavigation = yield call(newsService.getNewsDetails, newsReferenceName, queryParams);

        yield put(newsActions.fetchNewsDetailsResponse(news));
    } catch (e) {
        yield call(history.replace, routes.newsUrl());
    }
}

function* watchLogUserActivity(action: ActionType<typeof newsActions.logUserActivity>) {
    try {
        yield call(newsService.logUserActivity, action.payload.accessType);

        if (action.payload.link) {
            window.open(action.payload.link, '_self');
        }
    } catch (e) {
        yield put(errorActions.error(e));
    }
}

function* watchDownloadDocument(action: ActionType<typeof newsActions.downloadDocument>) {
    const { newsReferenceName, documentReferenceName, fileName } = action.payload;

    try {
        let file: { blob: Blob } = yield call(newsService.downloadDocument, newsReferenceName, documentReferenceName)

        saveAs(file.blob, fileName);
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchDownloadDocuments(action: ActionType<typeof newsActions.downloadDocuments>) {
    const { newsItem } = action.payload;

    try {
        let file: { blob: Blob } = yield call(newsService.downloadDocuments, newsItem.referenceName);

        saveAs(file.blob, `K-Watch News ${moment(newsItem.newsDateTime).format(constants.dateFormat)} attachment.zip`);
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

export function* watchNews() {
    yield takeEvery(getType(newsActions.fetchMarketingEventsRequest), watchFetchMarketingEventRequest);
    yield takeLatest(getType(newsActions.fetchNewsRequest), watchFetchNewsRequest);
    yield takeLatest(getType(newsActions.fetchTotalNewsCountRequest), watchFetchTotalNewsCount);
    yield takeLatest(getType(newsActions.deleteNews), watchDeleteNews);
    yield takeEvery(getType(newsActions.fetchNewsDetails), watchFetchNews);
    yield takeEvery(getType(newsActions.logUserActivity), watchLogUserActivity);
    yield takeLatest(getType(newsActions.deleteSingleNews), watchDeleteSingleNews);
    yield takeLatest(getType(newsActions.downloadDocument), watchDownloadDocument);
    yield takeLatest(getType(newsActions.downloadDocuments), watchDownloadDocuments);
}
