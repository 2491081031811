import { createSelector } from 'reselect';
import { user } from '../user';
import { BwicHistoryAction } from '../types/bwic-history/BwicHistoryAction';
import { BwicHistoryState } from '../types/state/BwicHistoryState';
import { compareDates } from '../utils/compare.utils';

export interface BwicHistoryActionSelector extends BwicHistoryAction {
    isMyMessage: boolean;
    isResponseMessage: boolean;
}

const getHistoryState = (state: BwicHistoryState) => state;

export const getHistory = createSelector(
    [getHistoryState],
    history => {
        if (!history.visible || !history.actions) {
            return history;
        }

        const actions = history.positionId
            ? history.actions.filter(a => a.positionId == null || a.positionId === history.positionId)
            : [...history.actions];

        actions.sort((a, b) => compareDates(a.sentDateUtc, b.sentDateUtc));

        return {
            ...history,
            actions: addActionProps(actions)
        };
    }
);

function addActionProps(actions: BwicHistoryAction[]): BwicHistoryActionSelector[] {
    const currentUser = user.current();
    if(!currentUser) return [];

    return actions
        // submittdBy field is empty for direct bid buyer actions 
        .map(a => {
            return {
                ...a,
                isMyMessage: !!a.positionId && a.submittedBy && a.submittedBy.id === currentUser.companyId,
                isResponseMessage: !!a.positionId && (!a.submittedBy || a.submittedBy.id !== currentUser.companyId)
            }
        });
}
