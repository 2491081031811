import { fetchSafe } from "./fetch.safe";
import { PaymentCard } from '../types/billing/PaymentCard';
import { responseType } from '../constants/response.type';
import { SavePaymentCard } from "../types/billing/SavePaymentCard";
import { BillingContact } from "../types/billing/BillingContact";
import { SaveBillingContact } from "../types/billing/SaveBillingContact";
import { Subscription } from "../types/billing/Subscription";
import { BillingHistory } from '../types/billing/BillingHistory';
import moment from "moment";

export const billingService = {
    getPaymentCards,
    setPrimaryCard,
    deletePaymentCard,
    addPaymentCard,
    getBillingContact,
    saveBillingContact,
    saveSubscription, // admins only
    requestImProSubscription,
    getSubscriptions,
    changeSubscription,
    searchBillingHistory,
    notifySales
};

function getPaymentCards(): Promise<PaymentCard[]> {
    return fetchSafe('/api/billing/cards');
}

function setPrimaryCard(id: number): Promise<void> {
    const requestOptions = {
        method: 'put',
        responseType: responseType.none
    };

    return fetchSafe(`/api/billing/set-primary-card/${id}`, requestOptions);
}

function deletePaymentCard(id: number): Promise<void> {
    const requestOptions = {
        method: 'delete',
        responseType: responseType.none
    };

    return fetchSafe(`/api/billing/card/${id}`, requestOptions);
}

function addPaymentCard(model: SavePaymentCard & { cardId: string }): Promise<void> {
    const requestOptions = {
        method: 'post',
        body: JSON.stringify(model),
        responseType: responseType.none
    };

    return fetchSafe(`/api/billing/card`, requestOptions);
}

function getBillingContact(): Promise<BillingContact> {
    return fetchSafe(`/api/billing`);
}

function saveBillingContact(model: SaveBillingContact): Promise<void> {
    const requestOptions = {
        method: 'post',
        body: JSON.stringify(model),
        responseType: responseType.none
    };

    return fetchSafe(`/api/billing`, requestOptions);
}

function saveSubscription(companyId: number, title: string, isTrial?: boolean, expirationDate?: Date) {
    const expiration = expirationDate 
        ? moment(expirationDate).toISOString(true)
        : undefined;

    const requestOptions = {
        method: 'put',
        body: JSON.stringify({ title, expiration, isTrial }),
        responseType: responseType.none,
    };

    return fetchSafe(`/api/manage-subscriptions/${companyId}`, requestOptions);
}

function requestImProSubscription() {
    const requestOptions = {
        method: 'put',
        responseType: responseType.none
    };

    return fetchSafe(`/api/Profile/request-pro-subscription`, requestOptions);
}

function changeSubscription(subscriptionTitle: string) {
    const requestOptions = {
        method: 'post',
        body: JSON.stringify(subscriptionTitle),
        responseType: responseType.none
    };

    return fetchSafe(`/api/subscriptions/request`, requestOptions);
}

function getSubscriptions(): Promise<Subscription[]> {
    return fetchSafe(`/api/subscriptions`, {},  true);
}

function notifySales(): Promise<void> {
    const requestOptions = {
        method: 'post',
        responseType: responseType.none
    };

    return fetchSafe(`/api/subscriptions/notify-sales`, requestOptions);
}

function searchBillingHistory(dateFrom: Date, dateTo: Date, statuses: number[]): Promise<BillingHistory[]> {
    const requestOptions = {
        method: 'post',
        body: JSON.stringify({ dateFrom, dateTo, statuses }),
        responseType: responseType.json
    };
    return fetchSafe('/api/billing/searchPayments', requestOptions);
}