import { fetchSafe } from './fetch.safe';
import { responseType } from '../constants';

export const biddingService = {
    getAccessState,
    requestAccess,
    submitBids,
    updateBidAttributes,
    sendQuickFeedback,
    sendOnBehalfQuickFeedback,
    saveTargetLevel,
    trade,
    affirm,
    reject,
    checkAwaitingTrades,
    sendBidRequest,
    getLiveBiddingData
}

function getAccessState(bwicReferenceName) {
    return fetchSafe(`/api/bidding/${bwicReferenceName}/access`);
}

function requestAccess(bwicReferenceName, comment) {
    const url = `/api/bidding/${bwicReferenceName}/access`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(comment),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function submitBids(bwicReferenceName, bids) {
    const url = `/api/bidding/${bwicReferenceName}`;
    const requestOptions = {
        method: 'post',
        body: JSON.stringify(bids)
    };

    return fetchSafe(url, requestOptions);
}

function updateBidAttributes(bidAttributes) {
    const requestOptions = {
        method: 'patch',
        body: JSON.stringify(bidAttributes)
    };

    return fetchSafe('/api/bidding', requestOptions);
}

function sendQuickFeedback(bwicReferenceName, positionId, bids, feedbackType) {
    const url = `/api/bidding/feedback/${bwicReferenceName}`;
    const requestOptions = {
        method: 'put',
        body: JSON.stringify({ positionId, bids, feedbackType }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions, true);
}

function sendOnBehalfQuickFeedback(bwicReferenceName, positionId, bids, feedbackType) {
    const url = `/api/bidding/on-behalf/feedback/${bwicReferenceName}`;
    const requestOptions = {
        method: 'put',
        body: JSON.stringify({ positionId, bids, feedbackType }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions, true);
}

function sendBidRequest(bwicReferenceName, positionId, companies, requestType) {
    const url = `/api/bidding/bid-request/${bwicReferenceName}`;
    const requestOptions = {
        method: 'put',
        body: JSON.stringify({ positionId, companies, requestType }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}


function trade(bwicReferenceName, tradingPositions) {
    const url = `/api/trading/${bwicReferenceName}`;
    const requestOptions = {
        method: 'post',
        body: JSON.stringify(tradingPositions),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function saveTargetLevel(bwicReferenceName, positionId, targetLevel) {
    const url = `/api/bidding/savetargetlevel/${bwicReferenceName}`;
    const requestOptions = {
        method: 'put',
        body: JSON.stringify({ positionId, targetLevel }),
        responseType: responseType.none
    }

    return fetchSafe(url, requestOptions);
}

function affirm(affirmModels) {
    const url = `/api/trading/affirm`;
    const requestOptions = {
        method: 'post',
        body: JSON.stringify(affirmModels),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function reject(rejectModel) {
    const url = '/api/trading/reject';
    const requestOptions = {
        method: 'post',
        body: JSON.stringify(rejectModel),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function checkAwaitingTrades() {
    return fetchSafe('/api/trading/trades', {}, true);
}

function getLiveBiddingData() {
    return fetchSafe('api/bwics/live-bidding-positions', {}, true);
}
