import { Conference } from '../types/marketing/Conference';
import { Releases } from '../types/marketing/Release';
import { Webinar } from '../types/marketing/Webinar';
import { fetchSafe } from './fetch.safe';

async function fetchReleases(): Promise<Releases> {
    return fetchSafe(`${process.env.REACT_APP_MARKETING_ENDPOINT}/api/releases`);
}

function fetchWebinar(): Promise<Webinar> {
    return fetchSafe(`${process.env.REACT_APP_MARKETING_ENDPOINT}/api/webinar`);
}

function fetchConferences(): Promise<Conference[]> {
    return fetchSafe(`${process.env.REACT_APP_MARKETING_ENDPOINT}/api/conferences`);
}

export const releaseService = {
    fetchReleases,
    fetchWebinar,
    fetchConferences,
};
