import { fetchSafe } from './fetch.safe';
import { UserConfigType } from '../types/user-config/UserConfigType';
import { ImUserConfig } from '../types/user-config/UserConfig';
import { responseType } from '../constants';
import { UserFilterAlertStatus } from '../types/user-config/UserConfigFilter';

export const imUserConfigService = {
    getUserConfig,
    getDefaultUserConfig,
    createOrUpdateUserConfig,
    deleteUserConfig,
    updateFilterEmailAlertsConfig,
    deleteUserFilter
};

function getUserConfig(configType?: UserConfigType) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/userConfig/${configType || ''}`
    );
}

function getDefaultUserConfig(configType: UserConfigType) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/userConfig/${configType}/config/default`
    );
}

function createOrUpdateUserConfig(userConfig: ImUserConfig, withResponse = false): Promise<string> {
    const requestOptions = {
        body: JSON.stringify(userConfig),
        method: 'PUT',
        responseType: withResponse
            ? responseType.json
            : responseType.none,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/userConfig/${userConfig.type}`,
        requestOptions
    );
}

function updateFilterEmailAlertsConfig(filterAlertStatuses: UserFilterAlertStatus[]): Promise<string> {
    const requestOptions = {
        body: JSON.stringify(filterAlertStatuses),
        method: 'PUT',
        responseType: responseType.none,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/userConfig/im-filters/alerts`,
        requestOptions
    );
}

function deleteUserConfig(configType: UserConfigType, referenceName: string = '') {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/userConfig/${configType}/${referenceName}`,
        {
            method: 'DELETE',
            responseType: responseType.none,
        }
    );
}

function deleteUserFilter(referenceName: string, filterType: UserConfigType.apFilter | UserConfigType.imFilter) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/userConfig/${filterType}/${referenceName}`,
        {
            method: 'DELETE',
            responseType: responseType.none,
        }
    );
}
