import qs from 'query-string';
import { fetchSafe } from './fetch.safe';
import { HelpResourceCategory, HelpResourceSection } from '../types/help-resources/HelpResourceCategory';
import { ArticleAttachmentList, HelpResourceArticle, HelpResourceSectionArticlesList } from '../types/help-resources/HelpResourceArticle';
import { HelpResourceSearch } from '../types/help-resources/HelpResourceSearch';
import { responseType } from '../constants';

const isDraft = (entityName: string) => {
    return /^\s?\[Draft\]/.test(entityName)
}

async function fetchCategories(): Promise<HelpResourceCategory[]> {
    const categories: HelpResourceCategory[] = await fetchSafe('/api/Zendesk/categories');
    return categories.filter(category => category.name && !isDraft(category.name))
}

async function fetchCategorySections(categoryId: number): Promise<HelpResourceSection[]> {
    return await fetchSafe(`/api/Zendesk/categories/${categoryId}/sections`);
}

// Getting all pages at once due restricted limits of maximum 100 articles
async function fetchCategoryArticles(categoryId: number, page: number = 1, result: HelpResourceArticle[] = []): Promise<HelpResourceArticle[]> {
    const pageSize = 100; // maximum ammount
    const nextPage = page + 1;
    const queryString = `?${qs.stringify({ page, pageSize })}`;
    const iterationResult = await fetchSafe(`/api/Zendesk/categories/${categoryId}/articles${queryString}`);
    const prevCount = result.length;
    result = [...result, ...iterationResult];
    if (result.length === prevCount || iterationResult.length < pageSize) {
        return result;
    }
    return fetchCategoryArticles(categoryId, nextPage, result);
}

async function fetchSection(sectionId: number): Promise<HelpResourceSectionArticlesList> {
    const section = await fetchSafe(`/api/Zendesk/sections/${sectionId}/articles`);
    if (isDraft(section.sectionName) || isDraft(section.categoryName)) {
        return {
            sectionName: '',
            categoryName: '',
            articles: []
        };
    }
    return section;
}

function fetchBySearch(search: string, categoryId: number | null, page: number = 1): Promise<HelpResourceSearch> {
    const queryString = `?${qs.stringify({ page })}`;
    return fetchSafe(`/api/Zendesk/search/${search}${categoryId ? `/${categoryId}` : ''}${queryString}`);
}

function getArticleImage(src: string): Promise<{ name: string, blob: File }> {
    return fetchSafe(src, { responseType: responseType.file });
}


function getArticleAttachment(id: number, name: string) {
    return fetchSafe(`/api/Zendesk/image/${id}/${name}`, { responseType: responseType.file })
}

function getArticleAttachmentsList(articleId: number): Promise<ArticleAttachmentList> {
    return fetchSafe(`/api/Zendesk/attechment/${articleId}`)
}

export const helpResourcesService = {
    fetchSection,
    fetchCategories,
    fetchCategorySections,
    fetchCategoryArticles,
    fetchBySearch,
    getArticleImage,
    getArticleAttachment,
    getArticleAttachmentsList
}
