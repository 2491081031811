import { responseType as responseTypes } from '../constants/response.type';
import { logger } from '../logging/logger';
import { user } from "../user/user";
import { refreshTokenController } from "../user/refresh-token-controller";
import { fileUtils } from '../utils';
import { requestCancelationController } from './request-cancelation-controller';

const maxRetryAttempts = 5;

const getMethod = options => (options.method || 'GET').toUpperCase();

const awaitTimeout = delay => new Promise(resolve => setTimeout(resolve, delay));

export const fetchSafe = (url, options = {}, disableRequestCancelation = false, abortSignal = undefined, retryAttempt = 1) => {
    const requestCancelationSignal = disableRequestCancelation
        ? undefined
        : abortSignal ?? requestCancelationController.signal();

    const handleResponse = async (response, responseType) => {
        if (response.ok) {
            switch (responseType) {
                case (responseTypes.none): return {};
                case (responseTypes.file): return {
                    name: fileUtils.getFileNameFromResponse(response),
                    blob: await response.blob()
                };
                default: return response.status !== 204 ? response.json() : undefined;
            }
        }

        const shouldRetry = () => {
            const method = getMethod(options);

            return (
                retryAttempt <= maxRetryAttempts &&
                (method === 'GET' || method === 'POST') &&
                response.status === 504
            );
        }

        if (shouldRetry(response)) {
            logger.trace("504 error retry attempt", { url, retryAttempt: retryAttempt + 1 });
            return awaitTimeout(300).then(() => fetchSafe(url, options, disableRequestCancelation, abortSignal,++retryAttempt));
        }

        return failure(response);
    }

    return refreshTokenController
        .ensureTokenUpToDate(user.token(), user.refreshToken().refreshToken)
        .then(accessToken => fetch(url, setOptionsDefaults(options, accessToken, requestCancelationSignal)))
        .then(response => handleResponse(response, options.responseType));
}

const failure = response =>
    response
        .text()
        .then(text => Promise.reject({
            status: response.status,
            message: (text || response.statusText)
        }))

const setOptionsDefaults = (options = {}, accessToken, signal) => {
    const method = getMethod(options);

    return {
        ...options,
        method,
        signal,
        headers: options.headers || {
            'authorization': accessToken && `Bearer ${accessToken}`,
            'accept': 'application/json',
            'content-type': 'application/json',
            ...(method === 'GET') && {
                'cache-control': 'no-cache',
                'pragma': 'no-cache'
            }
        }
    };
}
