import moment from 'moment';
import { fetchSafe } from './fetch.safe';
import { getFromCache } from './cache';

export const daysService = {
    getNextBusinessDate,
    getNextBusinessDates,
    isBusinessDay,
    getHolidays
};

function getNextBusinessDate() {
    return fetchSafe(`/api/days/next-business-day/${moment().format('YYYY-MM-DD')}`);
}

function getNextBusinessDates(from, daysCount) {
    return getFromCache(`/api/days/next-business-days/${moment(from).format('YYYY-MM-DD')}/${daysCount}`);
}

function isBusinessDay(date) {
    return fetchSafe(`/api/days/business-day/${moment(date).format('MM-DD-YYYY')}`);

}

function getHolidays(year) {
    return fetchSafe(`/api/days/holidays/${year}`);
}
