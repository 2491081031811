import { createSelector } from 'reselect';
import { BwicPosition } from '../types/bwic/BwicPosition';

type TState = {
    securities: BwicPosition[],
    tradingPositions: { [positionId: number]: {} }
};

const getPositions = (state: TState) => state.securities;
const getTradingPositionsState = (state: TState) => state.tradingPositions;

export const tradingPositionsSelector = createSelector(
    [getPositions, getTradingPositionsState],
    (positions, tradingPositionsState) => positions.filter(p => tradingPositionsState[p.id])
);

