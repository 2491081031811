import { fetchSafe } from './fetch.safe';
import { responseType } from '../constants';

export const pxTalkService = {
    normalize,
    save,
    bulkSave,
    deletePxTalk,
    fetchPxTalks
};

function normalize(talk, levelType) {
    return fetchSafe(`/api/bwics/normalize/${talk}/${levelType}`);
}

function save(bwicReferenceName, positionId, savePriceTalks, priceTalkLock, deletedPriceTalks) {
    const url = `/api/price-talk/save-position`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            bwicReferenceName,
            positionId,
            savePriceTalks,
            priceTalkLock,
            deletedPriceTalks
        }),
        responseType: responseType.json
    };

    return fetchSafe(url, requestOptions);
}

function bulkSave(bwicReferenceName, priceTalks) {
    const url = `/api/price-talk/save-broker-dealer`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            bwicReferenceName,
            priceTalks
        }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function deletePxTalk(bwicReferenceName, positionId, companyId) {
    const url = `/api/price-talk/${bwicReferenceName}/${positionId}/${companyId}`;
    const requestOptions = {
        method: 'DELETE',
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function fetchPxTalks(bwicReferenceName, positionId) {
    const url = `/api/price-talk/${bwicReferenceName}/${positionId}`;
    return fetchSafe(url, undefined, true);
}
