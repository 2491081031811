import { createSelector } from 'reselect';
import moment from 'moment';
import { companyColumns } from '../constants/company.columns';
import { companyStatuses } from '../constants/company.statuses';
import { companyRoles } from '../constants/company-roles';
import { bwicDateFilterOptions } from '../constants/bwicDateFilterOptions';
import { userStatuses } from '../constants/user.statuses';
import { CompanyStatus } from '../types/company/CompanyStatus';
import { getSearchDateRange } from '../utils/search-date-range';
import { isTrialExpired } from '../utils/subscription.utils';
import { SubscriptionType } from '../types/billing/SubscriptionType';
import { compareDatesNullable } from '../utils/compare.utils';

const getCompanyList = state => state.companies;
const getUserList = state => state.users;
const getOrderByOptions = state => state.companyOrderBy;
const getFilterOptions = state => state.lastAppliedCompanyFilter;
const getLiveSearch = state => state.companyFilter.searchTerm;

export const getCompanies = createSelector(
    [getCompanyList, getUserList, getFilterOptions, getOrderByOptions, getLiveSearch],
    (companies, users, filter, orderBy, searchTerm) => {
        companies.forEach(c => {
            c.primaryContact = users.find(u => u.id === c.primaryContactId);
            c.members = users.filter(u => u.companyId === c.id).length;
            c.rolesText = companyRoles.getTitle(c.role);
            c.subscriptionText = getSubscriptionTitle(c, users);
            c.subscriptionDate = getSubscriptionDate(c, users);
        });

        const filteredResult = applyFilter(companies, { ...filter, searchTerm });
        return sort(filteredResult, orderBy);
    }
);

function getSubscriptionTitle(company, users) {
    const hasActiveUsers = users.some(u =>
        u.companyId === company.id &&
        u.status === userStatuses.active.statusCode
    );

    if (!hasActiveUsers) return null;
    if (company.trialPeriodEnd && !isTrialExpired(company.trialPeriodEnd)) return SubscriptionType.trial;
    return company.subscription;
}

function getSubscriptionDate(company, users) {
    const hasActiveUsers = users.some(u =>
        u.companyId === company.id &&
        u.status === userStatuses.active.statusCode
    );

    if(!hasActiveUsers) return null;
    if(!!company.trialPeriodEnd && !isTrialExpired(company.trialPeriodEnd)) return company.trialPeriodEnd;
    if(!!company.subscriptionExpiration) return company.subscriptionExpiration;
    return null;
}


function applyFilter(companies, filter) {
    let filterCompanies = companies;

    const statuses = [
        filter.active && CompanyStatus.active,
        filter.blocked && CompanyStatus.blocked,
        filter.offPlatform && CompanyStatus.offPlatform
    ].filter(status => status);

    const roles = [
        filter.administrator && companyRoles.Administrator,
        filter.brokerDealer && companyRoles.BrokerDealer,
        filter.seller && companyRoles.Seller,
        filter.viewer && companyRoles.Viewer,
        filter.media && companyRoles.Media
    ].filter(status => status);

    if (statuses.length) {
        filterCompanies = filterCompanies.filter(company => statuses.some(status => status === company.status));
    }

    if (roles.length) {
        filterCompanies = filterCompanies.filter(company => roles.some(role => role === company.role));
    }

    if (filter.regBy?.length) {
        filterCompanies = filterCompanies.filter(company => filter.regBy.some(userId => company.createdById === userId));
    }

    if (filter.salesRepresentatives?.length) {
        filterCompanies = filterCompanies.filter(company => filter.salesRepresentatives.some(userId =>
            company.salesRepresentatives.some(salesRepresentative => salesRepresentative.id === userId)
        ));
    }

    if (filter.searchTerm) {
        const searchTerm = filter.searchTerm.toLowerCase();

        filterCompanies = filterCompanies.filter(c =>
            c.name.toLowerCase().includes(searchTerm) ||
            c.country?.name.toLowerCase().includes(searchTerm) ||
            c.rolesText.toLowerCase().includes(searchTerm) ||
            c.createdBy?.toLowerCase().includes(searchTerm) ||
            c.subscriptionText?.toLowerCase().includes(searchTerm) ||
            (c.subscriptionDate && moment(c.subscriptionDate).format('MM/DD/YY')
                .toLowerCase()
                .includes(searchTerm)) ||
            companyStatuses.getByStatusCode(c.status).title.toLowerCase().includes(searchTerm) ||
            (
                c.primaryContact &&
                concatFullName(c.primaryContact.firstName, c.primaryContact.lastName)
                    .toLowerCase().includes(searchTerm)
            ) ||
            moment(c.dateRegistered)
                .format('MM/DD/YY')
                .toLowerCase()
                .includes(searchTerm) ||
            c.salesRepresentatives
                .some(sr => concatFullName(sr.firstName, sr.lastName).toLowerCase().includes(searchTerm))
        );
    }

    if (filter.customDateRange
        && filter.customDateRange.from
        && filter.customDateRange.to
        && filter.selectedDateOption.key === bwicDateFilterOptions.custom.key) {
        filterCompanies = filterCompaniesByRange(filter.customDateRange.from, filter.customDateRange.to, filterCompanies);
    }

    if (filter.selectedDateOption
        && filter.selectedDateOption.key !== bwicDateFilterOptions.unspecified.key
        && filter.selectedDateOption.key !== bwicDateFilterOptions.custom.key) {

        const dateFilter = getSearchDateRange(filter);
        filterCompanies = filterCompaniesByRange(dateFilter.dateFrom, dateFilter.dateTo, filterCompanies);

    }

    return filterCompanies;

}

function filterCompaniesByRange(from, to, companies) {
    return companies.filter(company => {
        return moment(company.dateRegistered)
            .isAfter(from)
            &&
            moment(company.dateRegistered)
                .isBefore(to)
    });
}

function sort(companies, orderBy) {
    if (orderBy.column === companyColumns.status) {
        return orderBy.direction === 'asc'
            ? companies.sort((a, b) => a.status - b.status)
            : companies.sort((a, b) => b.status - a.status);
    } else if (orderBy.column === companyColumns.legalName) {
        return orderBy.direction === 'asc'
            ? companies.sort((a, b) => a.name.localeCompare(b.name))
            : companies.sort((a, b) => b.name.localeCompare(a.name));
    } else if (orderBy.column === companyColumns.members) {
        return orderBy.direction === 'asc'
            ? companies.sort((a, b) => a.members - b.members)
            : companies.sort((a, b) => b.members - a.members);
    } else if (orderBy.column === companyColumns.role) {
        return orderBy.direction === 'asc'
            ? companies.sort((a, b) => a.role.localeCompare(b.role))
            : companies.sort((a, b) => b.role.localeCompare(a.role));
    } else if (orderBy.column === companyColumns.country) {
        return orderBy.direction === 'asc'
            ? companies.sort((a, b) => a.country.name.localeCompare(b.country.name))
            : companies.sort((a, b) => b.country.name.localeCompare(a.country.name));
    } else if (orderBy.column === companyColumns.primaryContact) {
        return orderBy.direction === 'asc'
            ? companies.sort((a, b) => getPrimaryContactName(a).localeCompare(getPrimaryContactName(b)))
            : companies.sort((a, b) => getPrimaryContactName(b).localeCompare(getPrimaryContactName(a)));
    } else if (orderBy.column === companyColumns.dateRegistered) {
        return orderBy.direction === 'asc'
            ? companies.sort((a, b) => new Date(a.dateRegistered) - new Date(b.dateRegistered))
            : companies.sort((a, b) => new Date(b.dateRegistered) - new Date(a.dateRegistered));
    } else if (orderBy.column === companyColumns.createdBy) {
        return orderBy.direction === 'asc'
            ? companies.sort((a, b) => (a.createdBy || '').localeCompare(b.createdBy || ''))
            : companies.sort((a, b) => (b.createdBy || '').localeCompare(a.createdBy || ''));
    } else if (orderBy.column === companyColumns.isClearingBank) {
        return orderBy.direction === 'asc'
            ? companies.sort((a, b) => Number(a.role === companyRoles.BrokerDealer) - Number(b.role === companyRoles.BrokerDealer))
                .sort((a, b) => Number(a.isClearingBank) - Number(b.isClearingBank))
            : companies.sort((a, b) => Number(b.role === companyRoles.BrokerDealer) - Number(a.role === companyRoles.BrokerDealer))
                .sort((a, b) => Number(b.isClearingBank) - Number(a.isClearingBank))
    } else if (orderBy.column === companyColumns.subscription) {
        return orderBy.direction === 'asc'
            ? companies.sort((a, b) => (a.subscriptionText || '').localeCompare(b.subscriptionText || ''))
            : companies.sort((a, b) => (b.subscriptionText || '').localeCompare(a.subscriptionText || ''));
    } else if (orderBy.column === companyColumns.subscriptionEnd) {
        return orderBy.direction === 'asc'
            ? companies.sort((a, b) => compareDatesNullable(a.subscriptionDate, b.subscriptionDate))
            : companies.sort((a, b) => compareDatesNullable(b.subscriptionDate, a.subscriptionDate));
    }
}

function getPrimaryContactName(company) {
    const { primaryContact } = company;
    if (primaryContact) {
        if (primaryContact.name) {
            return primaryContact.name;
        }
        if (primaryContact.firstName || primaryContact.lastName) {
            return concatFullName(primaryContact.firstName, primaryContact.lastName);
        }
    }
    return '';
}

function concatFullName(firstName, lastName) {
    return [firstName, lastName].join(' ').trim();
}