import { fetchSafe } from "./fetch.safe";
import { responseType } from '../constants';
import { UpdateSettlementAgentAgreementStatus } from '../types/bid-as-dealer/UpdateSettlementAgentAgreementStatus';
import { SettlementAgentClientAgreement } from '../types/bid-as-dealer/SettlementAgentClientAgreement';
import { SettlementAgentAgreement } from "../types/bid-as-dealer/SettlementAgentAgreement";
import { CreateSettlementAgreementRequest } from '../types/bid-as-dealer/CreateBidAsDealerAgreementRequest';

function getAgreementForSettlementAgent():Promise<SettlementAgentClientAgreement[]> {
    return fetchSafe('/api/agreements');
}

function getAgreements(): Promise<SettlementAgentAgreement[]> {
    return fetchSafe('/api/agreements');
}

function sendRequest(params?: CreateSettlementAgreementRequest):Promise<void> {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        responseType: responseType.none
    };
    return fetchSafe('/api/agreements', requestOptions);
}

function updateAgreement(data: UpdateSettlementAgentAgreementStatus):Promise<void> {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data),
        responseType: responseType.none
    };
    return fetchSafe('/api/agreements', requestOptions);
}

function fetchPendingAgreementCount():Promise<number> {
    return fetchSafe('/api/agreements/pending-count', undefined, true)
}

export const settlementAgentAgreementService = {
    getAgreementForSettlementAgent,
    getAgreements,
    sendRequest,
    updateAgreement,
    fetchPendingAgreementCount
};
