import isNil from 'lodash/isNil';

export function urlBuilder(baseUrl, queryArgs) {
    const query = Object
        .keys(queryArgs)
        .filter(key => !isNil(queryArgs[key]) || (!!queryArgs[key] && (!Array.isArray(queryArgs[key]) || queryArgs[key].length)))
        .map(key => Array.isArray(queryArgs[key]) ? addArray(key, queryArgs[key]) : addSingle(key, queryArgs[key]))
        .flat()
        .join('&');

    if (query) {
        return `${baseUrl}?${query}`;
    }

    return baseUrl;
}

function addArray(key, values) {
    return values.map(value => addSingle(key, value));
}

function addSingle(key, value) {
    return `${key}=${encodeURIComponent(value)}`
}
