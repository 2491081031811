import { call, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { salesRepresentativeActions } from "../actions";
import { accountActions, roles } from "../constants";
import { logger } from "../logging/logger";
import { salesRepresentativesService } from "../services";
import { SalesRepresentative } from "../types/sales-representative/SalesRepresentative";
import { user } from "../user";

function* watchLoadSalesRepresentatives() {
    if (!user.isAuthenticated() || !user.hasRoles(...roles.salesRepresentativesSupported())) {
        return;
    }

    try {
        const salesRepresentatives: SalesRepresentative[] =
            yield call(salesRepresentativesService.getCurrentUserSalesRepresentatives);

        salesRepresentatives.sort((a, b) =>
            a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));

        if (salesRepresentatives.length) {
            yield put(salesRepresentativeActions.storeSalesRepresentatives(salesRepresentatives));
        }
    } catch (e) {
        logger.exception(e, 'Failed loading sales representatives for userId ' + user.current()?.id);
    }
}

function* watchLogout() {
    yield put(salesRepresentativeActions.reset())
}

export function* watchSalesRepresentative() {
    yield takeEvery(getType(salesRepresentativeActions.loadUserSalesRepresentatives), watchLoadSalesRepresentatives);
    yield takeEvery(accountActions.LOGOUT, watchLogout)
}
