import moment from 'moment';
import { createSelector } from 'reselect';
import { dateRangeFilterOptions } from '../constants/date-range.filter';
import { CommonFilter } from '../types/filters/FilterState';
import { InventoryPosition } from '../types/inventory/InventoryPosition';
import { AppState } from '../types/state/AppState';
import { InventoryPositionStatus } from '../types/inventory/InventoryPositionStatus';
import { InventoryListFilterState } from '../types/state/BdInventoryState';
import { formatUtils, isRequestFailed, isRequestSuccess } from '../utils';
import {
    compareNumbers, compareStrings, compareBoolean, compareByDateRange, compareByDateYearRange, getSelectedFilters, compareBooleanStrict
} from '../utils/filtering/filter.compare.utils';

export const dealerInventorySelector = createSelector(
    [
        (state: AppState) => state.entities.inventory.items,
        (state: AppState) => state.filters.bdInventory.lastAppliedFilter,
        (state: AppState) => state.searchSecurities.searchTermItems,
        (state: AppState) => state.entities.collateralManagers.requestState,

    ],
    (items, filter, searchIsinTickerItems, managersRequestState) => {
        const filters = filter as unknown as (InventoryListFilterState | undefined);
        const isDynamicFiltersLoaded = isRequestSuccess(managersRequestState) || isRequestFailed(managersRequestState);
        const selectedFilters = getSelectedFilters(filters as unknown as CommonFilter);

        const inventories = items.map(i => ({
            ...i,
            ncEnd: i.ncEndMonth && i.ncEndYear != null ? formatUtils.formatMonthAndYear(`${i.ncEndMonth}/${i.ncEndYear}`) : '',
            riEnd: i.riEndMonth && i.riEndYear != null ? formatUtils.formatMonthAndYear(`${i.riEndMonth}/${i.riEndYear}`) : '',
            statusName: InventoryPositionStatus[i.status]
        }))

        if (!isDynamicFiltersLoaded) return inventories
        return inventories.filter(item => applyFilterCriteria(item, selectedFilters, searchIsinTickerItems, filters))
    }
);

function applyFilterCriteria(i: InventoryPosition, selectedFilters: { [key: string]: boolean }, searchIsinTicker: string[], filter?: InventoryListFilterState) {
    const selectedDateOption = filter?.date.filter.selectedOption;
    const isCurrent = selectedDateOption?.key === dateRangeFilterOptions.CurrentInventory.key;
    const currentInventoryStatuses = [InventoryPositionStatus.added, InventoryPositionStatus.undefined, InventoryPositionStatus.updated];

    return (
        (searchIsinTicker.length ? searchIsinTicker.some(t => i.ticker.includes(t) || i.isinCusip.includes(t)) : true) && (
            !filter || (
                (isCurrent ? (currentInventoryStatuses.includes(i.status) || (i.status === InventoryPositionStatus.traded && moment().isSame(i.lastUpdateDate, 'day'))) : true) &&
                (!isCurrent && selectedDateOption ? (compareByDateRange(i.lastUpdateDate, filter.date)) : true) &&
                (selectedFilters[filter.maturity.key] ? compareByDateRange(i.statedMaturity, filter.maturity) : true) &&
                (selectedFilters[filter.vintage.key] ? compareByDateRange(i.vintage, filter.vintage) : true) &&
                (selectedFilters[filter.closing.key] ? compareByDateRange(i.closingDate, filter.closing) : true) &&
                (selectedFilters[filter.nonCallEnd.key] ? compareByDateYearRange(filter.nonCallEnd, i.nonCallPeriodEnd, i.closingDate) : true) &&
                (selectedFilters[filter.reinvestmentEnd.key] ? compareByDateYearRange(filter.reinvestmentEnd, i.reinvestmentPeriodEnd, i.closingDate) : true) &&
                (selectedFilters[filter.currency.key] ? compareStrings(i.currency, filter.currency) : true) &&
                (selectedFilters[filter.ratings.key] ? compareStrings(i.rating, filter.ratings) : true) &&
                (selectedFilters[filter.managers.key] ? compareStrings(i.collateralManager?.referenceName, filter.managers) : true) &&
                (selectedFilters[filter.collateralType.key] ? compareStrings(i.collateralType, filter.collateralType) : true) &&
                (selectedFilters[filter.trustees.key] ? compareStrings(i.trustee?.referenceName, filter.trustees) : true) &&
                (selectedFilters[filter.esg.key] ? compareBoolean(i.esg, filter.esg) : true) &&
                (selectedFilters[filter.euCompliance.key] ? compareBoolean(i.euCompliance, filter.euCompliance) : true) &&
                (selectedFilters[filter.staticDeal.key] ? compareBoolean(i.staticDeal, filter.staticDeal) : true) &&
                (selectedFilters[filter.amr.key] ? compareBoolean(i.amr, filter.amr) : true) &&
                (selectedFilters[filter.statuses.key] ? compareNumbers(i.status, filter.statuses) : true) &&
                (selectedFilters[filter.outOfNC.key] ? compareBooleanStrict(filter.outOfNC, i.outOfNC) : true) &&
                (selectedFilters[filter.outOfRI.key] ? compareBooleanStrict(filter.outOfRI, i.outOfRI) : true)
            )
        )
    );
}
