import { fetchSafe } from './fetch.safe';
import { responseType } from '../constants';
import { ContactSalesRequest } from "../types/contact-sales/ContactSalesRequest";

export const contactSalesService = {
    sendRequest
}

function sendRequest(request: ContactSalesRequest): Promise<void> {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request),
        responseType: responseType.none

    };

    return fetchSafe('/api/profile/contact-sales', requestOptions);
}
