import { user, refreshTokenController } from '../user';
import { requestCancelationController } from './request-cancelation-controller';
import { apiUtils } from '../utils';

export const fileUpload = (url, dataItems, blob, onProgress, disableCancelation = false) => {
    const upload = (headers, signal) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.open('POST', url);

            for (const key in headers) {
                xhr.setRequestHeader(key, headers[key]);
            }

            xhr.onload = e => apiUtils.isRequestSuccess(e.target.status)
                ? resolve(e.target.responseText ? JSON.parse(e.target.responseText) : undefined)
                : reject(e.target);
            xhr.onerror = reject;
            xhr.onabort = reject;
            xhr.upload.onprogress = onProgress;

            if (signal) {
                signal.onabort = () => xhr.abort();
            }

            const form = new FormData();
            form.append('file', blob, blob.name);

            if (dataItems) {
                Object.keys(dataItems).forEach(key => form.append(key, dataItems[key]));
            }

            xhr.send(form);
        });
    }
    const requestCancelationSignal = disableCancelation ? null : requestCancelationController.signal();

    return refreshTokenController
        .ensureTokenUpToDate(user.token(), user.refreshToken().refreshToken)
        .then(token => upload({ authorization: `Bearer ${token}` }, requestCancelationSignal))
        .catch(({ status, responseText}) => Promise.reject({ status, message: responseText }));
}


