import { createSelector } from 'reselect';

const getDeals = state => state.deals;
const getOrderByDirection = state => state.orderByAsc;

export const getOrderedDeals = createSelector(
    [getDeals, getOrderByDirection],
    (deals, orderByAsc) => orderByAsc
        ? deals.sort((a, b) => a.name.localeCompare(b.name))
        : deals.sort((a, b) => b.name.localeCompare(a.name))
);
