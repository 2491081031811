import { BlastMessage } from "../types/blast-message/BlastMessage";
import { fetchSafe } from './fetch.safe';

export const blastMessageService = {
    send
}

function send(bwicReferenceName: string, message: BlastMessage): Promise<string[]> {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(message)
    };

    return fetchSafe(`/api/bidding/blast-message/${bwicReferenceName}`, requestOptions);
}
