import { fetchSafe } from './fetch.safe';
import { UserFeedback } from '../types/models/UserFeedback';

interface FeedbackService {
    sendFeedback(feedbackData: UserFeedback): Promise<Response>;
}

export const feedbackService: FeedbackService = {
    sendFeedback,
};

function sendFeedback(feedbackData: UserFeedback): Promise<Response> {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(feedbackData),
    };

    return fetchSafe('/api/feedback', requestOptions);
}
