import { call, put, takeEvery } from 'redux-saga/effects';
import { sellerAdminLimitActions } from '../actions/seller-admin-limit.actions';
import { ActionType } from '../types/ActionType';
import { sellerAdminService } from '../services/seller-admin.service';
import { errorActions } from '../actions';
import { SellerAdminTradingLimitValuesState } from '../types/seller-admin/SellerAdminTradingLimitValuesState';
import { Currency } from '../types/enums/Currency';
import { TradingLimitType } from '../types/seller-admin/TradingLimitType';
import { accountActions } from '../constants';

function* watchGetTradeLimits() {
    try {
        const limits: { currency: Currency; size: number | null; type: TradingLimitType }[] =
            yield call(sellerAdminService.getTradingLimits);
        yield put(sellerAdminLimitActions.getTradeLimitsSuccess(limits));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchUpdateTradeLimits(action: ActionType<{ limits: SellerAdminTradingLimitValuesState }>) {
    const { limits } = action.payload;
    const tradingLimits: { currency: string, type: number, size: number | null }[] = [
        {
            currency: Currency.USD,
            type: TradingLimitType.SellMaxBondSize,
            size: limits[TradingLimitType.SellMaxBondSize][Currency.USD]
        },
        {
            currency: Currency.EUR,
            type: TradingLimitType.SellMaxBondSize,
            size: limits[TradingLimitType.SellMaxBondSize][Currency.EUR]
        },
        {
            currency: Currency.USD,
            type: TradingLimitType.SellMaxDailyTradesSize,
            size: limits[TradingLimitType.SellMaxDailyTradesSize][Currency.USD]
        },
        {
            currency: Currency.EUR,
            type: TradingLimitType.SellMaxDailyTradesSize,
            size: limits[TradingLimitType.SellMaxDailyTradesSize][Currency.EUR]
        },

        {
            currency: Currency.USD,
            type: TradingLimitType.BuyMaxBondSize,
            size: limits[TradingLimitType.BuyMaxBondSize][Currency.USD]
        },
        {
            currency: Currency.EUR,
            type: TradingLimitType.BuyMaxBondSize,
            size: limits[TradingLimitType.BuyMaxBondSize][Currency.EUR]
        },
        {
            currency: Currency.USD,
            type: TradingLimitType.BuyMaxDailyTradesSize,
            size: limits[TradingLimitType.BuyMaxDailyTradesSize][Currency.USD]
        },
        {
            currency: Currency.EUR,
            type: TradingLimitType.BuyMaxDailyTradesSize,
            size: limits[TradingLimitType.BuyMaxDailyTradesSize][Currency.EUR]
        },
    ];
    try {
        yield call(sellerAdminService.updateTradingLimits, { tradingLimits });
        yield put(sellerAdminLimitActions.updateTradeLimitsSuccess());
        yield put(sellerAdminLimitActions.getTradeLimitsSuccess(tradingLimits));
        yield put(sellerAdminLimitActions.setEditModeStatus(false));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(sellerAdminLimitActions.updateTradeLimitsFailure());
    }
}

function* watchLogout() {
    yield put(sellerAdminLimitActions.reset())
}

export function* watchSellerAdminLimit() {
    yield takeEvery(sellerAdminLimitActions.getTradeLimitsRequest, watchGetTradeLimits);
    yield takeEvery(sellerAdminLimitActions.updateTradeLimitsRequest, watchUpdateTradeLimits);
    yield takeEvery(accountActions.LOGOUT, watchLogout)
}
