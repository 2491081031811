import { createSelector } from 'reselect';
import { dateTimeUtils } from '../utils';

const getBWICs = state => state.manageParsedBwics.bwics;
const getSearchTermItems = state => state.searchSecurities.searchTermItems || [];
const getRefreshFlag = state=> state.refreshLastUpdatedDate;

export const getFilteredParsedBWICs = createSelector(
    [getBWICs, getSearchTermItems, getRefreshFlag],
    (bwics, searchTermItems, refreshFlag) => {
        if (!bwics || !bwics.length) {
            return bwics;
        }

        return bwics.map(b => (
            {
                ...b,
                bidsDue: dateTimeUtils.utcToLocalString(b.bidsDueUtc),
                lastUpdateAt: dateTimeUtils.duration(b.lastUpdateAtUtc)
            }));
    }
);
