import { createSelector } from 'reselect';
import { AppState } from '../types/state/AppState';
import { FilterGroup, FilterDateGroup, FilterDateTimeGroup, FilterRangeGroup, FilterType } from '../types/filters/FilterGroup';
import { PipelineType } from '../types/amr-pipeline/enums/PipelineType';
import { isEqual } from 'lodash';
import { getFilterCriteria } from '../utils/amr-pipeline.utils';
import { ArrangerPipelineFilters, DealsPipelineFilters } from '../types/state/PipelineFilterState';
import { validateYear } from '../utils/amr-pipeline-filter.utils';


export const amrPipelineSelector = (pipelineType: PipelineType) => (state: AppState) => {
    return pipelineType === PipelineType.Deals
        ? state.issuanceMonitor.amrPipeline
        : state.issuanceMonitor.amrPipelineIOIs;
};

export const arrangerPipelineSelector = createSelector(
    (state: AppState) => state.issuanceMonitor.amrArrangerPipeline,
    arrangerPipeline => arrangerPipeline,
);

export const amrDealsSelector = (pipelineType: PipelineType) => (state: AppState) => {
    return amrPipelineSelector(pipelineType)(state).deals;
};


export const summarySelector = (pipelineType: PipelineType) =>
    createSelector([amrPipelineSelector(pipelineType), amrPipelineFilterSelector(pipelineType)], (amrPipeline, filter) => {
        const {
            deals: { isSummaryRequesting, transactionsStatistic, classesStatistic },
        } = amrPipeline;
        const { lastAppliedFilter, initialFilter } = filter
        // show summary for classes only when one rating selected
        const shouldShowClassesSummary = lastAppliedFilter.ratings.filter.filter(f => f.selected).length === 1;

        const initialFilterCriteria = initialFilter ? getFilterCriteria(initialFilter, pipelineType) : {};
        const lastAppliedFilterCriteria = getFilterCriteria(lastAppliedFilter, pipelineType);
        const shouldShowTransactionsSummary = !isEqual(initialFilterCriteria, lastAppliedFilterCriteria);

        return {
            shouldShowTransactionsSummary,
            isSummaryRequesting,
            shouldShowClassesSummary,
            transactionsStatistic,
            classesStatistic,
        };
    });

export const amrPipelineFilterSelector = (pipelineType: PipelineType) => (state: AppState) => {
    return amrPipelineSelector(pipelineType)(state).filter;
};

// Use for common component on all im tabs
export const issuanceMonitorFilterSelector = (pipelineType: PipelineType) => (state: AppState) => {
    return pipelineType === PipelineType.ArrangerPipeline
        ? arrangerPipelineFilterSelector(state)
        : amrPipelineSelector(pipelineType)(state).filter;
};

export const issuanceMonitorSearchTransactionsSelector = (pipelineType: PipelineType) => (state: AppState) => {
    return  amrPipelineSelector(pipelineType)(state).searchTransactions;
};

export const arrangerPipelineFilterSelector = createSelector(
    arrangerPipelineSelector,
    arrangerPipeline => arrangerPipeline.filter,
);

export const arrangerPipelineDealsSelector = createSelector(
    arrangerPipelineSelector,
    arrangerPipeline => arrangerPipeline.deals,
);

const arrangerPipelineApplyDisabledSelector = createSelector([arrangerPipelineDealsSelector, arrangerPipelineFilterSelector], (deals, filters) => {
    const {
        filter, filterApplied, visibleFilters
    } = filters;

    const { isLoading } = deals;


    const filterErrors = visibleFilters
        .map((f: string) => filter[f as keyof ArrangerPipelineFilters])
        .filter(f => {
            const filter = f as FilterGroup;
            const filterRange = f as FilterRangeGroup;

            const fromRange = filterRange.filter.from;
            const toRange = filterRange.filter.to;

            if (filter.type === FilterType.Range) {
                if (fromRange && toRange && Number(fromRange) > Number(toRange)) return true;
            }

            return false;
        });

    return filterApplied || !!filterErrors.length || isLoading;
})

const amrDealsApplyDisabledSelector = (pipelineType: PipelineType) => createSelector(amrPipelineSelector(pipelineType), amrPipeline => {
    const {
        deals: { isTransactionsSearching, isClassesSearching },
        filter: { filter, filterApplied, visibleFilters },
    } = amrPipeline;

    const filterErrors = visibleFilters
        .map((f: string) => filter[f as keyof DealsPipelineFilters])
        .filter(f => {
            const filter = f as FilterGroup;
            const filterDate = f as FilterDateGroup;
            const filterRange = f as FilterRangeGroup;
            const filterDateTimeRange = f as FilterDateTimeGroup;

            const fromDate = filterDate.filter.options?.customDateRange?.from;
            const toDate = filterDate.filter.options?.customDateRange?.to;
            const fromYearsRange = filterDate.filter.options?.customYearsRange?.from;
            const toYearsRange = filterDate.filter.options?.customYearsRange?.to;
            const fromRange = filterRange.filter.from;
            const toRange = filterRange.filter.to;
            const fromDateTimeRange = filterDateTimeRange.filter.selectedOption?.from;
            const toDateTimeRange = filterDateTimeRange.filter.selectedOption?.to;

            if (
                filter.selected &&
                (filter.type === FilterType.DateWithYearsRange ||
                    filter.type === FilterType.Date ||
                    filter.type === FilterType.YearsDate ||
                    filter.type === FilterType.DateWithYearsAndTimeRange)
            ) {
                if (filter.type === FilterType.YearsDate) {
                    if (
                        fromDate &&
                        toDate &&
                        (validateYear(fromDate.getFullYear()) || validateYear(toDate.getFullYear()))
                    ) {
                        return true;
                    }
                }

                if ((fromDate || toDate) && (!fromDate || !toDate)) return true;
                if (fromDate && toDate && fromDate > toDate) return true;
                if (fromYearsRange && toYearsRange && Number(fromYearsRange) > Number(toYearsRange)) return true;
                if (fromDateTimeRange && toDateTimeRange && fromDateTimeRange.date > toDateTimeRange.date) return true;
            }

            if (filter.selected && filter.type === FilterType.Range) {
                if (fromRange && toRange && Number(fromRange) > Number(toRange)) return true;
            }

            return false;
        });

    return filterApplied || isTransactionsSearching || isClassesSearching || !!filterErrors.length;
})

export const applyDisabledSelector = (pipelineType: PipelineType) => {
        if (pipelineType === PipelineType.ArrangerPipeline) {
            return arrangerPipelineApplyDisabledSelector
        }

        return amrDealsApplyDisabledSelector(pipelineType)
};
