import { urlBuilder } from './url.builder';
import { responseType } from '../constants';
import { fetchSafe } from './fetch.safe';

export const photoService = {
    getThumbnail
}

function getThumbnail(userId: number): Promise<any> {
    const url = urlBuilder('/api/profile/thumbnail', { userId });
    const requestOptions = {
        method: 'GET',
        responseType: responseType.file
    };

    return fetchSafe(url, requestOptions, true);
}
