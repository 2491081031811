import qs from 'query-string';
import { fetchSafe } from './fetch.safe';
import { roles } from '../constants';
import { HavingDealType } from '../types/amr-pipeline/enums/HavingDealType';
import { CompanyStatus } from '../types/amr-pipeline/enums/CompanyStatus';

interface CompaniesQueryParams {
    searchString?: string;
    havingDealType?: HavingDealType;
    favorites?: boolean;
    roles?: string | string[];
    statuses?: CompanyStatus[];
}

type CMHavingDealTypes = HavingDealType.CloManagers | HavingDealType.CmHavingAllPublishedDeals | HavingDealType.None;

function getCompanies(queryParams?: CompaniesQueryParams) {
    const queryString = `?${qs.stringify(queryParams || {})}`;

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${queryString}`
    );
}

function getCompanyInfo(companyReferenceName: string) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${companyReferenceName}`);
}

function getMembersList(companyReferenceName: string) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/companies/${companyReferenceName}/members`);
}

function getManagersList(havingDealType: CMHavingDealTypes = HavingDealType.None, favorites = false) {
    return getCompanies({
        havingDealType,
        favorites,
        roles: roles.CollateralManager,
    });
}

function getArrangersList(havingDeals = false, statuses: CompanyStatus[] = []) {
    return getCompanies({
        havingDealType: havingDeals ? HavingDealType.BdHavingDeals : undefined,
        roles: 'BrokerDealer',
        statuses,
    });
}

function getTrusteeList(statuses: CompanyStatus[] = []) {
    return getCompanies({
        roles: 'Trustee',
        statuses,
    });
}

function getCollateralManagerList(statuses: CompanyStatus[] = []) {
    return getCompanies({
        roles: roles.CollateralManager,
        statuses,
    });
}

function getMajorityEquityList(statuses: CompanyStatus[] = []) {
    return getCompanies({
        roles: 'MajorityEquity',
        statuses,
    });
} 

function getAmrAgentList(statuses: CompanyStatus[] = []) {
    return getCompanies({
        roles: 'AmrAgent',
        statuses,
    });
}   

function getBanksList() {
    return getCompanies({
        havingDealType: HavingDealType.Banks,
        roles: 'BrokerDealer',
    });
}

function getAvailableBrokerDealers() {
    return getCompanies({
        roles: 'BrokerDealer',
    });
}

export const amrCompaniesService = {
    getCompanies,
    getCompanyInfo,
    getMembersList,
    getTrusteeList,
    getCollateralManagerList,
    getMajorityEquityList,
    getAmrAgentList,
    getManagersList,
    getArrangersList,
    getBanksList,
    getAvailableBrokerDealers
};
