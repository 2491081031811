import { takeEvery, put } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { subscriptionsActions, accountActions } from "../actions";
import { SubscriptionPush } from "../types/billing/SubscriptionPush";
import { user } from "../user";

/*
function* watchInit() {
    const subscriptions: SubscriptionsState = yield select((state: AppState) => state.subscriptions);
    if (subscriptions.billingContact) return;

    yield put(subscriptionsActions.loadingFlag(true));

    try {
        let paymentCards: PaymentCard[];
        let billingContact: BillingContact;
        let countries: Country[];
        let states: State[];

        [paymentCards, billingContact, countries, states] = yield all([
            call(billingService.getPaymentCards),
            call(billingService.getBillingContact),
            call(locationService.getCountries),
            call(locationService.getStates, constants.USCountryCode)
        ]);

        yield put(subscriptionsActions.storePaymentCards(paymentCards));
        yield put(subscriptionsActions.storeBillingContact(billingContact));
        yield put(subscriptionsActions.storeCountries(countries));
        yield put(subscriptionsActions.storeStates(states));
        yield put(billingHistoryActions.fetchBillingHistory(RequestState.request))
    } catch (e) {
        yield put(errorActions.criticalError(e));
    } finally {
        yield put(subscriptionsActions.loadingFlag(false));
    }
} */

function* wathSubscriptionChangePush(action: { type: string, payload: { subscription: SubscriptionPush } }) {
    const { subscription, trialPeriodEnd, subscriptionExpiration } = action.payload.subscription;

    user.changeSubscription(
        subscription,
        trialPeriodEnd,
        subscriptionExpiration
    );
    yield put(accountActions.refreshAuthentication());
}

/*
type TUpgradeAction = { type: string, payload: { targetSubscriptionType: SubscriptionType } }
function* watchUpgradeSubscription(action: TUpgradeAction) {
    const subscriptions: SubscriptionsState = yield select((state: AppState) => state.subscriptions);
    const { billingContact, paymentCards } = subscriptions;

    if (!billingContact) return;

    if (!billingContact.billingContactCreated || !paymentCards.length) {
        yield put(subscriptionsActions.showUpgradeSubscriptionWizard(action.payload.targetSubscriptionType));
    } else {
        const primaryCard = paymentCards.find(c => c.isPrimary);
        if (primaryCard) {
            yield put(upgradeSubscriptionActions.init(action.payload.targetSubscriptionType, billingContact, primaryCard));
            yield put(push(routes.upgradeSubscription));
        }
    }
}*/

/*
function* watchLogout() {
    yield put(subscriptionsActions.reset());
}*/

export function* watchSubscriptions() {
    //yield takeEvery(getType(subscriptionsActions.init), watchInit);
    yield takeEvery(getType(subscriptionsActions.subscriptionPushReceived), wathSubscriptionChangePush);
    //yield takeEvery(accountActionTypes.LOGOUT, watchLogout);
}
