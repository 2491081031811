import { createSelector } from 'reselect';
import { AppState } from '../types/state/AppState';
import { isRequestSuccess } from '../utils';
import { BrokerDealerCompanySlim } from '../types/company/BrokerDealerCompanySlim';
import { Contact } from '../types/company/Contact';
import { UserSlim } from '../types/account/UserSlim';
import { compareBoolean } from '../utils/compare.utils';
import { CompanyStatus } from '../types/company/CompanyStatus';
import { user } from '../user';
import { roles } from '../constants';
import { SettlementAgreementStatus } from '../types/bid-as-dealer/SettlementAgreementStatus';
import { SettlementAgentAgreement } from '../types/bid-as-dealer/SettlementAgentAgreement';

const getBrokerDealerList = (state: AppState) => state.entities.brokerDealerList;
const getBrokerDealerContactList = (state: AppState) => state.entities.brokerDealerContactList;
const getBrokerDealerUserList = (state: AppState) => state.entities.brokerDealerUserList;
const getSettlementAgentAgreements = (state: AppState) => state.entities.settlementAgentAgreements;
const getIsAllToAll = (state: AppState) => state.process.isAllToAll;

export const filterDealers = (dealers: BrokerDealerCompanySlim[], contacts: Contact[], users: UserSlim[], agreements: SettlementAgentAgreement[], isAllToAll: boolean | null) =>
    dealers
        .filter(c =>
            (user.hasRoles(roles.BrokerDealerTrader) || isAllToAll)
                ? c.isClearingBank &&
                agreements?.find((a: SettlementAgentAgreement) => a.settlementAgent.id === c.id)?.agreementStatus === SettlementAgreementStatus.confirmed
                : contacts.some(cc => cc.companyId === c.id) ||
                users.some(cu => cu.companyId === c.id) ||
                c.distributionList ||
                c.headOfTrading != null
        );

export const dealersSelector = createSelector(
    [getBrokerDealerList, getBrokerDealerContactList, getBrokerDealerUserList, getSettlementAgentAgreements, getIsAllToAll],
    (dealers, contacts, users, agreements, isAllToAll) =>
        isRequestSuccess(dealers.requestState, contacts.requestState, users.requestState)
            ? filterDealers(dealers.items, contacts.items, users.items, agreements.items, isAllToAll)
                .sort((a, b) =>
                    compareBoolean(a.isClearingBank && a.status === CompanyStatus.active, b.isClearingBank && b.status === CompanyStatus.active) ||
                    a.name.localeCompare(b.name)
                )
            : []
);

export const isRequestedInitialData = createSelector(
    [getBrokerDealerList, getBrokerDealerContactList, getBrokerDealerUserList],
    (brokerDealerList, brokerDealerContactList, brokerDealerUserList) =>
        isRequestSuccess(brokerDealerList.requestState, brokerDealerContactList.requestState, brokerDealerUserList.requestState)
);
