import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { thunk } from 'redux-thunk';
import createRootReducer from './reducers';
import { watchMain } from './sagas/main.saga';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    createRootReducer(),
    {},
    composeEnhancers(
        applyMiddleware(
            sagaMiddleware,
            thunk
        )
    ),
);

sagaMiddleware.run(watchMain);

export { store };
