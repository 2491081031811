import { call, put, select, takeEvery } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { sellerAdminUsersActions } from '../actions/seller-admin-users.actions';
import { ActionType } from '../types/ActionType';
import { SellerAdminEditFormModel } from '../types/seller-admin/SellerAdminEditFormModel';
import { sellerAdminService } from '../services/seller-admin.service';
import { errorActions, notificationActions } from '../actions';
import { RequestState } from '../constants/request-state';
import { AppState } from '../types/state/AppState';
import { CompanyUser } from '../types/management/CompanyUser';
import { UserStatus } from '../types/account/UserStatus';
import { UserCreationRequest } from '../types/management/UserCreationRequest';

function* watchGetUserListRequest() {
    try {
        const users: { users: CompanyUser[], requests: UserCreationRequest[] } = yield call(sellerAdminService.getCompanyUsers);
        yield put(sellerAdminUsersActions.getUserListSuccess(users.users, users.requests));
    } catch (e) {
        yield put(sellerAdminUsersActions.getUserListFailure());
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchSaveUser(action: ActionType<{ data: SellerAdminEditFormModel, userId: number }>) {
    const { data, userId } = action.payload;
    yield put(sellerAdminUsersActions.setUserUpdateState(RequestState.request));
    try {
        yield call(sellerAdminService.saveUser, { ...data, id: userId });
        const users: CompanyUser[] = yield select((state: AppState) => state.sellerAdminUsers.users);
        const user = users.find(u => u.id === userId);
        if (user) {
            const newUser: CompanyUser = { ...user, ...data, id: userId};
            yield put(sellerAdminUsersActions.userSaved(newUser));
        }
        yield put(sellerAdminUsersActions.setUserUpdateState(RequestState.success));
    } catch (e) {
        yield put(sellerAdminUsersActions.setUserUpdateState(RequestState.failure));
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchSaveUserRequest(action: ActionType<{ data: SellerAdminEditFormModel, userRequestId: number, concurrencyStamp?: Date }>) {
    const { data, userRequestId, concurrencyStamp } = action.payload;
    yield put(sellerAdminUsersActions.setUserRequestUpdateState(RequestState.request));
    try {
        const requestUser: UserCreationRequest = yield call(sellerAdminService.saveUserRequest, {
            ...data,
            id: userRequestId || undefined,
            concurrencyStamp
        });
        yield put(sellerAdminUsersActions.userRequestSaved(requestUser));
        yield put(sellerAdminUsersActions.setUserRequestUpdateState(RequestState.success));
    } catch (e) {
        yield put(sellerAdminUsersActions.setUserRequestUpdateState(RequestState.failure));
        yield handleUserRequestChangeError(e);
    }
}

function* watchEnableUserRequest(action: ActionType<{ userRequestId: number, concurrencyStamp: Date }>) {
    const { userRequestId, concurrencyStamp } = action.payload;
    yield put(sellerAdminUsersActions.setUserRequestOperationState(RequestState.request, userRequestId));
    try {
        const userRequest: UserCreationRequest = yield call(sellerAdminService.enableRequestUser, userRequestId, concurrencyStamp);
        yield put(sellerAdminUsersActions.setUserRequestOperationState(RequestState.success, userRequestId));
        yield put(sellerAdminUsersActions.userRequestEnabled(userRequest));
    } catch (e) {
        yield put(sellerAdminUsersActions.setUserRequestOperationState(RequestState.failure, userRequestId));
        yield handleUserRequestChangeError(e);
    }
}

function* watchDisableUserRequest(action: ActionType<{ userRequestId: number, blockReason: string, concurrencyStamp: Date }>) {
    const { userRequestId, concurrencyStamp, blockReason } = action.payload;
    yield put(sellerAdminUsersActions.setUserRequestOperationState(RequestState.request, userRequestId));
    try {
        const userRequest: UserCreationRequest = yield call(sellerAdminService.disableRequestUser, userRequestId, concurrencyStamp, blockReason);
        yield put(sellerAdminUsersActions.setUserRequestOperationState(RequestState.success, userRequestId));
        yield put(sellerAdminUsersActions.userRequestDisabled(userRequest));
    } catch (e) {
        yield put(sellerAdminUsersActions.setUserRequestOperationState(RequestState.failure, userRequestId));
        yield handleUserRequestChangeError(e);
    }
}

function* handleUserRequestChangeError(e?: {status: number}){
    if (e?.status === 409) {
        yield put(notificationActions.notificationAddErrorMessageModal(
            "Data was changed. Refresh the page to get the latest information.",
            "Changes are not allowed",
            true
        ));
    } else {
        yield put(errorActions.unexpectedError(e))
    }
}

function* watchEnableUser(action: ActionType<{ userId: number }>) {
    const { userId } = action.payload;
    yield put(sellerAdminUsersActions.setUserOperationState(RequestState.request, userId));
    try {
        const response: { id: number, status: UserStatus } = yield call(sellerAdminService.enableUser, userId);
        yield put(sellerAdminUsersActions.setUserEnabled(userId, response.status));
        yield put(sellerAdminUsersActions.setUserOperationState(RequestState.success, userId));
    } catch (e) {
        yield put(sellerAdminUsersActions.setUserOperationState(RequestState.failure, userId));
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchDisableUser(action: ActionType<{ userId: number, blockReason: string }>) {
    const { userId, blockReason } = action.payload;
    yield put(sellerAdminUsersActions.setUserOperationState(RequestState.request, userId));
    try {
        yield call(sellerAdminService.disableUser, userId, blockReason);
        yield put(sellerAdminUsersActions.setUserDisable(userId, blockReason));
        yield put(sellerAdminUsersActions.setUserOperationState(RequestState.success, userId));
    } catch (e) {
        yield put(sellerAdminUsersActions.setUserOperationState(RequestState.failure, userId));
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchExportUsersRequest(){
    try {
        const file: { name: string, blob: Blob } = yield call(sellerAdminService.exportUsers);
        saveAs(file.blob, file.name);
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(sellerAdminUsersActions.exportUsersResponse())
    }
}

export function* watchSellerAdminUsers() {
    yield takeEvery(sellerAdminUsersActions.getUserListRequest, watchGetUserListRequest);
    yield takeEvery(sellerAdminUsersActions.saveUser, watchSaveUser);
    yield takeEvery(sellerAdminUsersActions.saveUserRequest, watchSaveUserRequest);
    yield takeEvery(sellerAdminUsersActions.enableUserRequest, watchEnableUserRequest);
    yield takeEvery(sellerAdminUsersActions.disableUserRequest, watchDisableUserRequest);
    yield takeEvery(sellerAdminUsersActions.enableUser, watchEnableUser);
    yield takeEvery(sellerAdminUsersActions.exportUsersRequest, watchExportUsersRequest);
    yield takeEvery(sellerAdminUsersActions.disableUser, watchDisableUser);
}
