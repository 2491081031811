import { Location } from 'history';
import { history } from '../history';
import { logger } from '../logging/logger';
import { routes } from '../constants';
import { matchPath } from 'react-router';

class RequestCancellationController {
    readonly _abortControllers: Map<string, AbortController> = new Map<string, AbortController>();
    readonly _unionRoutes: string[][] = [];

    _previousLocation?: Location;

    constructor(unionRoutes: string[][] = []) {
        this._previousLocation = history.location;
        this._unionRoutes = unionRoutes;
        this.start();
    }

    start = (): void => {
        history.listen(nextLocation => {
            if (
                this._previousLocation != null &&
                this._previousLocation.pathname !== nextLocation.pathname
            ) {
                 const unionRoutes = this._unionRoutes.find(u =>
                     u.some(path =>
                         matchPath(this._previousLocation?.pathname ? this._previousLocation?.pathname : '', {
                             path,
                             exact: true,
                         }),
                     ),
                 );
                if (unionRoutes) {
                     const isNextLocationInUnionRouteList = unionRoutes.some(path =>
                         matchPath(nextLocation.pathname, { path, exact: true }),
                     );

                    if (!isNextLocationInUnionRouteList) {
                        unionRoutes.forEach(r => this.abort(r));
                    }
                } else {
                    this.abort(this._previousLocation.pathname);
                }
            }
            this._previousLocation = nextLocation;
        });
    };

    signal = (customKey?: string): AbortSignal => {
        const key: string = customKey ?? history.location.pathname;
        if (!this._abortControllers.has(key)) {
            this._abortControllers.set(key, new AbortController());
        }

        return (this._abortControllers.get(key) as AbortController).signal;
    }

    abort = (key: string): void => {
        const controller = this._abortControllers.get(key);
        if (controller) {
            controller.abort();
            this._abortControllers.delete(key);
        }
    }

    reset = (key: string): void => {
        this._abortControllers.delete(key);
    }

    log = (message: string, payload?: any): void => logger.trace('Request Cancellation: ' + message, payload)
}

const unionRoutes = [
    [routes.manageCompanies, routes.manageCompanyMembers],
    [routes.transactionDetail, routes.transactionDetail],
    [routes.manageCloManager, routes.manageCloManager],
    [routes.deals],
    [routes.deals],
];

export const requestCancelationController = new RequestCancellationController(unionRoutes);
