import { call, put, takeEvery } from "redux-saga/effects";
import { getType, ActionType } from "typesafe-actions";
import { errorActions } from "../actions";
import { tradeAllocationActions } from "../actions/trade-allocation.actions";
import { RequestState } from "../constants/request-state";
import { settlementService } from "../services/settlement.service";
import { TradeAllocationItem } from "../types/trade-allocation/TradeAllocationItem";

function* watchTradeAllocationRequest(action: ActionType<typeof tradeAllocationActions.request>) {
    try {
        const allocation: TradeAllocationItem[] = yield call(settlementService.getTradeAllocation, action.payload.tradeId);
        yield put(tradeAllocationActions.requestResult(action.payload.tradeId, RequestState.success, allocation));

    } catch (e) {
        yield put(errorActions.criticalError(e));
        yield put(tradeAllocationActions.requestResult(action.payload.tradeId, RequestState.failure));
    }
}

export function* watchTradeAllocation() {
    yield takeEvery(getType(tradeAllocationActions.request), watchTradeAllocationRequest);
}
