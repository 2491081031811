import { fetchSafe } from './fetch.safe';
import { fileUpload } from './file.upload';
import { user } from '../user/user';
import { constants } from '../constants/constants';
import { store } from '../store';
import { logger } from '../logging/logger';
import { urlBuilder } from './url.builder';
import { responseType } from '../constants/response.type';
import { onBoardingTooltipConst } from '../constants/onBoardingTooltip';
import { accountActions as actionTypes } from '../constants/actionTypes/account.actions';
import { routes } from '../constants/routes';
import { history } from '../history';

export const accountService = {
    login,
    logout,
    apiLogout,
    getAccessToken,
    refreshToken,
    resetPassword,
    setUpPassword,
    getAssetManagers,
    getProfile,
    changePhoneNumber,
    changePassword,
    getProfileCompany,
    addCompanyTrader,
    editCompanyTrader,
    editCompanyContact,
    enableCompanyTrader,
    disableCompanyTrader,
    requestIdentityVerificationCodeEmail,
    requestIdentityVerificationCodeSms,
    submitIdentityVerificationCode,
    saveIdentityPhoneNumber,
    requestPhoneVerificationCode,
    submitPhoneVerificationCode,
    deactivateSmsConfirmation,
    uploadUserImage,
    deleteIdentityPhoneNumber,
    fetchUserImage,
    deleteUserImage,
    buildVersion,
    downloadDirectBiddingAgreementPdf,
    setDirectBiddingAgreementSigned,
    saveBloombergEmail,
    saveSignupForm,
    saveArrangersClientSignupForm,
    saveBwicAnnouncementNotifications,
    downloadPortfolioAgreementFile,
    setPortfolioAgreementSigned,
    signCusipTerms,
    downloadCusipTermsFile
};

function login(email, password, deviceId) {
    const url = '/api/account/login';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, deviceId })
    };
    return fetchSafe(url, requestOptions)
        .then(userResult => {
            if (userResult && userResult.accessToken) {
                user.store(userResult);
            }
            return userResult;
        });
}

function logout() {
    store.dispatch({ type: actionTypes.LOGOUT });
    user.clear();
    localStorage.removeItem(onBoardingTooltipConst.localStorageKey);
    localStorage.removeItem(constants.requestDirectBiddingPopupLocalStorageKey);
    localStorage.removeItem(constants.requestAllToAllPopupLocalStorageKey);
    history.replace(routes.login);
}

function apiLogout() {
    if (user.isAuthenticated()) {
        const url = '/api/account/logout';
        const requestOptions = {
            method: 'POST',
            responseType: responseType.none
        };

        fetchSafe(url, requestOptions, true);
    }
}

async function getAccessToken() {
    let accessToken = '';
    try {
        const user = await refreshToken();
        if (user && user.accessToken) {
            accessToken = user.accessToken;
        }
    } catch (e) {
        logger.exception(e, 'Failed to get access token. Logging out');
        logout();
    }
    return accessToken;
}

function refreshToken() {
    const url = '/api/account/refreshToken';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user.refreshToken())
    };

    return fetch(url, requestOptions)
        .then(response => response.ok
            ? response.json()
            : Promise.reject(response));
}

function resetPassword(email) {
    const url = '/api/account/resetPassword';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'email': email }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function setUpPassword(model) {
    const url = '/api/account/setUpPassword';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(model)
    };

    return fetchSafe(url, requestOptions);
}

function getAssetManagers() {
    return fetchSafe('/api/account/asset-managers');
}

function getProfile() {
    return fetchSafe('/api/profile');
}

function getProfileCompany() {
    return fetchSafe('/api/company-account');
}

function addCompanyTrader(data) {
    const url = '/api/company-account/traders';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
    };
    return fetchSafe(url, requestOptions);
}

function editCompanyTrader(data, traderId) {
    const url = `/api/company-account/traders/${traderId}`;
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data),
    };
    return fetchSafe(url, requestOptions);
}

function enableCompanyTrader(traderId) {
    const url = `/api/company-account/traders/${traderId}/enable`;
    const requestOptions = {
        method: 'PATCH',
    };

    return fetchSafe(url, requestOptions);
}

function disableCompanyTrader(traderId) {
    const url = `/api/company-account/traders/${traderId}/disable`;
    const requestOptions = {
        method: 'PATCH',
    };

    return fetchSafe(url, requestOptions);
}

function editCompanyContact(data, departmentValue) {
    const url = `/api/company-account/contacts/${departmentValue}`;
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data),
    };
    return fetchSafe(url, requestOptions);
}

function changePhoneNumber(phoneNumber) {
    const url = '/api/account/changePublicPhoneNumber';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ phoneNumber }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function changePassword(model) {
    const url = '/api/account/changePassword';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(model)
    };

    return fetchSafe(url, requestOptions)
        .then(userResult => {
            if (userResult && userResult.accessToken) {
                user.store(userResult)
            }
            return userResult;
        });
}

function requestIdentityVerificationCodeEmail() {
    const requestOptions = {
        responseType: responseType.none
    };

    return fetchSafe('/api/account/two-factor/email', requestOptions);
}

function requestIdentityVerificationCodeSms() {
    const requestOptions = {
        responseType: responseType.none
    };

    return fetchSafe('/api/account/two-factor/sms', requestOptions);
}

function submitIdentityVerificationCode(code, deviceId) {
    const url = '/api/account/TwoFactorSignIn';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ code, deviceId })
    };

    return fetchSafe(url, requestOptions)
        .then(userResult => {
            if (userResult && userResult.accessToken) {
                user.store(userResult);
            }
            return userResult;
        });
}

function requestPhoneVerificationCode() {
    const url = '/api/account/phone-verification';
    const requestOptions = {
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function submitPhoneVerificationCode(code, deviceId) {
    const url = '/api/account/phone-verification';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ code, deviceId })
    };

    return fetchSafe(url, requestOptions);
}

function deactivateSmsConfirmation() {
    const url = '/api/account/phone-verification';
    const requestOptions = {
        method: 'PUT',
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function saveIdentityPhoneNumber(phoneNumber) {
    const url = '/api/account/ChangeIdentityPhoneNumber';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ phoneNumber }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function deleteIdentityPhoneNumber() {
    const url = '/api/account/DeleteIdentityPhoneNumber';
    const requestOptions = {
        method: 'DELETE',
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function uploadUserImage(file) {
    return fileUpload(
        '/api/profile/uploadPhoto',
        null,
        file,
        null,
        true
    );
}

function fetchUserImage(userId) {
    const url = urlBuilder('/api/profile/profilePhoto', { userId });
    const requestOptions = {
        method: 'GET',
        responseType: responseType.file
    };
    return fetchSafe(url, requestOptions, true);
}

function deleteUserImage() {
    const requestOptions = {
        method: 'DELETE',
        responseType: responseType.none
    };
    return fetchSafe('/api/profile/deletePhoto', requestOptions)
}

function buildVersion() {
    return fetchSafe('/build')
}

function saveBloombergEmail(bloombergEmail) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ bloombergEmail }),
        responseType: responseType.none
    };
    return fetchSafe('/api/profile/changeBloombergEmail', requestOptions);
}

function downloadDirectBiddingAgreementPdf() {
    const requestOptions = {
        method: 'get',
        responseType: responseType.file
    };
    return fetchSafe('/api/account/directBiddingAgreement', requestOptions)
}

function setDirectBiddingAgreementSigned() {
    const requestOptions = {
        method: 'put',
        responseType: responseType.none
    };
    return fetchSafe('/api/account/signDirectBiddingAgreement', requestOptions)
}

function saveSignupForm(form) {
    const url = '/api/account/signup';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(form),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);

}

function saveArrangersClientSignupForm(form) {
    const url = '/api/account/sign-up/arrangers-client';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(form),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);

}

function saveBwicAnnouncementNotifications(form) {
    const url = '/api/company-account/bwic-announcement-notifications';
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(form),
        responseType: responseType.json
    };
    return fetchSafe(url, requestOptions);
}

function downloadPortfolioAgreementFile() {
    const requestOptions = {
        method: 'get',
        responseType: responseType.file
    };
    return fetchSafe('/api/account/portfolio-disclaimer', requestOptions)
}

function setPortfolioAgreementSigned() {
    const requestOptions = {
        method: 'put',
        responseType: responseType.none
    };
    return fetchSafe('/api/account/agree-portfolio-disclaimer', requestOptions)
}

function signCusipTerms() {
    const requestOptions = {
        method: 'put',
        responseType: responseType.none
    };
    return fetchSafe('/api/account/sign-cusip-terms', requestOptions)
}

function downloadCusipTermsFile() {
    const requestOptions = {
        method: 'get',
        responseType: responseType.file
    };
    return fetchSafe('/api/account/cusip-terms', requestOptions)
}

