import { createSelector } from 'reselect';
import { CommonFilter } from '../types/filters/FilterState';
import { PortfolioSecurity } from '../types/portfolio/PortfolioSecurity';
import { AppState } from '../types/state/AppState';
import { PortfolioFilterState } from '../types/state/PortfolioState';
import {
    compareStrings, compareBoolean, compareByDateRange, compareByDateYearRange, getSelectedFilters, compareBooleanStrict
} from '../utils/filtering/filter.compare.utils';
import { Portfolio } from '../types/portfolio/Portfolio';

const getPortfolioList = (s: AppState) => s.entities.portfolios.items;
const getSearchTermItems = (s: AppState) => s.searchSecurities.searchTermItems || [];
const getFilter = (s: AppState) => s.filters.portfolio;

export const getPortfolios: (s: AppState) => Portfolio[] = createSelector(
    [getPortfolioList, getSearchTermItems, getFilter],
    (portfolios, searchTermItems, filter) => {
        if (!portfolios || !portfolios.length) return [];
        const filters = filter.lastAppliedFilter as unknown as (PortfolioFilterState | undefined);
        const selectedFilters = getSelectedFilters(filters as unknown as CommonFilter)

        return portfolios
            .map(p => ({
                ...p,
                securities: p.securities.filter(
                    s =>
                        applySearchTerms(p.name, s, searchTermItems) &&
                        (!filters || applyFilterCriteria(s, selectedFilters, filters))
                )
            }))
            .filter(p => p.securities.length);
    }
);

function applyFilterCriteria(s: PortfolioSecurity, selectedFilters: { [key: string]: boolean }, filter: PortfolioFilterState) {
    return (
        (selectedFilters[filter.maturity.key] ? compareByDateRange(s.statedMaturity, filter.maturity) : true) &&
        (selectedFilters[filter.vintage.key] ? compareByDateRange(s.vintage, filter.vintage) : true) &&
        (selectedFilters[filter.closing.key] ? compareByDateRange(s.closingDate, filter.closing) : true) &&
        (selectedFilters[filter.nonCallEnd.key] ? compareByDateYearRange(filter.nonCallEnd, s.nonCallPeriodEnd, s.closingDate) : true) &&
        (selectedFilters[filter.reinvestmentEnd.key] ? compareByDateYearRange(filter.reinvestmentEnd, s.reinvestmentPeriodEnd, s.closingDate) : true) &&
        (selectedFilters[filter.trustees.key] ? compareStrings(s.trustee?.referenceName, filter.trustees) : true) &&
        (selectedFilters[filter.currency.key] ? compareStrings(s.currency, filter.currency) : true) &&
        (selectedFilters[filter.ratings.key] ? compareStrings(s.rating, filter.ratings) : true) &&
        (selectedFilters[filter.dealName.key] ? compareStrings(s.dealLegalName, filter.dealName) : true) &&
        (selectedFilters[filter.collateralType.key] ? compareStrings(s.collateralType, filter.collateralType) : true) &&
        (selectedFilters[filter.managers.key] ? compareStrings(s.collateralManager?.referenceName, filter.managers) : true) &&
        (selectedFilters[filter.esg.key] ? compareBoolean(s.esg, filter.esg) : true) &&
        (selectedFilters[filter.euCompliance.key] ? compareBoolean(s.euCompliance, filter.euCompliance) : true) &&
        (selectedFilters[filter.staticDeal.key] ? compareBoolean(s.staticDeal, filter.staticDeal) : true) &&
        (selectedFilters[filter.amr.key] ? compareBooleanStrict(filter.amr, s.amr) : true) &&
        (selectedFilters[filter.outOfNC.key] ? compareBooleanStrict(filter.outOfNC, s.outOfNC) : true) &&
        (selectedFilters[filter.outOfRI.key] ? compareBooleanStrict(filter.outOfRI, s.outOfRI) : true)
    )
}

function applySearchTerms(portfolioName: string, security: PortfolioSecurity, searchTerms: string[] = []) {
    if (!searchTerms.length) return true
    return searchTerms.some(t => {
        const term = t.toLowerCase();
        return (
            portfolioName.toLowerCase().indexOf(term) === 0 ||
            security.isinCusip.toLowerCase().indexOf(term) === 0 ||
            security.ticker.toLowerCase().indexOf(term) === 0
        );
    });
}
