import { fetchSafe } from './fetch.safe';
import { responseType } from '../constants';

export const onBoardingTooltipService = {
    setCount
}

function setCount(data) {
    const url = '/api/account/set-tooltip-count';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}
