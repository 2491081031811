import { Country } from '../types/location/Country';
import { State } from '../types/location/State';
import { City } from '../types/location/City';
import { getFromCache } from './cache';
import { constants } from '../constants';


export const locationService = {
    getCountries,
    getStates,
    getCities
}

function getCountries(): Promise<Country[]> {
    const applyOrdering = (countries: Country[]) => {
        const usa = countries.find(c => c.code === constants.USCountryCode);
        const otherCountries = countries
            .filter(c => c.code !== constants.USCountryCode)
            .sort((a, b) => a.name.localeCompare(b.name));

        return usa ? [usa, ...otherCountries] : otherCountries;
    }

    return getFromCache('/api/countries', applyOrdering);
}

function getStates(countryCode: string): Promise<State[]> {
    return getFromCache(`/api/states/${countryCode}`);
}

function getCities(stateId: number): Promise<City[]> {
    return getFromCache(`/api/cities/state/${stateId}`);
}
