import { fetchSafe } from "./fetch.safe";

export function getFromCache<T>(
    url: string,
    onFetched?: (fetchResult: T) => T,
    storage: Storage = sessionStorage,
    disableRequestCancelation = false): Promise<T> {
    const cached = storage.getItem(url);

    if (cached == null) {
        return fetchSafe(url, {}, disableRequestCancelation)
            .then((result: T) => {
                if (onFetched) {
                    result = onFetched(result);
                }

                try {
                    storage.setItem(url, JSON.stringify(result));
                } catch {
                    console.log('Storage is overloaded');
                }

                return result;
            });
    }

    return Promise.resolve(JSON.parse(cached) as T);
}
