import { responseType } from '../constants';
import { fetchSafe } from './fetch.safe';

export const bwicDealsService = {
    getDeals,
    getDealSecurityStatistics,
    downloadDealSecurityDocuments
}

function getDeals(positions) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(positions)
    };

    return fetchSafe('/api/deals', requestOptions);
}

function getDealSecurityStatistics(dealTickers){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ dealTickers })
    };

    return fetchSafe('/api/securities/clo-managers-deals', requestOptions);
}

function downloadDealSecurityDocuments(dealTicker, dealName){
    return fetchSafe(`/api/securities/clo-managers-deals/${dealTicker}/download/${dealName}`, {
        responseType: responseType.file
    });
}
