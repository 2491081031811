import { fetchSafe } from './fetch.safe';

export const bwicProcessService = {
    getTemplates
}

function getTemplates() {
    return fetchSafe('/api/process-templates')
        .then(list => list.filter(x => x.readonly)) // Hide user created templates
}
