import { SettlementAccount } from '../types/settlement/SettlementAccount';
import { fetchSafe } from './fetch.safe';
import { SaveSettlementAccount } from '../types/settlement/SaveSettlementAccount';
import { SaveTradeAllocationItem, TradeAllocationItem } from '../types/trade-allocation/TradeAllocationItem';
import { TradeSettlementStatus } from '../types/settlement/TradeSettlementStatus';
import { TradeSide } from '../types/trades/TradeSide';

export const settlementService = {
    getSettlementAccounts,
    getTradeAllocation,
    saveSettlementAccount,
    deleteSettlementAccount,
    saveTradeAllocation
}

function getSettlementAccounts(): Promise<SettlementAccount[]> {
    return fetchSafe('/api/settlement-accounts');
}

function getTradeAllocation(tradeId: string): Promise<TradeAllocationItem[]> {
    return fetchSafe(`/api/trading/${tradeId}/allocation`);
}

function saveSettlementAccount(settlementAccount: SaveSettlementAccount): Promise<SettlementAccount> {
    return fetchSafe('/api/settlement-accounts', {
        method: 'POST',
        body: JSON.stringify(settlementAccount),
    })
}

function deleteSettlementAccount(accountId: number): Promise<void> {
    return fetchSafe(`/api/settlement-accounts/${accountId}`, { method: 'DELETE' })
}

type TSaveAllocationResult = {
    referenceName: string;
    isBuyerAllocationManual: boolean;
    isSellerAllocationManual: boolean;
    allocationLock: Date;
    buyerSettlementStatus: TradeSettlementStatus;
    sellerSettlementStatus: TradeSettlementStatus;
    items: TradeAllocationItem[];
};

function saveTradeAllocation(
    tradeId: string,
    allocationLock: Date,
    items: SaveTradeAllocationItem[],
    side: TradeSide): Promise<TSaveAllocationResult> {
    return fetchSafe(`/api/trading/${tradeId}/allocation`, {
        method: 'POST',
        body: JSON.stringify({ allocationLock, items, side })
    });
}
