import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { apiOperationActions, errorActions, searchSecuritiesActions, sellerBuysideActions } from '../actions';
import { AppState } from '../types/state/AppState';
import { SellerBuysideFilterState, SellerBuysideState } from '../types/state/SellerBuysideState';
import { getDateRangeOption, getSearchDateRange, isRequesting, jsonUtils, numericUtils } from '../utils';
import { PaginationResult } from '../types/PaginationResult';
import { SellerBuysideSearchResult } from '../types/bid-as-dealer/SellerBuysideSearchResult';
import { bidAsDealerRequestService, biddingService } from '../services';
import { FilterOption } from '../types/filters/FilterOption';
import { SubmitBidRequestPayloadType } from '../actions';
import { accountActions, bwicDateFilterOptions, constants, pushDataActions } from '../constants';
import { BwicStatus } from '../types/enums/BwicStatus';
import { RequestState } from '../constants/request-state';
import { queryStringSerializer } from '../utils/filtering';
import { Rating } from '../types/enums/Rating';
import {
    dateFrom,
    dateTo,
    identifiers,
    multipleCurrencies,
    ratings,
    statuses,
    types,
    sizeFrom,
    sizeTo,
    submittedBids,
    sorting,
} from '../utils/filtering/serializers/serializer.definitions';
import { BidRequestStatus } from '../types/bid-as-dealer/BidRequestStatus';
import { SellerBuySideFilterOptions } from '../types/bid-as-dealer/SellerBuysideFilterOptions';
import { Currency } from '../types/enums/Currency';
import { QueryStringArgumentSerializer } from '../utils/filtering/serializers/QueryStringArgumentSerializer';
import { BwicType } from '../types/enums/BwicType';
import { ApiOperation } from '../types/api-operation/ApiOperation';
import { ApiOperationType } from '../types/api-operation/ApiOperationType';
import { keys } from 'lodash';
import { TApiCallResponse } from '../types/api-operation/ApiOperationResult';
import { BidConflict } from '../types/bidding/BidConflict';
import { SubmitBidAttributesModel } from '../types/bidding/SubmitBidAttributesModel';
import { BwicMonitorSortByOptions } from '../types/state/AllBwicsState';
import { user } from '../user';
import { SubscriptionFeature } from '../types/billing/SubscriptionFeature';
import { DeserializeCommandHandler, IDeserializeCommand } from '../utils/filtering/serializers/DeserializeCommand';
import { SellerBuysideSearchResultSummary } from '../types/bid-as-dealer/SellerBuysideSearchResultSummary';
import { history } from '../history';

function* watchInit() {
    const { sellerBuyside } = yield select((state: AppState) => state);
    const { lastAppliedFilter } = sellerBuyside;
    yield put(sellerBuysideActions.initRequestStatus(RequestState.request));
    try {
        const queryString = history.location.search;
        const bidAsDealerRequestExist: boolean = yield call(bidAsDealerRequestService.bidAsDealerRequestExist);
        yield put(sellerBuysideActions.initRequestStatus(RequestState.success, bidAsDealerRequestExist));

        yield put(sellerBuysideActions.advancedFiltersBlocked(false));

        if (!user.hasFeatures(SubscriptionFeature.BwicMonitorAvancedFilters)) {
            yield put(sellerBuysideActions.dateFilterChange(bwicDateFilterOptions.unspecified));
        }
        if (queryString) {
            yield setFilterFromSearchString(queryString)
        }
        if (!lastAppliedFilter) {
            yield put(sellerBuysideActions.filterApply(true))
        }
    } catch (e) {
        yield put(errorActions.criticalError(e));
        yield put(sellerBuysideActions.initRequestStatus(RequestState.failure));
    }
}

function* setFilterFromSearchString(queryString: string) {
    const { sellerBuyside } = yield select((state: AppState) => state);
    let identifierOptions: string[] = [];
    let from: Date | undefined | null = undefined;
    let to: Date | undefined | null = undefined;
    let statusOptions: BwicStatus[] = [];
    let currencyOptions: Currency[] = [];
    let ratingsOptions: Rating[] = [];
    let typesOptions: BwicType[] = [];
    let sizeMin: string | undefined = undefined;
    let sizeMax: string | undefined = undefined;
    let submittedBidsOption: boolean | null = null;
    let sortBy: BwicMonitorSortByOptions | undefined = undefined;

    const serializers: QueryStringArgumentSerializer<any>[] = [
        identifiers((parsedIdentifiers: string[]) => identifierOptions = parsedIdentifiers),
        dateFrom(parsedDate => from = parsedDate),
        dateTo(parsedDate => to = parsedDate),
        statuses(parsedStatuses => statusOptions = parsedStatuses.map(s => Number(s))),
        ratings(parsedRatings => ratingsOptions = parsedRatings.map(r => Rating[r as keyof typeof Rating])),
        multipleCurrencies((parsedCurrency) => currencyOptions = parsedCurrency.map(c => Currency[c as keyof typeof Currency])),
        types(parsedTypes => typesOptions = parsedTypes.map(t => BwicType[t as keyof typeof BwicType])),
        sizeFrom(min => sizeMin = min),
        sizeTo(max => sizeMax = max),
        submittedBids(submittedBidsValue => submittedBidsOption = Boolean(submittedBidsValue)),
        sorting(sortByValue => sortBy = sortByValue as BwicMonitorSortByOptions),
    ];
    queryStringSerializer.deserialize(queryString, serializers);

    yield all(sellerBuyside.filter.bwicStatuses.map((s: FilterOption) => put(sellerBuysideActions.bwicStatusFilterChange(Number(s.value), false))));
    yield all(identifierOptions.map((item: string) => put(searchSecuritiesActions.addSearchItem(item))));

    if (currencyOptions.length) {
        yield put(sellerBuysideActions.storeCurrencySelectedStatus(false));
        yield all(currencyOptions.map(c => put(sellerBuysideActions.currencyFilterChange(c, true))));
    }

    yield all(statusOptions.map(s => put(sellerBuysideActions.bwicStatusFilterChange(s, true))));
    yield all(ratingsOptions.map(r => put(sellerBuysideActions.ratingFilterChange(r, true))));
    yield all(typesOptions.map(t => put(sellerBuysideActions.typeFilterChange(t, true))));

    if ((sizeMin && !sizeMax) || (!sizeMin && sizeMax) || (sizeMin && sizeMax && Number(sizeMin) <= Number(sizeMax))) {
        yield put(sellerBuysideActions.sizeFilterChange({ min: sizeMin ?? '', max: sizeMax ?? '' }));
    }
    yield put(sellerBuysideActions.submittedBidsFilterChange(submittedBidsOption))

    const dateCommand: IDeserializeCommand = {
        canExecute: () => user.hasFeatures(SubscriptionFeature.BwicMonitorAvancedFilters),
        shouldExecute: () => !!from || !!to,
        execute: function* () {
            const { option, customRange } = getDateRangeOption(from, to);
            yield put(sellerBuysideActions.dateFilterChange(option));

            if (option.key === bwicDateFilterOptions.custom.key) {
                yield put(sellerBuysideActions.customDateFilterChange(customRange));
            }

        }
    };
    const sortByCommand: IDeserializeCommand = {
        canExecute: () => user.hasFeatures(SubscriptionFeature.BwicMonitorSorting),
        shouldExecute: () => !!sortBy,
        execute: function* () {
            yield put(sellerBuysideActions.sortFieldChanged(sortBy!));
        }
    }

    const isAllCommandsExecuted: boolean = yield new DeserializeCommandHandler()
        .addCommand(dateCommand)
        .addCommand(sortByCommand)
        .processGenerator();

    if (!isAllCommandsExecuted) {
        yield put(sellerBuysideActions.advancedFiltersBlocked(true));
    }
}

function* watchExportBidRequestsRequest() {
    try {
        const lastAppliedFilter: SellerBuysideFilterState = yield select((state: AppState) => state.sellerBuyside.lastAppliedFilter);
        const sortBy: BwicMonitorSortByOptions = yield select((state: AppState) => state.sellerBuyside.sortBy);
        if (lastAppliedFilter) {
            const filterOption: SellerBuySideFilterOptions = yield getSearchFilterOptions(lastAppliedFilter);
            const file: { name: string, blob: Blob } = yield call(
                bidAsDealerRequestService.exportBidRequestsAsSeller,
                filterOption, sortBy
            );
            saveAs(file.blob, file.name);
            yield put(sellerBuysideActions.exportBidRequestsSuccess())
        }
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(sellerBuysideActions.exportBidRequestsFailure())
    }
}

function* getSearchFilterOptions(stateFilter: SellerBuysideFilterState): Generator<any, SellerBuySideFilterOptions | undefined, any> {
    const state: SellerBuysideState = yield select((state: AppState) => state.sellerBuyside);
    const isinCusipsAndTickers: string[] = yield select((state: AppState) => state.searchSecurities.searchTermItems);
    const dateRange = getSearchDateRange(stateFilter);

    return {
        isinCusipsAndTickers,
        from: dateRange.dateFrom ? moment(dateRange.dateFrom).format(constants.dateTimeFormatUtc) : undefined,
        to: dateRange.dateTo ? moment(dateRange.dateTo).format(constants.dateTimeFormatUtc) : undefined,
        statuses: getFilterSelectedValues(stateFilter.bwicStatuses),
        currency: getSelectedCurrency(stateFilter.currencies)[0] || '',
        ratings: getFilterSelectedValues(stateFilter.ratings),
        types: getFilterSelectedValues(stateFilter.types),
        page: state.currentPageNumber,
        pageSize: state.pageSize,
        sizeFrom: numericUtils.isNumber(stateFilter.size.min) ? Number(stateFilter.size.min) : undefined,
        sizeTo: numericUtils.isNumber(stateFilter.size.max) ? Number(stateFilter.size.max) : undefined,
        submittedBids: stateFilter.submittedBids
    };
}

function* watchFilterApply(action: ActionType<typeof sellerBuysideActions.filterApply>) {
    const state: SellerBuysideState = yield select((state: AppState) => state.sellerBuyside);
    const isinCusipsAndTickers: string[] = yield select((state: AppState) => state.searchSecurities.searchTermItems);

    if (state.advancedFiltersBlocked) return;

    yield put(sellerBuysideActions.dataItemsLoadingState(true));

    const isFilterChanged = action.payload.isApply || action.payload.isSearchTermChange;

    const filter = isFilterChanged
        ? state.filter
        : state.lastAppliedFilter ?? state.initialFilter;

    if (isFilterChanged) {
        yield put(sellerBuysideActions.resetSummary());
    }

    try {
        const response: PaginationResult<SellerBuysideSearchResult> & SellerBuysideSearchResultSummary = yield call(
            bidAsDealerRequestService.findBidRequestsAsSeller,
            yield getSearchFilterOptions(filter),
            state.sortBy,
            isFilterChanged
        );
        const { totalRecordNumber, result, ...summary } = response;
        const serializers: QueryStringArgumentSerializer<any>[] = [
            identifiers(),
            statuses(),
            dateFrom(),
            ratings(),
            multipleCurrencies(),
            dateTo(),
            types(),
            sizeFrom(),
            sizeTo(),
            submittedBids()
        ];

        if (user.hasFeatures(SubscriptionFeature.BwicMonitorSorting)) {
            serializers.push(sorting());
        }

        const criteria = getFilterCriteria(state.filter, isinCusipsAndTickers);
        const queryString = queryStringSerializer.serialize({ ...criteria, sortBy: state.sortBy }, serializers);
        if (queryString !== history.location.search?.replace('?', '')) {
            yield call(history.replace, history.location.pathname + '?' + queryString);
        }
        if (action.payload.isApply) {
            yield put(sellerBuysideActions.storeAppliedFilter(state.filter));
        }
        yield put(sellerBuysideActions.storeSearchResult(
            { totalRecordNumber, result },
            isFilterChanged ? summary : undefined
        ));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    } finally {
        yield put(sellerBuysideActions.dataItemsLoadingState(false));
    }
}

function getSelectedCurrency(currencies: FilterOption[]) {
    const selectedCurrencies = currencies.filter(currency => currency.selected);
    return selectedCurrencies.length === 2 || !selectedCurrencies.length ? [] : [String(selectedCurrencies[0].value)]
}

function getFilterCriteria(filter: SellerBuysideFilterState, isinCusipsAndTickers: string[]) {
    return {
        isinCusipsAndTickers,
        statuses: filter.bwicStatuses.filter(s => s.selected).map(s => s.value),
        ratings: filter.ratings.filter(r => r.selected).map(r => r.value),
        types: filter.types.filter(t => t.selected).map(t => t.value),
        currency: filter.currencies.filter(currency => currency.selected).map(c => c.value),
        sizeFrom: filter.size.min,
        sizeTo: filter.size.max,
        submittedBids: filter.submittedBids,
        ...getSearchDateRange(filter)
    };
}

function getFilterSelectedValues<TValue>(options: FilterOption[]): TValue[] {
    if (options.some(o => o.selected) && options.some(o => !o.selected)) {
        return options.filter(o => o.selected).map(o => o.value as unknown as TValue);
    }
    return [];
}

function* watchReset() {
    const { searchTermItems } = yield select((state: AppState) => state.searchSecurities);
    yield put(searchSecuritiesActions.reset());
    yield put(sellerBuysideActions.saveSearchTermItems(searchTermItems));
}

type SubmitBidRequestActionType = {
    type: string;
    payload: { data: SubmitBidRequestPayloadType };
};

function* watchSubmitBidRequest(action: SubmitBidRequestActionType) {
    const state: SellerBuysideState = yield select((state: AppState) => state.sellerBuyside);
    const apiOperation: ApiOperation | undefined = yield select((s: AppState) =>
        s.apiOperation.requests.find(r =>
            r.event === ApiOperationType.SubmitAxedFinal ||
            r.event === ApiOperationType.SubmitBidRequest)
    );

    const {
        bwicReferenceName,
        positionId,
        level,
        axed,
        final,
        commission,
        modifiedDate,
        agreementBrokerDealerId,
        agreementCommission,
        positionSize
    } = action.payload.data;
    const current = state.dataItems.find(i => i.position.id === positionId);

    if (
        !current ||
        isRequesting(apiOperation?.state) ||
        keys(state.requestStateSubmitBidRequest).some(key => state.requestStateSubmitBidRequest[+key])) {
        return;
    }

    yield put(sellerBuysideActions.updateRequestStateSubmitBid(true, positionId));

    if (apiOperation) {
        yield put(apiOperationActions.resetEvent(ApiOperationType.SubmitAxedFinal));
        yield put(apiOperationActions.resetEvent(ApiOperationType.SubmitBidRequest));
    }

    const statusCode = apiOperation?.result?.statusCode;
    const response = apiOperation?.result?.response;
    const conflict = statusCode === 409 && response != null ? parseConflict(response) : undefined;
    const isBiddingOverThemself = statusCode === 300;
    const bidLock = conflict?.modifiedDate ?? modifiedDate;
    const isBidOrCommissionChanged = current.bidAsDealerRequest?.directBid == null
        ? (level || commission !== agreementCommission)
        : (level && level !== current.bidAsDealerRequest.level) || (commission && commission !== current.bidAsDealerRequest.commission);

    try {
        if (isBidOrCommissionChanged) {
            const bidRequest = {
                positionId,
                brokerDealerId: agreementBrokerDealerId,
                commission,
                value: level,
                axed,
                final,
                size: positionSize,
                salesCoverage: [],
                bidLock,
                acceptBiddingOverThemself: isBiddingOverThemself,
                stagedBiddingStatus: current.bwic.process.stagedBiddingStatus
            }

            const [response]: TApiCallResponse[] = yield call(
                bidAsDealerRequestService.submit,
                bwicReferenceName,
                [bidRequest]
            );

            yield put(apiOperationActions.waitResult({
                state: RequestState.request,
                event: ApiOperationType.SubmitBidRequest,
                token: response.token,
                positionId: response.positionId,
                autoRemove: false,
                errorToastDisabled: true,
                createdDate: new Date()
            }));
        } else if (current.bidAsDealerRequest && current.bidAsDealerRequest.directBid) {
            const model: SubmitBidAttributesModel[] = [{
                positionId,
                bidId: current.bidAsDealerRequest.directBid.id,
                axed,
                final,
                bidLock,
                stagedBiddingStatus: current.bwic.process.stagedBiddingStatus,
            }];
            const [response]: TApiCallResponse[] = yield call(biddingService.updateBidAttributes, model);

            yield put(apiOperationActions.waitResult({
                state: RequestState.request,
                event: ApiOperationType.SubmitAxedFinal,
                token: response.token,
                positionId: response.positionId,
                autoRemove: false,
                errorToastDisabled: true,
                createdDate: new Date()
            }));
        }
    } catch (e) {
        yield put(apiOperationActions.waitResult({
            state: RequestState.failure,
            event: ApiOperationType.SubmitBidRequest,
            token: '',
            autoRemove: false,
            positionId,
            createdDate: new Date(),
            result: {
                statusCode: e.status,
                event: ApiOperationType.SubmitBidRequest,
                token: '',
                positionId,
                response: ''
            }
        }));
    } finally {
        yield put(sellerBuysideActions.updateRequestStateSubmitBid(false, positionId));
    }
}

function parseConflict(jsonText: string) {
    const conflicts: BidConflict[] | null = jsonText ? jsonUtils.tryParse(jsonText) : null;
    return conflicts?.length ? conflicts[0] : null;
}

function* watchBwicStatusChange(action: { type: string, bwicReferenceName: string, bwicStatus: BwicStatus }) {
    const state: SellerBuysideState = yield select((state: AppState) => state.sellerBuyside);

    if (!(action.bwicStatus === BwicStatus.scheduled || action.bwicStatus === BwicStatus.bidding)) {
        const editState = { ...state.editBidRequestState };
        const dataItems = state.dataItems
            .filter(i => i.bwic.referenceName === action.bwicReferenceName)
            .map(i => ({
                ...i,
                bwic: { ...i.bwic, status: action.bwicStatus, isColorDistribution: false },
                bidAsDealerRequest: i.bidAsDealerRequest ? {
                    ...i.bidAsDealerRequest,
                    status: BidRequestStatus.expired
                } : undefined
            }));

        dataItems.forEach(i => {
            if (editState[i.position.id]) {
                delete editState[i.position.id];
            }
        });

        if (dataItems.length) {
            yield put(sellerBuysideActions.updateEditBidRequestState(editState));
            yield put(sellerBuysideActions.updateBidRequests(dataItems));
        }
    }
}

function* watchLogout() {
    yield put(sellerBuysideActions.hardReset());
}

function* watchSortChanged() {
    yield put(sellerBuysideActions.pagingReset());
    yield put(sellerBuysideActions.filterApply());
}

export function* watchSellerBuyside() {
    yield takeEvery(getType(sellerBuysideActions.init), watchInit);
    yield takeEvery(getType(sellerBuysideActions.filterApply), watchFilterApply);
    yield takeEvery(getType(sellerBuysideActions.sortChanged), watchSortChanged);
    yield takeEvery(getType(sellerBuysideActions.reset), watchReset);
    yield takeEvery(getType(sellerBuysideActions.submitBidRequest), watchSubmitBidRequest);
    yield takeEvery(pushDataActions.PUSH_DATA_BWIC_STATUS_CHANGE, watchBwicStatusChange);
    yield takeEvery(getType(sellerBuysideActions.exportBidRequestsRequest), watchExportBidRequestsRequest);
    yield takeEvery(accountActions.LOGOUT, watchLogout);
}
