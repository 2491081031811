import { fetchSafe } from './fetch.safe';
import { responseType } from '../constants';
import { urlBuilder } from "./url.builder";

export const usersService = {
    getUsers,
    getUser,
    getUserCreationRequests,
    getActiveBrokerDealers,
    enableUser,
    disableUser,
    resendInvite,
    resetPassword,
    saveUser,
    exportUsers,
    updateTfaEnabledState,
    getUserLoginHistory,
    approveUserRequest,
    blockUserRequest,
    deleteUser,
    deleteUserRequest,
    setTempPassword,
    gePendingUserRequestCount,
    unblockUserRequest
};

function getUsers() {
    return fetchSafe('/api/users');
}
// Endpoint for Administrators only
function getUser(userId) {
    return fetchSafe(`/api/users/${userId}`);
}

function getUserCreationRequests() {
    return fetchSafe('/api/manage/user-creation-requests');
}

function getActiveBrokerDealers() {
    return fetchSafe('/api/companies/broker-dealers/users');
}

function enableUser(userId) {
    const url = `/api/users/${userId}/enable`;
    const requestOptions = {
        method: 'POST'
    };

    return fetchSafe(url, requestOptions);
}

function disableUser(userId, blockReason) {
    const url = `/api/users/${userId}/disable`;
    const requestOptions = {
        method: 'POST',
        responseType: responseType.none,
        body: JSON.stringify({ blockReason })
    };

    return fetchSafe(url, requestOptions);
}

function resendInvite(userId) {
    const url = `/api/users/${userId}/send-invite`;
    const requestOptions = {
        method: 'POST'
    };

    return fetchSafe(url, requestOptions);
}

function resetPassword(userId) {
    const url = `/api/users/${userId}/reset-password`;
    const requestOptions = {
        method: 'POST',
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function saveUser(member, sendInvite = false) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ ...member, sendInvite })
    };

    return fetchSafe('/api/users', requestOptions);
}

function exportUsers(filterOptions) {
    const exportUrl = urlBuilder('/api/users/export', filterOptions);

    const requestOptions = {
        responseType: responseType.file
    };

    return fetchSafe(exportUrl, requestOptions);
}

function updateTfaEnabledState(userId, enabled) {
    const url = `/api/users/${userId}/tfa-enable`;
    const requestOptions = {
        method: 'POST',
        body: enabled,
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function getUserLoginHistory(userId) {
    return fetchSafe(`/api/users/history/${userId}`)
}

function gePendingUserRequestCount() {
    return fetchSafe('/api/manage/user-creation-requests/unapproved-count')
}

function approveUserRequest(id, concurrencyStamp) {
    const url = `/api/manage/user-creation-requests/approve`;
    const requestOptions = {
        method: 'patch',
        body: JSON.stringify({ id, concurrencyStamp }),
        responseType: responseType.json
    };

    return fetchSafe(url, requestOptions);
}

function blockUserRequest(id, concurrencyStamp, blockReason) {
    const url = `/api/manage/user-creation-requests/block`;
    const requestOptions = {
        method: 'patch',
        body: JSON.stringify({ id, concurrencyStamp, blockReason }),
        responseType: responseType.json
    };

    return fetchSafe(url, requestOptions);

}

function deleteUserRequest(userRequestId) {
    const url = `/api/manage/user-creation-requests/${userRequestId}`;
    return fetchSafe(url, {  method: 'delete' });
}

function deleteUser(userId) {
    return fetchSafe(`/api/users/${userId}`, {  method: 'delete' });
}

function unblockUserRequest(id, concurrencyStamp) {
    const url = `/api/manage/user-creation-requests/unblock`;
    const requestOptions = {
        method: 'patch',
        body: JSON.stringify({ id, concurrencyStamp }),
        responseType: responseType.json
    };

    return fetchSafe(url, requestOptions);
}

function setTempPassword(userId) {
    return fetchSafe(`/api/users/${userId}/set-temp-password`, { method: 'POST' })
}
