import { fetchSafe } from './fetch.safe';
import { urlBuilder } from './url.builder';
import {
    ManageEmailDetails,
    ManageEmailDetailsEventStatus,
    ManageEmailTypeOption
} from '../types/management/ManageEmailDetails';

const getEmail = (
    requestParams: {
        from: string | null | undefined;
        to: string | null | undefined;
        page: number,
        pageSize: number,
        emailTypes: number[],
        emailEventTypes?: ManageEmailDetailsEventStatus[]
    }
): Promise<{ result: ManageEmailDetails[]; totalRecordNumber: number; }> => {
    const url = urlBuilder('/api/email-trace', requestParams)
    return fetchSafe(url)
}

const getEmailTypes = (
): Promise<ManageEmailTypeOption[]> => {
    return fetchSafe('/api/email-trace/types')
}

export const manageEmailsService = {
    getEmail,
    getEmailTypes
}
