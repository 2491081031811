import { AccessTokenApi, AccessToken } from '../types/management/AccessToken';
import { fetchSafe } from './fetch.safe';

const getTokens = (): Promise<AccessToken[]> => {
    return fetchSafe('/api/tokens')
}

const saveToken = (id: number | undefined, companyId: number, apis: AccessTokenApi[]): Promise<AccessToken> => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ id, companyId, apis })
    };
    return fetchSafe('/api/tokens', requestOptions);
}

const revokeToken = (id: number): Promise<AccessToken> => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ id })
    };
    return fetchSafe('/api/tokens', requestOptions);
}

export const manageTokensService = {
    getTokens,
    saveToken,
    revokeToken
}
