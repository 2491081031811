import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import moment from 'moment';
import { isEqual } from 'lodash';
import { getType } from 'typesafe-actions';
import { billingHistoryActions } from '../actions/subscription-biling-history.actions';
import { billingService } from '../services/billing.service';
import { AppState } from '../types/state/AppState';
import { SubscriptionBillingHistoryFilterState } from '../types/state/SubscriptionsState';
import { BillingHistory } from '../types/billing/BillingHistory';
import { SubscriptionPeriod } from '../types/filters/SubscriptionPeriod';
import { FilterOption } from '../types/filters/FilterOption';
import { RequestState } from '../constants/request-state';
import { AnyAction } from 'redux';
import { isRequesting } from '../utils';

function getFilterPeriodValues(filterPeriod: FilterOption[]): { from: Date, to: Date } {
    const period = filterPeriod.find(p => p.selected)?.value;
    const periodValues: { [key: string]: { from: Date, to: Date } } = {
        [SubscriptionPeriod.month]: {
            from: moment().subtract(1, 'month').toDate(),
            to: moment().toDate()
        },
        [SubscriptionPeriod.quarter]: {
            from: moment().subtract(4, 'month').toDate(),
            to: moment().toDate()
        },
        [SubscriptionPeriod.year]: {
            from: moment().subtract(1, 'year').toDate(),
            to: moment().toDate()
        },
    };
    return period ? periodValues[period] : periodValues[SubscriptionPeriod.month];
}

function getSearchRequestParams(filter: SubscriptionBillingHistoryFilterState): { dateFrom: Date, dateTo: Date, statuses: number[] } {
    const period = getFilterPeriodValues(filter.period);
    const statuses = filter.status.filter(s => s.selected).map(s => Number(s.value));
    return { dateFrom: period.from, dateTo: period.to, statuses }
}

function* watchApplyFilter() {
    const { filter, appliedFilter } = yield select((s: AppState) => s.subscriptions.billingHistory);
    yield put(billingHistoryActions.fetchBillingHistory(RequestState.request));
    if (!isEqual(filter, appliedFilter)) {
        yield put(billingHistoryActions.setApplyFilter());
    }
}

function* watchFetchHistory(action: AnyAction) {
    const {requestState} = action.payload;
    if (isRequesting(requestState)) {
        try {
            const { filter } = yield select((s: AppState) => s.subscriptions.billingHistory);
            const { dateFrom, dateTo, statuses } = getSearchRequestParams(filter);
            const billingHistory: BillingHistory[] = yield call(billingService.searchBillingHistory, dateFrom, dateTo, statuses);
            yield put(billingHistoryActions.fetchBillingHistory(RequestState.success, billingHistory));
        } catch (e) {
            yield put(billingHistoryActions.fetchBillingHistory(RequestState.failure));
        }
    }
}

export function* watchBillingHistory() {
    yield takeLatest(getType(billingHistoryActions.fetchBillingHistory), watchFetchHistory);
    yield takeEvery(getType(billingHistoryActions.applyFilter), watchApplyFilter);
}
