import moment from "moment";
import { responseType, constants } from "../constants";
import { fetchSafe } from "./fetch.safe";
import { urlBuilder } from "./url.builder";
import { BwicStatus } from "../types/enums/BwicStatus";
import { OrderByDirection } from "../types/OrderByDirection";
import { BidRequest } from "../types/bid-as-dealer/BidRequest";
import { PaginationResult } from "../types/PaginationResult";
import { SellerBuysideSearchResult } from "../types/bid-as-dealer/SellerBuysideSearchResult";
import { SellerBuySideFilterOptions } from '../types/bid-as-dealer/SellerBuysideFilterOptions';
import { BwicType } from "../types/enums/BwicType";
import { Rating } from "../types/enums/Rating";
import { OpenBiddingStatus } from "../types/enums/OpenBiddingStatus";
import { TApiCallResponse } from '../types/api-operation/ApiOperationResult';
import { BwicMonitorSortByOptions, bwicMonitorSortByApiValues } from "../types/state/AllBwicsState";
import { SellerBuysideSearchResultSummary } from "../types/bid-as-dealer/SellerBuysideSearchResultSummary";
import { appConfig } from "../app-config";


export const bidAsDealerRequestService = {
    getBwicBidRequests,
    findBidRequestsAsSeller,
    create,
    approve,
    reject,
    cancel,
    submit,
    exportBidRequestsAsSeller,
    exportBidRequestsAsDealer,
    bidAsDealerRequestExist,
};

function exportBidRequestsAsSeller(filterOptions: SellerBuySideFilterOptions, sortBy: BwicMonitorSortByOptions = BwicMonitorSortByOptions.NewestFirst): Promise<File> {
    const sortByApiParams = bwicMonitorSortByApiValues[sortBy];
    const url = urlBuilder('/api/bid-as-dealer-requests/export', { ...filterOptions, sortOrder: sortByApiParams.direction });
    return fetchSafe(url, { responseType: responseType.file });
}

function getBwicBidRequests(bwicReferenceName: string): Promise<BidRequest[]> {
    return fetchSafe(`/api/bid-as-dealer-requests/${bwicReferenceName}`);
}

function findBidRequestsAsSeller(
    filterOptions: SellerBuySideFilterOptions,
    sortBy: BwicMonitorSortByOptions = BwicMonitorSortByOptions.NewestFirst,
    loadSummary?: boolean
): Promise<PaginationResult<SellerBuysideSearchResult> & SellerBuysideSearchResultSummary> {
    const sortByApiParams = bwicMonitorSortByApiValues[sortBy];
    const url = urlBuilder('/api/bid-as-dealer-requests/search', {
        ...filterOptions,
        sortOrder: sortByApiParams.direction,
        loadSummary: loadSummary && !appConfig.isSummaryRowHidden
    });
    return fetchSafe(url);
}

function create(
    bwicReferenceName: string,
    positionId: number,
    brokerDealerId: number,
    commission: number,
    value: number,
    size: number,
    salesCoverage: string[],
    bidLock: Date | undefined,
) {
    const url = `/api/bid-as-dealer-requests/${bwicReferenceName}`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ positionId, brokerDealerId, commission, value, size, salesCoverage, bidLock }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function approve(
    bwicReferenceName: string,
    positionId: number,
    bidRequestId: number,
    bidLock: Date | undefined): Promise<void> {
    const url = `/api/bid-as-dealer-requests/confirm/${bwicReferenceName}`;
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ positionId, id: bidRequestId, bidLock }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function reject(
    bwicReferenceName: string,
    positionId: number,
    bidRequestId: number,
    bidLock: Date | undefined,
    comment: string): Promise<void> {
    const url = `/api/bid-as-dealer-requests/reject/${bwicReferenceName}`;
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ positionId, id: bidRequestId, bidLock, comment }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function cancel(
    bwicReferenceName: string,
    positionId: number,
    bidRequestId: number,
    bidLock: Date | undefined): Promise<void> {
    const url = `/api/bid-as-dealer-requests/cancel/${bwicReferenceName}`;
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ positionId, id: bidRequestId, bidLock }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

interface BidRequestSubmitModel {
    positionId: number
    brokerDealerId: number
    commission: number
    value: number
    axed: boolean
    final: boolean
    size: number
    salesCoverage: string[]
    bidLock: Date | undefined
    acceptBiddingOverThemself: boolean
    stagedBiddingStatus?: OpenBiddingStatus | null
}

function submit(bwicReferenceName: string, bidRequests: BidRequestSubmitModel[]): Promise<TApiCallResponse[]> {
    const url = `/api/bid-as-dealer-requests/${bwicReferenceName}`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(bidRequests)
    };

    return fetchSafe(url, requestOptions);
}

function bidAsDealerRequestExist(): Promise<boolean> {
    return fetchSafe('/api/bid-as-dealer-requests/exist')
}

function exportBidRequestsAsDealer(
    isinCusipsAndTickers: string[],
    from: Date | undefined,
    to: Date | undefined,
    statuses: BwicStatus[],
    ratings: Rating[],
    types: BwicType[],
    latestBidsOnly: boolean,
    orderByColumn: string | undefined,
    sortOrder: OrderByDirection | undefined) {
    const url = urlBuilder(
        '/api/bid-as-dealer-requests/bids/Export',
        {
            isinCusipsAndTickers,
            from: from && moment(from).format(constants.dateTimeFormatUtc),
            to: to && moment(to).format(constants.dateTimeFormatUtc),
            statuses,
            ratings,
            types,
            latestBidsOnly,
            orderByColumn,
            sortOrder
        }
    );
    const requestOptions = {
        method: 'get',
        responseType: responseType.file
    };

    return fetchSafe(url, requestOptions);
}
