import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { store } from '../store';
import { constants } from '../constants';
import {
    dashboardPushUpdateTradingStats,
    dashboardPushUpdatePriceLevel,
    dashboardPushUpdateMostTradedTickers,
    dashboardPushBiggestMovers,
    subscriptionsActions,
    apiOperationActions,
    notificationActions,
    companiesActions
} from '../actions';
import { dataPushReceived } from '../actions/push.bwic.actions';
import { settlementAgentAgeementPushActions } from '../actions/settlement-agent-agreement-push.actions';
import { jsonUtils } from "../utils";
import { accountService } from "./account.service";
import { user, refreshTokenController } from '../user';
import { logger } from '../logging/logger';
import { pushInvitationActions } from '../actions';
import { pushUserActions } from '../actions/push.user.actions.js';
import { inventoryActions } from '../actions/inventory.actions';
import { bdInventoryActions } from '../actions/bd-inventory.actions';
import { brokerDealerContactListActions } from '../actions/broker-dealer-contact-list.actions';
import { settlementAgentAgreementActions } from '../actions/settlement-agent-agreements.actions';
import { newBwicDealersActions } from '../actions/new-bwic-dealers.actions';
import { portfolioListActions } from '../actions/portfolio-list.actions';
import { notificationUtils } from '../utils/notifications.utils';
import { errorActions } from '../actions';

class PushNotificationService {
    lastReconnectError = null;

    build = async () => {
        this.conn = new HubConnectionBuilder()
            .withUrl(constants.notificationService, this.getAccessTokenFactory())
            .withAutomaticReconnect(this.getReconnectTimeoutPolicy())
            .configureLogging({ log: (level, message) => level >= LogLevel.Debug && this.log(message) })
            .build();

        this.conn.on('PushNotification', body => {
            this.log('Push: Toast Notification', body)
            store.dispatch(notificationActions.notificationPushReceived(jsonUtils.parse(body)));
        });

        this.conn.on('PushNotificationRead', data => {
            store.dispatch(notificationActions.notificationRead(data))
        });

        this.conn.on('PushNotificationReadAll', () => {
            store.dispatch(notificationActions.notificationReadAll())
        });

        this.conn.on('PushBwic', data => {
            store.dispatch(dataPushReceived(jsonUtils.parse(data)));
        });

        this.conn.on('PushCompany', data => {
            store.dispatch(companiesActions.companyPushDataReceived(jsonUtils.parse(data)));
        });

        this.conn.on('PushPortfolioAlert', data => {
            store.dispatch(portfolioListActions.updateSendAlertState(data));
        });

        this.conn.on('PushTradingStats', response => {
            store.dispatch(dashboardPushUpdateTradingStats(jsonUtils.parse(response)))
        });

        this.conn.on('PushPriceLevel', response => {
            store.dispatch(dashboardPushUpdatePriceLevel(jsonUtils.parse(response)))
        });

        this.conn.on('PushMostTradedTickers', response => {
            store.dispatch(dashboardPushUpdateMostTradedTickers(jsonUtils.parse(response)))
        });

        this.conn.on('PushBiggestMovers', response => {
            this.log('PushBiggestMovers push data received');
            store.dispatch(dashboardPushBiggestMovers(jsonUtils.parse(response)))
        });

        this.conn.on('PushInvitationSent', response => {
            store.dispatch(pushInvitationActions.dataPushReceived(jsonUtils.parse(response)))
        });

        this.conn.on('PushUser', response => {
            this.log('User push data received');
            store.dispatch(pushUserActions.user(jsonUtils.parse(response)))
        });

        this.conn.on('PushBidAsDealerAgreement', response => {
            this.log('Bid as dealer agreement push data received');
            store.dispatch(settlementAgentAgeementPushActions.settlementAgentAgreementPushReceived(jsonUtils.parse(response)));
            store.dispatch(settlementAgentAgreementActions.pushDataReceived(jsonUtils.parse(response)));
            store.dispatch(newBwicDealersActions.sellerBidAsDealerAgreementsPushDataReceived(jsonUtils.parse(response)));
        });

        this.conn.on('PushSubscription', response => {
            this.log('Subscription push data received');
            store.dispatch(subscriptionsActions.subscriptionPushReceived(jsonUtils.parse(response)))
        });

        this.conn.on('PushContact', response => {
            this.log('Dealers company contacts received');
            store.dispatch(brokerDealerContactListActions.pushUpdateList(jsonUtils.parse(response)))
        });

        this.conn.on('PushOperationResult', body => {
            this.log('Push operation result received');
            store.dispatch(apiOperationActions.result(jsonUtils.parse(body)));
        });

        this.conn.on('PushInventorySecurities', body => {
            this.log('Inventory security push data received');
            const data = jsonUtils.parse(body);
            store.dispatch(bdInventoryActions.pushInventory(data));
            store.dispatch(inventoryActions.pushInventory(data.updatedInventorySecurities, data.lockModifiedDate));
        });

        this.conn.onreconnecting(error => store.dispatch(notificationActions.notificationServiceReconnecting(error)));

        this.conn.onreconnected(() => {
            this.lastReconnectError = null;
            store.dispatch(notificationActions.notificationServiceReconnected())
        });

        this.conn.onclose((e) => {
            store.dispatch(notificationActions.notificationServiceDisconnected());

            const statusCode = this.lastReconnectError && this.lastReconnectError.statusCode;
            this.log('Connection closed', statusCode);

            if (statusCode === 401) {
                accountService.logout();
            } else if (this.lastReconnectError) {
                window.location.reload(true);
            } else if(e) {
                this.start();
            }
        });
    };

    start = async (attempt = 1) => {
        if (
            this.conn.state !== HubConnectionState.Disconnected &&
            this.conn.state !== HubConnectionState.Disconnecting) {
            store.dispatch(notificationActions.notificationServiceConnected());
            return;
        }

        try {
            await this.conn.start();
            store.dispatch(notificationActions.notificationServiceConnected());
        } catch (e) {
            if (user.isAuthenticated()) {

                const isUnauthorized = e && e.toString().includes('401');

                if (attempt < constants.pushNotificationsServiceRestartAttemptsLimit) {
                    const timeout = constants.pushNotificationsServiceRestartTimeout / 1000;
                    this.log(`Failed to start Push Notification Service. Next attempt in ${timeout} seconds.`);

                    setTimeout(() => this.start(++attempt), constants.pushNotificationsServiceRestartTimeout)
                } else if (isUnauthorized) {
                    this.log('Push Notification Service could not be started. Logging out.');
                    accountService.logout();
                } else {
                    this.log('Push Notification Service could not be started. Reload page.');
                    window.location.reload(true);
                }
            }

            store.dispatch(notificationActions.notificationServiceDisconnected());
        }
    };

    stop = async () => {
        try {
            if (this.conn.state !== HubConnectionState.Disconnected) {
                this.lastReconnectError = null;
                await this.conn.stop();
            }
        } catch (e) {
            logger.exception('SignalR: Failed to Stop Push Notification Service.');
        }
    }

    getAccessTokenFactory = () => ({
        accessTokenFactory: async () => {
            if (user.isAuthenticated()) {
                return await refreshTokenController.ensureTokenUpToDate(user.token(), user.refreshToken().refreshToken);
            } else {
                accountService.logout();
                return null;
            }
        }
    })

    getReconnectTimeoutPolicy = () => ({
        nextRetryDelayInMilliseconds: (retryContext) => {
            this.lastReconnectError = retryContext.retryReason;

            if (!user.isAuthenticated()) {
                return null;
            }

            switch (retryContext.previousRetryCount) {
                case 0: return 0;
                case 1: return 2000;
                case 2: return 10000;
                case 3: return 30000;
                default: return null;
            }
        }
    })

    send = (methodName, param) => {
        if (!notificationUtils.isConnected(this.conn.state)) {
            return Promise.reject();
        }
        if (param === undefined) {
            return this.conn.invoke(methodName)
        }
        return this.conn.invoke(methodName, param)
    };

    sendNotification = async (methodName, subj, message)  => {
        if (!notificationUtils.isConnected(this.conn.state)) {
            return Promise.reject();
        }

        try {
            await this.conn.invoke(methodName, subj, message);
        } catch (error) {
            this.log(error);
            store.dispatch(errorActions.unexpectedError(error));
        }
    }

    log = (message, payload) => logger.trace('SignalR: ' + message, payload);

    isConnected = () => this.conn.state === HubConnectionState.Connected
}

export const pushNotificationService = new PushNotificationService();
