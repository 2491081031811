import { takeLatest, call, put } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import {
    onBoardingTooltipSetCountRequest,
    onBoardingTooltipSetCountSuccess,
    onBoardingTooltipSetCountFailure,
    onBoardingReset
} from '../actions';
import { accountActions } from '../constants';
import { onBoardingTooltipService } from '../services';

function* watchOnBoardingTooltipSetCount(action) {
    try {
        const { data } = action.payload;
        yield call(onBoardingTooltipService.setCount, data);
        yield put(onBoardingTooltipSetCountSuccess())
    } catch (e) {
        yield put(onBoardingTooltipSetCountFailure())
    }
}

function* watchLogout() {
    yield put(onBoardingReset())
}

export function* watchOnBoardingTooltip() {
    yield takeLatest(getType(onBoardingTooltipSetCountRequest), watchOnBoardingTooltipSetCount);
    yield takeLatest(accountActions.LOGOUT, watchLogout)
}
