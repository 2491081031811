import { fetchSafe } from './fetch.safe';
import { responseType } from '../constants';


export const portfolioService = {
    getPortfolios,
    getPortfolio,
    deletePortfolio,
    createPortfolio,
    createNewPortfolio,
    renamePortfolio,
    savePositions,
    saveSecurityPosition,
    exportPortfolios,
    exportPortfolio,
    updatePortfoliosAlert,
    portfolioExist
};

function portfolioExist() {
    return fetchSafe('/api/portfolios/has-portfolios');
}

function getPortfolios() {
    return fetchSafe('/api/portfolios');
}

function getPortfolio(portfolioReferenceName) {
    return fetchSafe(`/api/portfolios/${portfolioReferenceName}`);
}

function deletePortfolio(portfolioId) {
    let url = `/api/portfolios/${portfolioId}`;

    const requestOptions = {
        method: 'DELETE',
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function createPortfolio(title) {
    let url = `/api/portfolios`;

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(title)
    };

    return fetchSafe(url, requestOptions);
}

function createNewPortfolio(title, positions, notifyUser = false) {
    return fetchSafe(
        '/api/portfolios',
        { method: 'POST', body: JSON.stringify({ title, positions, notifyUser }) }
    );
}

function renamePortfolio(portfolioId, title) {
    let url = `/api/portfolios/${portfolioId}`;

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(title),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function savePositions(portfolioId, positions) {
    let url = `/api/portfolios/${portfolioId}/positions`;

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(positions),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);

}

function saveSecurityPosition(portfolioId, position) {
    let url = `/api/portfolios/${portfolioId}/add`;

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(position),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function exportPortfolios(searchTerms){
    const url = '/api/portfolios/export';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(searchTerms),
        responseType: responseType.file
    };

    return fetchSafe(url, requestOptions);
}

function exportPortfolio(portfolioId) {
    return fetchSafe(
        `/api/portfolios/export/${portfolioId}`,
        { responseType: responseType.file }
    );
}

function updatePortfoliosAlert(data) {
    const url = '/api/portfolios/alerts';
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}