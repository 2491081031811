import { TradeAllocationTemplate } from "../types/trade-allocation/template/TradeAllocationTemplate";
import { fetchSafe } from './fetch.safe';
import { responseType } from '../constants';
import { SaveTradeAllocationTemplate } from "../types/trade-allocation/template/SaveTradeAllocationTemplate";

export const tradeAllocationTemplateService = {
    getTemplates,
    setTemplateActive,
    saveTemplate,
    deleteTemplate
};

function getTemplates(): Promise<TradeAllocationTemplate> {
    return fetchSafe('/api/allocation-templates');
}

function setTemplateActive(templateId: number, active: boolean, lock: Date): Promise<TradeAllocationTemplate[]> {
    return fetchSafe('/api/allocation-templates', {
        method: 'PATCH',
        body: JSON.stringify({ id: templateId, active, lock }),
        responseType: responseType.json
    }, true);
}

function saveTemplate(template: SaveTradeAllocationTemplate): Promise<TradeAllocationTemplate> {
    return fetchSafe('/api/allocation-templates', {
        method: 'POST',
        body: JSON.stringify(template),
        responseType: responseType.json
    }, true);
}

function deleteTemplate(templateId: number): Promise<void> {
    return fetchSafe(`/api/allocation-templates/${templateId}`, { method: 'DELETE' }, true);
}
