import { fetchSafe } from './fetch.safe';
import { responseType } from '../constants';


export const colorService = {
    save,
    deleteColor
}

function save(bwicReferenceName, positionId, color) {
    const url = '/api/color';
    const requestOptions = {
        method: 'POST',
        responseType: responseType.none,
        body: JSON.stringify({
            bwicReferenceName,
            positionId,
            ...color
        })
    };

    return fetchSafe(url, requestOptions);
}

function deleteColor(bwicReferenceName, positionId) {
    const url = `/api/color/${bwicReferenceName}/${positionId}`;
    const requestOptions = {
        method: 'DELETE',
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}
