import { createSelector } from 'reselect';
import { Bid } from '../types/bidding/Bid';
import { BwicPosition } from '../types/bwic/BwicPosition';
import { arrayUtils } from '../utils/array.utils';
import { biddingUtils } from '../utils/bidding.utils';

type TState = { securities: BwicPosition[], includeDirectBids?: boolean };

export const getSecuritiesWithLatestBids = createSelector(
    (state: TState) => state.securities,
    (state: TState) => state.includeDirectBids,
    (securities: BwicPosition[], includeDirectBids: boolean | undefined = true) =>
        securities.map(s => ({ ...s, bids: getLatestBids(s.bids, includeDirectBids) }))
);

function getLatestBids(allBids: Bid[], includeDirectBids: boolean): Bid[] {
    if (!allBids || !allBids.length) {
        return [];
    }

    const bids = includeDirectBids
        ? allBids
        : allBids.filter(b => !b.buyerPseudoOrderNumber);

    const bidsByCompany = [...arrayUtils.groupBy(bids, (bid: Bid) => biddingUtils.getBidCompanyIdentifier(bid)).values()];

    return bidsByCompany
        .filter(companyBids => companyBids && companyBids.length)
        .map(companyBids => companyBids.sort((a: Bid, b: Bid) => b.id - a.id))
        .map(companyBids => companyBids[0]);
}

export function getLatestStage1Bids(bids: Bid[]) {
    const stage1Bids = bids.filter(b => b.stagedBiddingStatus == null);
    return getLatestBids(stage1Bids, true);
}
