import { createSelector } from 'reselect';
import { AppState } from '../types/state/AppState';
import { filterDealers } from './dealers.selector';
import { isRequestSuccess } from '../utils';
import { sortDealersByConfirmedAgreement } from '../utils/direct-bidding.utils';

const getBrokerDealerList = (state: AppState) => state.entities.brokerDealerList;
const getBrokerDealerContactList = (state: AppState) => state.entities.brokerDealerContactList;
const getBrokerDealerUserList = (state: AppState) => state.entities.brokerDealerUserList;
const getSettlementAgentAgreements = (state: AppState) => state.entities.settlementAgentAgreements;
const getIsAllToAll = (state: AppState) => state.process.isAllToAll;

export const newBwicDealersSelector = createSelector(
    [getBrokerDealerList, getBrokerDealerContactList, getBrokerDealerUserList, getSettlementAgentAgreements, getIsAllToAll],
    (dealers, contacts, users, agreements, isAllToAll) => {
        if (isRequestSuccess(dealers.requestState, contacts.requestState, users.requestState, agreements.requestState)) {
            const visibleCompanies = filterDealers(dealers.items, contacts.items, users.items, agreements.items, isAllToAll);
            return sortDealersByConfirmedAgreement(visibleCompanies, agreements.items);
        }

        return [];
    });
