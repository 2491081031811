import { call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { sellerAdminDealerActions } from '../actions/seller-admin-dealers.actions';
import { errorActions } from '../actions';
import { ActionType } from '../types/ActionType';
import { accountActions } from '../constants';
import { sellerAdminService } from '../services/seller-admin.service';
import { brokerDealerListActions } from '../actions/broker-dealer-list.actions';
import { BrokerDealerCompanySlim } from '../types/company/BrokerDealerCompanySlim';

function* watchGetExcludedDealers() {
    try {
        const response: { excludeNewCompany: boolean, excludedDealers: BrokerDealerCompanySlim[] } =
            yield call(sellerAdminService.getExcludedDealers);
        yield put(sellerAdminDealerActions.getExcludedDealersSuccess(response.excludeNewCompany, response.excludedDealers));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(sellerAdminDealerActions.getExcludedDealersFailure());
    }
}

function* watchSaveExcludedDealers(action: ActionType<{ excludeNewCompany: boolean, dealerIds: number[] }>) {
    const { dealerIds, excludeNewCompany } = action.payload;
    try {
        yield call(sellerAdminService.updateExcludedDealers, excludeNewCompany, dealerIds);
        yield put(sellerAdminDealerActions.saveExcludedDealersSuccess());
        yield put(sellerAdminDealerActions.setEditModeStatus(false));
        yield put(brokerDealerListActions.request());
        yield put(sellerAdminDealerActions.getExcludedDealersRequest());
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(sellerAdminDealerActions.saveExcludedDealersFailure());
    }
}

function* watchLogout() {
    yield put(sellerAdminDealerActions.reset())
}

export function* watchSellerAdminDealers() {
    yield takeEvery(getType(sellerAdminDealerActions.getExcludedDealersRequest), watchGetExcludedDealers);
    yield takeEvery(getType(sellerAdminDealerActions.saveExcludedDealersRequest), watchSaveExcludedDealers);
    yield takeEvery(accountActions.LOGOUT, watchLogout);
}
