import { fetchSafe } from './fetch.safe';
import { responseType } from '../constants';
import { SettlementAgentCommissionByRating } from '../types/bid-as-dealer/SettlementAgentCommissionByRating';

export const clearingBankService = {
    saveClearingBankSettings,
    getSettlementAgentCommission
}

function saveClearingBankSettings(
    companyId: number,
    isClearingBank: boolean,
    dailyTradingLimit?: number,
    commissions?: SettlementAgentCommissionByRating) {
    const url = '/api/companies/clearing-bank';
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ companyId, isClearingBank, dailyTradingLimit, ...commissions }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function getSettlementAgentCommission(companyId: number): Promise<SettlementAgentCommissionByRating> {
    return fetchSafe(`/api/agreements/settlement-agent/${companyId}/commissions`);
}
