import moment from 'moment';
import { fetchSafe } from './fetch.safe';
import { fileUpload } from './file.upload';
import { responseType } from '../constants';
import { getFromCache } from './cache';
import { urlBuilder } from './url.builder';
import { moneyUtils } from '../utils';
import { bwicMonitorSortByApiValues } from '../types/state/AllBwicsState';
import { appConfig } from '../app-config';

export const bwicService = {
    getAssets,
    getOpenBiddingStatus,
    getBwicHistory,
    uploadSecurities,
    getSecuritiesXlsxTemplate,
    getSecurityDocsZip,
    searchSecurities,
    searchSecuritiesGlobal,
    searchBwics,
    submit,
    exportBwic,
    exportBwics,
    parseSecuritiesStirng,
    getByReferenceName,
    cancel,
    markHistoryMessagesAsRead,
    setColorDistribution,
    completeBwic,
    myBwicsExist,
    saveAutoFeedbackConfiguration,
    validateTradeLimits,
    isBwicWithSecuritesExist,
    finishStage,
    getSecurityBwicStatistics,
    getClassDetails
}

function getAssets() {
    return getFromCache('/api/asset-types');
}

function getOpenBiddingStatus(referenceName) {
    return fetchSafe('/api/bwics/open-bidding-status/' + encodeURIComponent(referenceName), {}, true);
}

function uploadSecurities(file, progressCallback) {
    return fileUpload(
        '/api/securities',
        null,
        file,
        progressCallback
    );
}

function getSecuritiesXlsxTemplate() {
    const requestOptions = {
        responseType: responseType.file
    };

    return fetchSafe('/api/securities/template', requestOptions);
}

function getSecurityDocsZip(isinCusip) {
    const requestOptions = {
        responseType: responseType.file
    };

    return fetchSafe(`/api/securities/documents/${isinCusip}`, requestOptions);
}

function getBwicHistory(bwicReferenceName) {
    return fetchSafe(`/api/bwics/${bwicReferenceName}/history`);
}

function searchSecurities(searchTerm) {
    return fetchSafe(`/api/securities?searchTerm=${searchTerm}`);
}

function searchSecuritiesGlobal(searchTerm, searchByTicker, abortSignal) {
    const endpoint = searchByTicker
        ? `/api/securities/search-ticker?searchTerm=${searchTerm}`
        : `/api/securities/search-isin-cusip?searchTerm=${searchTerm}`;

    return fetchSafe(endpoint, undefined, false, abortSignal);
}

function submit(bwic) {
    const url = '/api/bwics';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(bwic),
        responseType: responseType.json
    };

    return fetchSafe(url, requestOptions);
}

function searchBwics(criteria, page, pageSize, sortBy, loadSummary) {
    const sortByApiParams = bwicMonitorSortByApiValues[sortBy];
    const url = urlBuilder('/api/bwics/search', {
        page,
        pageSize,
        sortOrder: sortByApiParams?.direction,
        loadSummary: loadSummary && !appConfig.isSummaryRowHidden
    });
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ ...criteria, loadSummary: loadSummary && !appConfig.isSummaryRowHidden })
    };

    return fetchSafe(url, requestOptions);
}

function exportBwic(referenceName) {
    const url = `/api/bwics/export/${referenceName}`;

    const requestOptions = {
        responseType: responseType.file
    };

    return fetchSafe(url, requestOptions);

}

function exportBwics(criteria, page, pageSize, sortBy) {
    const sortByApiParams = bwicMonitorSortByApiValues[sortBy];
    const url = urlBuilder('/api/bwics/export', { page, pageSize, sortOrder: sortByApiParams.direction });
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(criteria),
        responseType: responseType.file
    };

    return fetchSafe(url, requestOptions);
}

function parseSecuritiesStirng(text) {
    const url = '/api/securities/clipboard';

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ text })
    };

    return fetchSafe(url, requestOptions);
}

function getByReferenceName(referenceName) {
    return fetchSafe(`/api/bwics/${referenceName}`);
}

function cancel(referenceName, reason) {
    const url = `/api/bwics/cancel/${referenceName}`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(reason),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function markHistoryMessagesAsRead(bwicActions) {
    const url = '/api/bwics/history/read';
    const requestOptions = {
        method: 'post',
        body: JSON.stringify(bwicActions),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function setColorDistribution(bwicReferenceName, enabled) {
    const url = `/api/bwics/color-distribution/${enabled ? 'enable' : 'disable'}/${bwicReferenceName}`;
    const requestOptions = {
        method: 'post',
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function completeBwic(bwicReferenceName, colorDistributionModel) {
    const url = `/api/bwics/finish/${bwicReferenceName}`;
    const requestOptions = {
        method: 'post',
        body: JSON.stringify(colorDistributionModel),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function myBwicsExist(isBdBuy) {
    return fetchSafe(urlBuilder('/api/bwics/myBwicsExist', { buyBwics: isBdBuy }));
}

function saveAutoFeedbackConfiguration(bwicReferenceName, configuration) {
    const url = `/api/bwics/auto-feedback-configuration/${bwicReferenceName}`;
    const requestOptions = {
        method: 'patch',
        body: JSON.stringify(configuration),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function validateTradeLimits(bwicDate, bidsDue, timeZone, bwicPositions) {
    const url = '/api/bwics/validate-sell-limits';
    const model = {
        date: moment(bwicDate).startOf('day').utcOffset(0, true).toISOString(),
        bidsDue, timeZone,
        positions: bwicPositions.map(p => ({
            securityId: p.securityId,
            isinCusip: p.isinCusip,
            ticker: p.ticker,
            currency: p.currency,
            rating: p.rating,
            size: moneyUtils.parse(p.size)
        }))
    }
    const requestOptions = {
        method: 'post',
        body: JSON.stringify(model),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function isBwicWithSecuritesExist(securityIds) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(securityIds),
        responseType: responseType.json
    };
    return fetchSafe("/api/bwics/exist/securities", requestOptions);
}

function finishStage(bwicReferenceName) {
    return fetchSafe(`/api/bwics/change-stage/${bwicReferenceName} `, { method: 'PUT' });
}

function getSecurityBwicStatistics(securityId) {
    return fetchSafe(`/api/securities/bwic-statistic/${securityId}`);
}

function getClassDetails(securityId) {
    return fetchSafe(`/api/securities/class-details/${securityId}`)
}