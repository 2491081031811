import { ActionType, getType } from "typesafe-actions";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { priceTalksActions } from "../actions/price-talks.actions";
import { errorActions } from '../actions/error.actions';
import { pushDataActions } from '../constants/actionTypes/push.data.actions';
import { RequestState } from "../constants/request-state";
import { PxTalk } from "../types/bwic/PxTalk";
import { pxTalkService } from '../services/pxTalk.service';

function* watchRequest(action: ActionType<typeof priceTalksActions.request>) {
    try {
        const priceTalks: PxTalk[] = yield call(pxTalkService.fetchPxTalks, action.payload.bwicReferenceName, action.payload.positionId);
        yield put(priceTalksActions.requestResult(action.payload.positionId, RequestState.success, priceTalks));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(priceTalksActions.requestResult(action.payload.positionId, RequestState.failure));
    }
}

function* watchAdd(action: { type: string; positionId: number; priceTalk: PxTalk; }) {
    yield put(priceTalksActions.addPriceTalk(action.positionId, action.priceTalk));
}

export function* watchPriceTalks() {
    yield takeLatest(getType(priceTalksActions.request), watchRequest);
    yield takeEvery(pushDataActions.PUSH_DATA_NEW_PX_TALK, watchAdd);
}
