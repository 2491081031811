import { AssignSalesRepresentatives } from '../types/sales-representative/AssignSalesRepresentatives';
import { SalesRepresentative } from '../types/sales-representative/SalesRepresentative';
import { fetchSafe } from './fetch.safe';
import { responseType } from '../constants';

export const salesRepresentativesService = {
    getCurrentUserSalesRepresentatives,
    getList,
    assign,
    unassign
}

function getCurrentUserSalesRepresentatives(): Promise<SalesRepresentative[]> {
    return fetchSafe('/api/sales-representative', {}, true);
}

function getList(): Promise<SalesRepresentative[]> {
    return fetchSafe('/api/sales-representative/all')
        .then((sales: SalesRepresentative[]) => sales.sort( (a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName)));
}

function assign(model: AssignSalesRepresentatives): Promise<void> {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(model),
        responseType: responseType.none
    };

    return fetchSafe('/api/sales-representative/assign', requestOptions);
}

function unassign(model: AssignSalesRepresentatives): Promise<void> {
    const requestOptions = {
        method: 'DELETE',
        body: JSON.stringify(model),
        responseType: responseType.none
    };

    return fetchSafe('/api/sales-representative', requestOptions);
}
