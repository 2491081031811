import { fetchSafe } from './fetch.safe';
import { fileUpload } from './file.upload';
import { responseType } from '../constants';

export const contactsService = {
    getContacts,
    addContact,
    updateContact,
    deleteContact,
    uploadContacts,
    parseContactsStirng,
    bulkSave,
    getXlsxTemplate,
    inviteBuysideClients,
    inviteAllToJoin,
    sendInvite
}

function getContacts() {
    return fetchSafe('/api/contacts');
}

function addContact(contact) {
    const url = '/api/contacts';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(contact)
    };

    return fetchSafe(url, requestOptions);
}

function updateContact(contact) {
    const url = '/api/contacts';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(contact),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function deleteContact(contactId) {
    const url = `/api/contacts/${contactId}`;
    const requestOptions = {
        method: 'DELETE',
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}


function uploadContacts(file, progressCallback) {
    return fileUpload(
        '/api/contacts/upload',
        null,
        file,
        progressCallback
    );
}

function parseContactsStirng(text) {
    const url = '/api/contacts/clipboard';

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ text })
    };

    return fetchSafe(url, requestOptions);
}

function bulkSave(contactsToSave, contactsToDelete) {
    const url = '/api/contacts/bulk-save';

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ contactsToSave, contactsToDelete })
    };

    return fetchSafe(url, requestOptions);
}

function getXlsxTemplate() {
    const requestOptions = {
        responseType: responseType.file
    };

    return fetchSafe('/api/contacts/template', requestOptions);
}

function inviteBuysideClients(emailList) {
    const url = '/api/invitation/invite-buyside';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ buysideClientEmails: emailList }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function sendInvite(contactId) {
    const url = '/api/invitation/invite';
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ contactId }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}

function inviteAllToJoin(message) {
    const url = '/api/invitation/invite-all';
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ message }),
        responseType: responseType.none
    };

    return fetchSafe(url, requestOptions);
}
