import moment from 'moment';
import { createSelector } from 'reselect';
import { constants } from '../constants';
import { dateTimeUtils } from '../utils';
import { newBWICSteps } from '../constants/newBWIC.steps';
import { TimeZone } from '../types/models/TimeZone';

type TState = {
    isInitializing: boolean;
    hasBwicPositions: boolean;
    submitted: boolean;
    activeStep: string;
    process: {
        date?: Date;
        nextBusinessDay?: Date;
        bidsDue?: string;
        goodUntil?: string;
        daysToSettle: number;
        timeZone?: string;
        timeZones: TimeZone[];
    }
};

const getInitializingFlag = (state: TState) => state.isInitializing;
const getSubmittedFlag = (state: TState) => state.submitted;
const getActiveStep = (state: TState) => state.activeStep
const getHasBwicPositionsFlag = (state: TState) => state.hasBwicPositions;
const getBwicDate = (state: TState) => state.process.date;
const getNextBusinessDay = (state: TState) => state.process.nextBusinessDay;
const getBidsDue = (state: TState) => state.process.bidsDue;
const getGoodUntil = (state: TState) => state.process.goodUntil;
const getDaysToSettle = (state: TState) => state.process.daysToSettle;
const getTimeZone = (state: TState) => state.process.timeZone;
const getTimeZoneList = (state: TState) => state.process.timeZones;

export const bwicChangedFlagSelector = createSelector([
    getInitializingFlag,
    getActiveStep,
    getSubmittedFlag,
    getTimeZoneList,
    getHasBwicPositionsFlag,
    getDaysToSettle,
    getBwicDate,
    getNextBusinessDay,
    getBidsDue,
    getGoodUntil,
    getTimeZone], (
        isInitializing,
        activeStep: string,
        submitted: boolean,
        timeZones: TimeZone[],
        hasPositions: boolean,
        daysToSettle: number,
        date?: Date,
        nextBusinessDay?: Date,
        bidsDue?: string,
        goodUntil?: string,
        timeZone? : string  ) => {
    return !isInitializing && !submitted && (
            activeStep !== newBWICSteps.securities ||
            hasPositions ||
            daysToSettle !== constants.daysToSettleDefault ||
            !date ||
            !bidsDue ||
            !goodUntil ||
            (nextBusinessDay && !moment(date).isSame(nextBusinessDay, 'day')) ||
            dateTimeUtils.timestampFromUtcString(bidsDue) !== constants.bidDueDefaultTimestamp ||
            dateTimeUtils.timestampFromUtcString(goodUntil) !== constants.goodUntilDefaultTimestamp ||
            (timeZone && timeZones.length && timeZone !== timeZones[0].id)
        );
    }
);
