import moment from 'moment';
import { createSelector } from 'reselect';
import { companyColumns, userStatuses, memberColumns, companyStatuses, constants, userRequestStatuses, bwicDateFilterOptions, roles } from '../constants';
import dateTimeUtils from "../utils/dateTime.utils";
import { UserRequestStatus } from '../types/management/UserRequestStatus';
import { UserStatus } from '../types/account/UserStatus';
import { getSearchDateRange } from '../utils';

const getMemsberList = state => state.users;
const getUserRequestList = state => state.userRequests;
const getCompanyList = state => state.companies;
const getOrderByOptions = state => state.memberOrderBy;
const getFilterOptions = state => state.lastAppliedMemberFilter;
const getLiveSearch = state => state.memberFilter.searchTerm;

export const getActiveMembers = createSelector(
    [getMemsberList],
    (members) => members.filter(m => m.status === userStatuses.active.statusCode)
);

export const getMembers = createSelector(
    [getMemsberList, getUserRequestList, getCompanyList, getFilterOptions, getOrderByOptions, getLiveSearch],
    (members, userRequests, companies, filter, orderBy, searchTerm) => {
        const extendedMembers = members.map(u => {
            const company = companies.find(c => c.id === u.companyId);
            return {
                ...u,
                fullName: () => `${u.firstName} ${u.lastName}`,

                companyName: company.name,
                companyStatus: company.status,
                companyStatusName: companyStatuses.getByStatusCode(company.status).title,
                canUnblock: company.status === companyStatuses.active.statusCode || company.status === companyStatuses.offPlatform.statusCode,
                statusName: userStatuses.getByStatusCode(u.status).title,
                dateRegistered: moment
                    .utc(u.dateRegisteredUtc)
                    .local()
                    .format('MMM DD YYYY'),
                dateLastLogin: u.dateLastLoginUtc ? moment
                    .utc(u.dateLastLoginUtc)
                    .local()
                    .format('MMM DD YYYY') : constants.emptyPlaceholder
            };
        });

        const extendedUserRequests = userRequests.map(r => ({
            ...r,
            isUserRequest: true,
            dateRegisteredUtc: r.registrationDate,
            dateRegistered: moment
                .utc(r.registrationDate)
                .local()
                .format('MMM DD YYYY'),
            dateLastLogin: '',
            createdBy: r.registrationBy,
            fullName: () => `${r.firstName} ${r.lastName}`,
            companyName: r.company.name,
            companyId: r.company.id,
            companyStatus: r.company.status,
            companyStatusName: companyStatuses.getByStatusCode(r.company.status).title,
            statusName: userRequestStatuses.getByStatusCode(r.status).title
        }));

        const filteredUserRequests = applyFilter(extendedUserRequests, { ...filter, searchTerm });
        const unprocessedUserRequests = filteredUserRequests.filter(r => r.status === UserRequestStatus.Pending);
        const blockedUserRequests = filteredUserRequests.filter(r => r.status === UserRequestStatus.Blocked);
        const filteredUsers = applyFilter(extendedMembers, { ...filter, searchTerm });
        return [
            ...sort(unprocessedUserRequests, orderBy), // pending user requests always on top
            ...sort([...blockedUserRequests, ...filteredUsers], orderBy)
        ];
    }
);

function applyFilter(members, filter) {
    let filteringResult = members;

    const statuses = [
        filter.active && UserStatus.Active,
        filter.blocked && UserStatus.Blocked,
        filter.invited && UserStatus.Invited,
        filter.notInvited && UserStatus.NotInvited,
        filter.pendingApproval && 'pending-approval'
    ].filter(status => status);

    const requestStatuses = [
        filter.pendingApproval && UserRequestStatus.Pending,
        filter.blocked && UserRequestStatus.Blocked
    ]

    const rolesArray = [
        filter.administrator && roles.Administrator,
        filter.arrangersClient && roles.ArrangersClient,
        filter.brokerDealerTrader && roles.BrokerDealerTrader,
        filter.brokerDealerViewer && roles.BrokerDealerViewer,
        filter.dataEntry && roles.DataEntry,
        filter.sellerAdmin && roles.SellerAdministrator,
        filter.sellerTrader && roles.SellerTrader,
        filter.sellerViewer && roles.SellerViewer,
        filter.subscriptionManager && roles.SubscriptionManager,
        filter.viewer && roles.Viewer,
        filter.collateralManager && roles.CollateralManager,
        filter.media && roles.Media
    ].filter(status => status);

    if (statuses.length) {
        filteringResult = filteringResult.filter(member => (member.isUserRequest ? requestStatuses : statuses)
            .some(status => status === member.status)
        );
    }

    if (rolesArray.length) {
        filteringResult = filteringResult.filter(member => {
            return rolesArray.some(role => {
                return member.roles.some(memberRole => memberRole === role);
            });
        });
    }

    if (filter.regBy?.length) {
        filteringResult = filteringResult.filter(member => filter.regBy.some(userId => member.createdById === userId));
    }

    if (filter.companies?.length) {
        filteringResult = filteringResult.filter(member => filter.companies.some(companyId => member.companyId === companyId));
    }

    if (filter.searchTerm) {
        const searchTerm = filter.searchTerm.toLowerCase();

        filteringResult = filteringResult.filter(m =>
            m.fullName().toLowerCase().includes(searchTerm) ||
            m.email.toLowerCase().includes(searchTerm) ||
            m.companyName.toLowerCase().includes(searchTerm) ||
            (m.phone && m.phone.toLowerCase().includes(searchTerm)) ||
            m.statusName.toLowerCase().includes(searchTerm) ||
            m.dateRegistered.toLowerCase().includes(searchTerm) ||
            (m.roles && m.roles.length && m.roles.some(r => r.toLowerCase().includes(searchTerm))) ||
            m.dateLastLogin.toLowerCase().includes(searchTerm) ||
            m.createdBy?.toLowerCase().includes(searchTerm) ||
            m.companyStatusName.toLowerCase().includes(searchTerm) ||
            dateTimeUtils.utcToLocalString(m.dateRegisteredUtc, constants.dateFormat4).includes(searchTerm)
        );
    }

    if (filter.customDateRange
        && filter.customDateRange.from
        && filter.customDateRange.to
        && filter.selectedDateOption.key === bwicDateFilterOptions.custom.key) {
        filteringResult = filterMembersByRange(filter.customDateRange.from, filter.customDateRange.to, filteringResult);
    }

    if (filter.selectedDateOption
        && filter.selectedDateOption.key !== bwicDateFilterOptions.unspecified.key
        && filter.selectedDateOption.key !== bwicDateFilterOptions.custom.key) {
        const dateFilter = getSearchDateRange(filter);
        filteringResult = filterMembersByRange(dateFilter.dateFrom, dateFilter.dateTo, filteringResult);

    }

    return filteringResult;
}

function filterMembersByRange(from, to, companies) {
    return companies.filter(member => {
        return moment(member.dateRegisteredUtc)
            .isAfter(from)
            &&
            moment(member.dateRegisteredUtc)
                .isBefore(to)
    });
}

function sort(members, orderBy) {
    if (orderBy.column === memberColumns.status) {
        return orderBy.direction === 'asc'
            ? members.sort((a, b) => a.status - b.status)
            : members.sort((a, b) => b.status - a.status);
    } else if (orderBy.column === memberColumns.fullName) {
        return orderBy.direction === 'asc'
            ? members.sort((a, b) => a.fullName().localeCompare(b.fullName()))
            : members.sort((a, b) => b.fullName().localeCompare(a.fullName()));
    } else if (orderBy.column === memberColumns.email) {
        return orderBy.direction === 'asc'
            ? members.sort((a, b) => a.email.localeCompare(b.email))
            : members.sort((a, b) => b.email.localeCompare(a.email));
    } else if (orderBy.column === memberColumns.phone) {
        return orderBy.direction === 'asc'
            ? members.sort((a, b) => (a.phone || '').localeCompare(b.phone || ''))
            : members.sort((a, b) => (b.phone || '').localeCompare(a.phone || ''));
    } else if (orderBy.column === companyColumns.dateRegistered) {
        return orderBy.direction === 'asc'
            ? members.sort((a, b) => new Date(a.dateRegisteredUtc) - new Date(b.dateRegisteredUtc))
            : members.sort((a, b) => new Date(b.dateRegisteredUtc) - new Date(a.dateRegisteredUtc));
    } else if (orderBy.column === companyColumns.company) {
        return orderBy.direction === 'asc'
            ? members.sort((a, b) => a.companyName.localeCompare(b.companyName))
            : members.sort((a, b) => b.companyName.localeCompare(a.companyName));
    } else if (orderBy.column === memberColumns.createdBy) {
        return orderBy.direction === 'asc'
            ? members.sort((a, b) => a.createdBy?.localeCompare(b.createdBy))
            : members.sort((a, b) => b.createdBy?.localeCompare(a.createdBy));
    } else if (orderBy.column === memberColumns.companyStatus) {
        return orderBy.direction === 'asc'
            ? members.sort((a, b) => a.companyStatus - b.companyStatus)
            : members.sort((a, b) => b.companyStatus - a.companyStatus);
    } else if (orderBy.column === memberColumns.dateLastLogin) {
        return orderBy.direction === 'asc'
            ? members.sort((a, b) => getDate(a.dateLastLoginUtc) - getDate(b.dateLastLoginUtc))
            : members.sort((a, b) => getDate(b.dateLastLoginUtc) - getDate(a.dateLastLoginUtc));

        function getDate(dt) {
            return dt ? moment.utc(dt).toDate() : new Date(0);
        }
    }
}
